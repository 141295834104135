import React from "react";

import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";

const DialogActionsWrapper = styled(DialogActions)`
  padding: 8px 24px;
`;

const DialogTitleWrapper = styled(DialogTitle)`
  padding: 16px;
  display: flex;
  align-items: center;

  .MuiButtonBase-root {
    margin-left: auto;
  }

  .title.MuiTypography-root {
    width: 100%;
    font-size: 18px;
    font-weight: bold;
  }
`;

const CustomDialogContent = styled(DialogContent)`
  border-color: rgba(88, 75, 75, 0.06);
  padding: 15px;
  color: #8f9bb3;
`;

const StyledDialogTitleCloseButton = styled(IconButton)`
  margin: 0;
  padding: 0;
  // color: ${({ theme }) => theme.palette.grey[500]};
  color: #c5cee0;
  font-weight: bold;
  font-size: 16px;
`;

const CustomDialogTitle = ({ children, onClose, ...other }) => (
  <DialogTitleWrapper disableTypography={true} {...other}>
    <Typography className="title" variant="subtitle1">
      {children}
    </Typography>
    <StyledDialogTitleCloseButton
      aria-label="close"
      onClick={e => onClose && onClose(e, "backdropClick")}
    >
      <CloseIcon />
    </StyledDialogTitleCloseButton>
  </DialogTitleWrapper>
);

export type SimpleDialogProps = DialogProps & {
  actions: React.ReactNode;
  children: any;
  id: string;
  maxWidth: string;
  onClose: () => void;
  open: boolean;
  title: string;
};

export default ({
  onClose,
  id,
  maxWidth,
  open,
  title,
  children,
  actions,
  ...props
}: SimpleDialogProps): JSX.Element => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby={id}
      fullWidth={true}
      maxWidth={maxWidth || "md"}
      open={open}
      {...props}
    >
      <CustomDialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </CustomDialogTitle>
      <CustomDialogContent dividers={true}>{children}</CustomDialogContent>
      <DialogActionsWrapper>{actions}</DialogActionsWrapper>
    </Dialog>
  );
};
