import { useEffect, useRef, useState } from "react";

export default function useStateWithHistory<State>(
  defaultState: State,
): [State, State, React.Dispatch<React.SetStateAction<State>>] {
  const [state, setState] = useState(defaultState);
  const prevState = useRef(state);

  useEffect(() => {
    prevState.current = state;
  }, [state]);

  return [state, prevState.current, setState];
}
