import React, { ReactNode } from "react";

import Paper, { PaperProps } from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import { IExtendedTheme } from "themes/default";

const useStyles = makeStyles((theme: IExtendedTheme) => ({
  root: {
    alignItems: "center",
    border: "1px solid " + theme.palette.colors.basic[500],
    display: "flex",
    flexDirection: "column",
    height: theme.spacing(14.5),
    justifyContent: "space-around",
    padding: theme.spacing(1),
    transitionProperty: "box-shadow, border",
    width: theme.spacing(12),
    "&:hover": {
      borderColor: theme.palette.colors.primary[500],
      boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.24)",
    },
    "& .icon": {
      alignItems: "center",
      display: "flex",
      flex: 2,
      justifyContent: "center",
      width: "100%",
    },
    "& .title": {
      flex: 1,
      textAlign: "center",
    },
  },
}));

type SubItemProps = { children: ReactNode | string };

const Icon = ({ children }: SubItemProps) => (
  <span className="icon">{children}</span>
);

const Title = ({ children }: SubItemProps) => (
  <span className="title">{children}</span>
);

export type BuilderCardProps = PaperProps;
const BuilderCard = (props: BuilderCardProps) => {
  const classes = useStyles();
  return (
    <>
      <Paper {...props} variant="outlined" className={classes.root} />
    </>
  );
};

BuilderCard.Icon = Icon;
BuilderCard.Title = Title;
export default BuilderCard;
