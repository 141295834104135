import React from "react";

import logoDark from "assets/images/Logo-Dark.png";
import styled from "styled-components";
// import roleUtils from "core/role";

const options = [
  {
    label: "Contact Us",
    path: "/settings/contactus",
  },
  {
    label: "FAQs",
    path: "/settings/faq",
  },
  {
    label: "Terms of Use",
    path: "/settings/terms",
  },
  {
    label: "Privacy Policy",
    path: "/settings/privacy",
  },
  {
    label: "Legal Notices",
    path: "/settings/legal",
  },
];

const calculateOffset = ({ open, drawer, logo }) => {
  if (drawer) {
    return open ? drawer.open : drawer.closed;
  }

  return 0;
};

type MenuOptionProps = {
  isNonUser?: boolean;
  isPlatformAdmin?: boolean;
  label: string;
  path: string;
};

const MenuOption = ({
  path,
  label,
  isNonUser,
  isPlatformAdmin,
}: MenuOptionProps): JSX.Element => (
  <div className="menu-option">
    <a href={!isNonUser && isPlatformAdmin ? path : "/"}>{label}</a>
  </div>
);

export type FooterProps = {
  companyDesc?: string;
  isNonUser?: boolean;
  menuOptions?: MenuOptionProps[];
  shouldHideMenu?: boolean;
  yearFrom?: number | string;
};

const getYearsDesc = (yearFrom: string | number): string => {
  const current = new Date().getFullYear();
  const sanitizedYear: number =
    typeof yearFrom === "string" ? parseInt(yearFrom, 10) : yearFrom;
  return (
    (!isNaN(sanitizedYear) && sanitizedYear === current
      ? current.toString()
      : `${sanitizedYear}-${current}`) || current.toString()
  );
};

export default styled(
  ({
    isNonUser,
    shouldHideMenu,
    yearFrom = "1996",
    companyDesc = "Amazon.com, Inc. or its affiliates",
    menuOptions = options,
    ...restProps
  }: FooterProps): JSX.Element => (
    <div {...restProps}>
      <div className="copyright">
        <span>
          TM & &copy; {getYearsDesc(yearFrom)}, {companyDesc}
        </span>
      </div>
      <div className="logo">
        <img alt="Brand Logo" src={logoDark} />
      </div>
      {!shouldHideMenu && (
        <div className="menu">
          {menuOptions.map(option => (
            <MenuOption key={option.label} isNonUser={isNonUser} {...option} />
          ))}
        </div>
      )}
    </div>
  ),
)`
  background: #fff;
  min-height: 56px;
  width: calc(100% - ${calculateOffset}px);
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  font-size: 13px;
  margin-left: ${calculateOffset}px;

  .copyright {
    color: ${props => props.theme.palette.text.secondary};
  }

  .logo {
    display: none;
  }

  .menu {
    display: flex;

    .menu-option {
      margin: 16px;
      a {
        text-decoration: none;
        color: ${props => props.theme.palette.text.secondary};
      }
    }
  }

  ${props => props.theme.breakpoints.down("sm")} {
    flex-direction: column-reverse;
    width: 100%;
    margin-left: 0;
    margin-top: 16px;

    .menu {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;

      .menu-option {
        margin: 16px 16px 0 16px;
      }
    }

    .logo {
      display: inline-block;

      img {
        height: 24px;
        object-fit: contain;
      }
    }

    .copyright {
      margin-top: 16px;
    }
  }
`;
