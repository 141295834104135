import React from "react";

import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import { Close as CloseIcon } from "@material-ui/icons";
import { Button, ButtonPalette } from "atoms";
import styled from "styled-components";

const DialogActionsWrapper = styled(DialogActions)`
  padding: 8px 24px;
`;

const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  padding: ${({ theme }) => theme.spacing(2)}px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledDialogTitleTypography = styled(Typography)`
  font-size: 18px;
  font-weight: bold;
`;

const StyledDialogTitleCloseButton = styled(IconButton)`
  margin: 0;
  padding: 0;
  // color: ${({ theme }) => theme.palette.grey[500]};
  color: #c5cee0;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 24px 16px;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const CancelButton = ({ cancelText, palette, onCancel, buttonTheme }) => (
  <Button
    size="medium"
    palette={palette}
    buttonTheme={buttonTheme}
    onClick={onCancel}
  >
    {cancelText}
  </Button>
);

const ConfirmButton = ({ confirmText, palette, onConfirm, buttonTheme }) => (
  <Button
    size="medium"
    palette={palette}
    buttonTheme={buttonTheme}
    onClick={onConfirm}
  >
    {confirmText}
  </Button>
);

export type ConfirmationDialogProps = DialogProps & {
  cancelText: string;
  children: React.ReactNode;
  confirmColor?: ButtonPalette;
  confirmText?: string;
  onCancel: () => void;
  onConfirm?: () => void;
  title: string | undefined;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  confirmText,
  confirmColor,
  cancelText,
  children,
  onCancel,
  onConfirm,
  onClose,
  ...otherProps
}) => (
  <Dialog maxWidth="sm" fullWidth={true} onClose={onClose} {...otherProps}>
    <StyledDialogTitle disableTypography={true}>
      <StyledDialogTitleTypography variant="h6">
        {title}
      </StyledDialogTitleTypography>
      <StyledDialogTitleCloseButton
        aria-label="close"
        onClick={e => onClose && onClose(e, "backdropClick")}
      >
        <CloseIcon />
      </StyledDialogTitleCloseButton>
    </StyledDialogTitle>

    <StyledDialogContent dividers={true}>{children}</StyledDialogContent>

    <DialogActionsWrapper>
      <CancelButton
        cancelText={cancelText}
        palette="control"
        buttonTheme={"outline"}
        onCancel={onCancel}
      />
      <ConfirmButton
        confirmText={confirmText}
        palette={confirmColor || "danger"}
        buttonTheme={"light"}
        onConfirm={onConfirm}
      />
    </DialogActionsWrapper>
  </Dialog>
);

export default ConfirmationDialog;
