import React, { HTMLAttributes } from "react";

import CloseCircle from "assets/icons/close-circle.svg";
import styled from "styled-components";

const ExplorerTagWrapper = styled.span<ExplorerTagWrapperProps>`
  border-radius: 14px;
  padding: ${props => (props.withCloseBtn ? "5px 0px 5px 10px" : "5px 10px")};
  text-align: center;
  font-size: 11px;
  color: ${props => props.theme.palette.TagColors[props.palette].color};
  background: ${props =>
    props.isFilled
      ? props.theme.palette.TagColors[props.palette].backgroundColor
      : "transparent"};
  border: ${props =>
    !props.isFilled
      ? `1px solid ${
          props.theme.palette.TagColors[props.palette].backgroundColor
        }`
      : "0px"};
  ${props => (props.tagStyle ? props.tagStyle(props) : "")}

  ${props =>
    props.size === "small" &&
    props.withCloseBtn &&
    `
    font-size: 11px;
    padding: 4px 0 4px 8px;
  `}

  ${props =>
    props.size === "medium" &&
    `
    font-size: 15px;
    min-width: 70px;
  `}

  .tagCloseCircle {
    transform: ${props =>
      props.size === "medium" ? "scale(0.6)" : "scale(0.5)"};
    position: relative;
    top: ${props => (props.size === "medium" ? "7px" : "8px")};
    path:nth-child(2) {
      fill: ${props =>
        props.theme.palette.TagColors[props.palette].color} !important;
    }
  }
`;

type ExplorerTagWrapperProps = HTMLAttributes<HTMLSpanElement> & {
  isFilled?: boolean;
  palette: string;
  size: "small" | "medium";
  tagStyle?: Function;
  withCloseBtn?: boolean;
};

export type TagProps = ExplorerTagWrapperProps & { label: string };

export default (props: TagProps): JSX.Element => {
  console.log(props);
  return (
    <ExplorerTagWrapper className="explorer-tag" {...props}>
      {props.label}
      {props.withCloseBtn ? <CloseCircle className={"tagCloseCircle"} /> : ""}
    </ExplorerTagWrapper>
  );
};
