/* eslint-disable no-console */
import React from "react";

import CancelIcon from "@material-ui/icons/Cancel";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";

import { IExtendedTheme } from "../../themes/default";

type EditWrapperProps = {
  theme: IExtendedTheme;
};

export type EditRowProps = React.HTMLAttributes<HTMLElement> &
  EditWrapperProps & {
    children?: any;
    index: number | string;
    onRemove: (index: number | string) => void;
    onReorderHover?: (index: number | string) => void;
    onReorderMouseEnter?: (index: number | string) => void;
    onReorderMouseLeave?: (index: number | string) => void;
    showReorder?: boolean;
  };

// ${props => props.theme.palette.tertiary.border}
// const EditWrapper = styled.div<EditWrapperProps>;

const ReorderHandle = SortableHandle(() => (
  <div>
    <DragHandleIcon />
  </div>
));

export default styled(
  ({
    index,
    onRemove,
    showReorder = true,
    onReorderMouseLeave,
    onReorderMouseEnter,
    onReorderHover,
    className,
    children,
  }: Readonly<EditRowProps>): JSX.Element => {
    const handleReorderHover = () => {
      console.log("REORDER HOVER");
      onReorderHover && onReorderHover(index);
    };
    const handleReorderMouseOut = () => {
      console.log("REORDER MOUSE OUT");
      onReorderMouseLeave && onReorderMouseLeave(index);
    };
    const handleReorderMouseEnter = () => {
      console.log("REORDER MOUSE ENTER");
      onReorderMouseEnter && onReorderMouseEnter(index);
    };
    return (
      <div className={className}>
        {children}
        <div className="remove-icon" onClick={() => onRemove(index)}>
          <CancelIcon height={24} width={24} />
        </div>
        {showReorder ? (
          <div
            className="reorder-icon"
            onMouseOver={handleReorderHover}
            onMouseEnter={handleReorderMouseEnter}
            onMouseLeave={handleReorderMouseOut}
          >
            <ReorderHandle />
          </div>
        ) : null}
      </div>
    );
  },
)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & .remove-icon {
    color: ${props => props.theme.palette.colors.basic[500]};
    cursor: pointer;
    margin-left: 16px;
  }
  & .reorder-icon {
    margin-left: 16px;
    color: ${props => props.theme.palette.colors.basic[500]};

    & .MuiSvgIcon-root {
      width: 30px;
      height: 36px;
      cursor: move;
    }
  }
`;
