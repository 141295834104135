import React from "react";

import styled from "styled-components";

const RibbonWrapper = styled.span`
  .explorer-ribbon {
    width: 12.1em;
    height: 12.1em;
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    z-index: 20;
    pointer-events: none;
    font-size: 13px;
    text-decoration: none;
    text-indent: -999999px;
  }

  .explorer-ribbon.fixed {
    position: fixed;
  }

  .explorer-ribbon:hover,
  .explorer-ribbon:active {
    background-color: rgba(0, 0, 0, 0);
  }

  .explorer-ribbon:before,
  .explorer-ribbon:after {
    /* The right and left classes determine the side we attach our banner to */
    position: absolute;
    display: block;
    width: 15.38em;
    height: 1.54em;

    top: 3.23em;
    right: -3.23em;

    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;

    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .explorer-ribbon:before {
    content: "";

    /* Add a bit of padding to give some substance outside the "stitching" */
    padding: 0.38em 0;

    /* Set the base colour */
    background-color: ${props => props.theme.palette.quaternary.main};

    background-image: ${props => props.theme.palette.quaternary.main};

    pointer-events: auto;
  }

  .explorer-ribbon:after {
    /* Set the text from the data-ribbon attribute */
    content: attr(data-ribbon);
    .explorer-ribbon:after:before {
      margin-left: 10px;
    }
    /* Set the text properties */
    color: #fff;
    font: 700 1em "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.54em;
    text-decoration: none;
    text-align: center;
    text-indent: 27px;

    /* Set the layout properties */
    padding: 0.15em 0;
    margin: 0.15em 0;
  }

  .explorer-ribbon.left-top,
  .explorer-ribbon.left-bottom {
    right: auto;
    left: 0;
  }

  .explorer-ribbon.left-bottom,
  .explorer-ribbon.right-bottom {
    top: auto;
    bottom: 0;
  }

  .explorer-ribbon.left-top:before,
  .explorer-ribbon.left-top:after,
  .explorer-ribbon.left-bottom:before,
  .explorer-ribbon.left-bottom:after {
    right: auto;
    left: -5.23em;
  }

  .explorer-ribbon.left-bottom:before,
  .explorer-ribbon.left-bottom:after,
  .explorer-ribbon.right-bottom:before,
  .explorer-ribbon.right-bottom:after {
    top: auto;
    bottom: 5.23em;
  }

  .explorer-ribbon.left-top:before,
  .explorer-ribbon.left-top:after,
  .explorer-ribbon.right-bottom:before,
  .explorer-ribbon.right-bottom:after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
`;

export type RibbonProps = {
  title: string;
};

export default ({ title }: RibbonProps): JSX.Element => (
  <RibbonWrapper>
    <span
      title={title}
      className="explorer-ribbon left-top"
      data-ribbon={title}
    />
  </RibbonWrapper>
);
