import React from "react";

import Input from "@material-ui/core/Input";
import { StandardTextFieldProps } from "@material-ui/core/TextField/TextField";
import { useTheme } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { Avatar, AvatarProps } from "atoms";
import styled from "styled-components";

import { IExtendedTheme } from "themes/default";

const SearchHeaderWrapper = styled.div<{ variant?: string }>`
  display: grid;
  grid-template-columns: 600px 1fr auto;
  gap: 40px;
  padding: 0.8em 1.8em;
  justify-content: space-between;

  ${({ variant, theme }) =>
    variant === "default"
      ? `border-bottom: 1px solid ${theme.palette.colors.basic[300]}`
      : `background-color: white;
         box-shadow: 0px 0px 17px -7px rgba(0,0,0,0.67);`};

  .avatar {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > span {
      margin-right: 1em;
    }
  }

  .input-container {
    padding: 0.6em 1em;
    background-color: ${({ theme }) => theme.palette.colors.basic[300]};
    border-radius: 5px;
    svg {
      margin-right: 15px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export interface SearchHeaderProps {
  avatarProps: AvatarProps;
  children: React.ElementType;
  onChange?: StandardTextFieldProps["onChange"];
  value?: StandardTextFieldProps["value"];
  variant?: "filled" | "default";
}

export default ({
  avatarProps,
  value,
  variant = "default",
  onChange,
  children,
}: SearchHeaderProps) => {
  const theme: IExtendedTheme = useTheme();
  return (
    <SearchHeaderWrapper variant={variant}>
      <Input
        classes={{ root: "input-container" }}
        disableUnderline={true}
        startAdornment={
          <SearchIcon htmlColor={theme.palette.colors.basic[600]} />
        }
        placeholder="Search"
        value={value}
        onChange={onChange}
      />
      <div className="actions">{children}</div>
      <div className="avatar">
        <span>{avatarProps.title}</span>
        <Avatar {...avatarProps} />
      </div>
    </SearchHeaderWrapper>
  );
};
