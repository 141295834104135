import React, { HTMLAttributes } from "react";

import { Theme } from "@material-ui/core";
import styled from "styled-components";

const clsPrefix = "explorer-factor";

type WrapperProps = HTMLAttributes<HTMLDivElement> & {
  customStyle: Function;
  size: "large" | "medium";
  theme: Theme;
};

type DefaultFactorProps = {
  value: any;
  valueFrom: any;
};

export type FactorProps = WrapperProps & DefaultFactorProps;

const FactorWrapper = styled.div<WrapperProps>`
  font-size: 15px;

  ${props => props.size === "medium" && "font-size: 20px"};
  ${props => props.size === "large" && "font-size: 24px"};

  ${props => props.customStyle && props.customStyle(props)};

  .${clsPrefix}__value_from {
    color: ${props => props.theme.palette.text.secondary};
  }
`;

export default (props: Readonly<FactorProps>): JSX.Element => (
  <FactorWrapper
    theme={props.theme}
    customStyle={props.customStyle}
    size={props.size}
  >
    <span className={`${clsPrefix}__value value`}>{props.value}</span>
    &nbsp;/&nbsp;
    <span className={`${clsPrefix}__value_form value-from`}>
      {props.valueFrom}
    </span>
  </FactorWrapper>
);
