import React from "react";

import classNames from "classnames";
import styled from "styled-components";

import theme from "../../themes/default";
import { Text, TextProps } from "../index";

export type StatusLabelProps = {
  color: "basic" | "primary" | "success" | "info" | "warning" | "danger";
  italic?: boolean;
  label: string;
  textVariant?: TextProps["variant"];
  upperCase?: boolean;
};

export default styled(
  ({
    color,
    label,
    italic,
    textVariant = "caption",
    upperCase,
    ...rest
  }: StatusLabelProps): JSX.Element => {
    const classes = classNames("status", `status-${color}`);
    return (
      <div className={classes} {...rest}>
        <Text display={"inline"} noWrap variant={textVariant}>
          {label}
        </Text>
      </div>
    );
  },
)`
  display: flex;
  align-items: center;
  font-style: ${({ italic }) => (italic ? "italic" : "normal")};
  text-transform: ${({ upperCase }) => (upperCase ? "uppercase" : "none")};

  &::before {
    content: "";
    background-color: ${({ color }) => theme.palette.status[color]};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 4px;
  }
`;
