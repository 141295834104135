import React, { HTMLAttributes } from "react";

import classNames from "classnames";
import { IoMdClose } from "react-icons/all";
import styled from "styled-components";

import { default as theme } from "../../themes/default";
import Text from "../Text";

type BannerColor =
  | "basic"
  | "primary"
  | "success"
  | "info"
  | "warning"
  | "danger";
type Palette = { palette: BannerColor };

type BannerWrapperProps = HTMLAttributes<HTMLDivElement> & Palette;

const BannerWrapper = styled.div<BannerWrapperProps>`
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-height: 100px;
  padding: 10px;

  overflow: hidden;

  transition: border-width, max-height, padding 0.5s ease-in-out;

  &.hidden {
    border-width: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  ${(props: BannerWrapperProps) => {
    if (!props.palette) {
      return `
            background-color: ${theme.palette.BannerColors.basic.backgroundColor};
            color: ${theme.palette.BannerColors.basic.textColor};
          `;
    }

    const bannerPalette = theme.palette.BannerColors[props.palette];
    let styles = "";

    styles += `
        background-color: ${bannerPalette.backgroundColor};
        color: ${bannerPalette.textColor};
        `;

    if (
      typeof bannerPalette.borderColor !== "undefined" &&
      bannerPalette.borderColor.length
    ) {
      styles += `
          border-color: ${bannerPalette.borderColor};
          `;
    }

    return styles;
  }}
`;

const BannerContent = styled.div<HTMLAttributes<HTMLDivElement>>`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const CloseBtn = styled.a<HTMLAttributes<HTMLAnchorElement>>`
  color: inherit;
  cursor: pointer;

  svg {
    display: block;
  }
`;

export type BannerProps = HTMLAttributes<HTMLDivElement> &
  Palette & {
    hasCloseBtn: boolean;
    onClose: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    show: boolean;
    text: string;
  };

export default ({
  palette,
  text,
  hasCloseBtn,
  onClose,
  show,
  ...rest
}: Readonly<BannerProps>): JSX.Element => {
  return (
    <BannerWrapper
      palette={palette}
      className={classNames({ hidden: !show })}
      {...rest}
    >
      <BannerContent>
        <Text noWrap={true}>{text}</Text>
      </BannerContent>
      {hasCloseBtn && (
        <CloseBtn onClick={onClose}>
          <IoMdClose size={19} />
        </CloseBtn>
      )}
    </BannerWrapper>
  );
};
