import React, { HTMLAttributes } from "react";

import {
  Drawer,
  DrawerProps,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import CollapseIcon from "assets/icons/collapse-sidemenu.svg";
import HomeActiveIcon from "assets/icons/home-active.svg";
import HomeIcon from "assets/icons/home.svg";
import CollapseRightIcon from "assets/icons/menu-collapse-right.svg";
import PlanActiveIcon from "assets/icons/plan-active.svg";
import PlanIcon from "assets/icons/plan.svg";
import SettingActiveIcon from "assets/icons/setting-active.svg";
import SettingIcon from "assets/icons/setting.svg";
import TeamActiveIcon from "assets/icons/team-active.svg";
import TeamIcon from "assets/icons/team.svg";

import logoWhite from "assets/images/logo-white.png";
import styled from "styled-components";

const icons = {
  home: { active: <HomeActiveIcon />, default: <HomeIcon /> },
  plan: { active: <PlanActiveIcon />, default: <PlanIcon /> },
  setting: { active: <SettingActiveIcon />, default: <SettingIcon /> },
  team: { active: <TeamActiveIcon />, default: <TeamIcon /> },
};

const DrawerWrapper = styled(Drawer)<DrawerProps & { drawer: any }>`
  flex-shrink: 0;
  white-space: nowrap;

  .MuiPaper-root.MuiDrawer-paper {
    background: ${props => props.theme.palette.secondary.main};
    width: ${props => (props.open ? props.drawer.open : props.drawer.closed)}px;
    overflow: hidden;
    transition: width 0.2s;
  }
`;

const DrawerTopWrapper = styled.div`
  display: flex;
  height: 64px;
  align-items: center;
  background-color: #192435;
  margin-bottom: 22px;
  padding: 0 12px;

  .MuiButtonBase-root {
    margin-left: auto;
    .MuiIconButton-label {
      color: ${props => props.theme.palette.tertiary.contrastText};
      .MuiSvgIcon-root {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }
`;

const DrawerList = styled(List)`
  color: ${props => props.theme.palette.tertiary.contrastText};
`;

const DrawerListItemWrapper = styled.div<
  HTMLAttributes<HTMLDivElement> & { active: boolean }
>`
  & .MuiListItem-root {
    ${props =>
      props.active && `background: ${props.theme.palette.drawer.foreground};`};
    display: flex;
    align-items: center;
    cursor: pointer;

    .MuiListItemIcon-root {
      margin-left: 4px;
    }

    .MuiListItemText-root {
      margin-top: 0;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      .MuiTypography-root {
        font-size: 13px;
        line-height: 2.5;
        ${props => props.active && "color: #fff;"}
      }
    }
  }
`;

const DrawerTop = ({
  open,
  setOpen,
}: {
  open?: boolean;
  setOpen?: Function;
}): JSX.Element => (
  <DrawerTopWrapper>
    {open && <DrawerLogo />}
    <IconButton onClick={() => setOpen && setOpen(!open)}>
      {!open ? <CollapseIcon /> : <CollapseRightIcon />}
    </IconButton>
  </DrawerTopWrapper>
);

const DrawerListItem = styled(({ name, icons: icns, ...props }) => (
  <DrawerListItemWrapper {...props}>
    <ListItem classes={{ root: "drawer-list-item" }}>
      <ListItemIcon>{props.active ? icns.active : icns.default}</ListItemIcon>
      <ListItemText primary={<span>{props.label}</span>} />
    </ListItem>
  </DrawerListItemWrapper>
))``;

const DrawerLogoWrapper = styled.div`
  padding: 20px 0;
  img {
    height: 20px;
    margin-top: 10px;
  }
`;

const DrawerLogo = props => (
  <DrawerLogoWrapper {...props}>
    <img alt="Amazon Northstar" src={logoWhite} />
  </DrawerLogoWrapper>
);

export default ({ theme, drawer, open, onClickMenuItem, ...props }) => (
  <DrawerWrapper drawer={drawer} open={open} variant="permanent" theme={theme}>
    <DrawerTop />
    <DrawerTopWrapper>
      {open && <DrawerLogo />}
      <IconButton onClick={() => props.setOpen(!open)}>
        {!open ? <CollapseIcon /> : <CollapseRightIcon />}
      </IconButton>
    </DrawerTopWrapper>
    {props.groups.map((list, index) => (
      <DrawerList theme={props.theme} key={String(index)}>
        {list.options.map(item => (
          <DrawerListItem
            icon={icons[item.name]}
            onClick={() => onClickMenuItem(item)}
            key={item.name}
            {...item}
          />
        ))}
      </DrawerList>
    ))}
  </DrawerWrapper>
);
