import React from "react";

import { ListItemText, Menu, MenuItem, MenuProps } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Button, ButtonProps } from "atoms";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
    transformOrigin={{ horizontal: "center", vertical: "top" }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme: any) => ({
  root: {
    "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
      fontSize: 13,
    },
    "&:focus": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.quaternary.contrastText,
      },
      backgroundColor: theme.palette.quaternary.main,
    },
  },
}))(MenuItem);

export type ButtonMenuProps = ButtonProps &
  MenuProps & {
    menuId: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    options: any;
  };

export default ({
  disabled,
  options,
  children,
  onClick,
  menuId,
  ...rest
}: ButtonMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderOptions = (option, index) => (
    <StyledMenuItem key={index}>
      <ListItemText onClick={option.onClick} primary={option.label} />
    </StyledMenuItem>
  );

  return (
    <>
      <Button
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleClick}
        {...rest}
      >
        {children}
      </Button>
      <StyledMenu
        autoFocus={false}
        id={menuId}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {(options || []).map(renderOptions)}
      </StyledMenu>
    </>
  );
};
