import React, { HTMLAttributes } from "react";

import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListProps,
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
  Theme,
} from "@material-ui/core";
import CollapseIcon from "assets/icons/collapse-sidemenu.svg";
import CollapseRightIcon from "assets/icons/menu-collapse-right.svg";
import _ from "lodash";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import styled from "styled-components";

import icons from "./icons";

const drawerMenuType = {
  MAIN: "main",
  SUB: "submenu",
};

interface IDrawer {
  closed: number;
  open: number;
}

interface IOption {
  active: boolean;
  label?: string;
  name: string;
  path: string;
  visible: boolean;
}

interface IGroup {
  always?: boolean;
  name: string;
  options: IOption;
  title?: string;
  visible: boolean;
}

export type DrawerProps = MuiDrawerProps & {
  collapsable?: boolean;
  drawer: IDrawer;
  groups: IGroup[];
  logo?: JSX.Element;
  onClickMenuItem: (o: IOption, g: any) => void;
  open: boolean;
  setOpen: (v: boolean) => void;
  theme: Theme;
};

export default ({
  theme,
  drawer,
  open,
  groups,
  variant = "permanent",
  onClickMenuItem,
  setOpen,
  logo,
  title,
  collapsable = false,
}: DrawerProps): JSX.Element => (
  <Drawer open={open} drawer={drawer} variant={variant} theme={theme}>
    <Drawer.top
      open={open}
      setOpen={setOpen}
      logo={logo}
      title={title}
      collapsable={collapsable}
    />
    {_.map(
      groups,
      (list, index) =>
        list.visible && (
          <Drawer.list
            type={drawerMenuType.MAIN}
            list={list}
            theme={theme}
            onClickMenuItem={v => onClickMenuItem(v, list)}
            key={String(index)}
          />
        ),
    )}
  </Drawer>
);

const Drawer: any = styled(MuiDrawer)<MuiDrawerProps & { drawer: any }>`
  flex-shrink: 0;
  white-space: nowrap;

  .MuiPaper-root.MuiDrawer-paper {
    background: ${props => props.theme.palette.secondary.main};
    width: ${props => (props.open ? props.drawer.open : props.drawer.closed)}px;
    overflow-x: hidden;
    transition: width 0.2s;
  }
`;

const DrawerLogoWrapper = styled.div`
  padding: 20px 0;
  img {
    height: 15px;
    margin-top: 10px;
  }
`;
Drawer.logo = (props): JSX.Element => (
  <DrawerLogoWrapper {...props}>
    {props.logo ? <props.logo /> : null}
  </DrawerLogoWrapper>
);

const DrawerTopWrapper = styled.div`
  display: flex;
  height: 64px;
  align-items: center;
  background-color: #192435;
  margin-bottom: 22px;
  padding: 0 12px;

  .title {
    color: ${props => props.theme.palette.drawer.titleColor};
    font-size: ${props => props.theme.palette.drawer.titleFontSize};
    padding: 0 10px;
  }

  .MuiButtonBase-root {
    margin-left: auto;
    .MuiIconButton-label {
      color: ${props => props.theme.palette.tertiary.contrastText};
      .MuiSvgIcon-root {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }
`;
Drawer.top = ({ open, setOpen, title, logo, collapsable }): JSX.Element => (
  <DrawerTopWrapper>
    {open && <Drawer.logo logo={logo} />}
    {open && title && <span className="title">{title}</span>}
    {collapsable && (
      <IconButton onClick={() => setOpen(!open)}>
        {!open ? <CollapseIcon /> : <CollapseRightIcon />}
      </IconButton>
    )}
  </DrawerTopWrapper>
);

const DrawerListWrapper = styled(List)<ListProps & { type: any }>`
  color: ${props => props.theme.palette.tertiary.contrastText};
  ${props =>
    props.type === drawerMenuType.SUB
      ? `
      padding-top: 0;
      padding-bottom: 0;
      `
      : ""}
`;
Drawer.list = ({ type, theme, list, onClickMenuItem }): JSX.Element => (
  <>
    {list.title && <Drawer.subtitle>{list.title}</Drawer.subtitle>}
    <DrawerListWrapper type={type} theme={theme}>
      {list.options.map(
        item =>
          item.visible && (
            <Drawer.listitem
              key={item.name}
              menuType={type}
              onClickMenuItem={onClickMenuItem}
              {...item}
            />
          ),
      )}
    </DrawerListWrapper>
  </>
);

Drawer.subtitle = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 15px;
  color: #8f9bb3;
  padding: 0 20px;
`;

const DrawerListItemWrapper = styled.div<
  HTMLAttributes<HTMLDivElement> & { active: boolean; menuType: any }
>`
  & > .drawer-list-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: ${props =>
      props.active && props.theme.palette.drawer.foreground};

    ${props =>
      props.menuType === drawerMenuType.SUB &&
      `
      padding-top: 4px;
      padding-left: 50px;
      padding-bottom: 4px;
    `}

    .menu-option {
      font-size: 13px;
    }

    .submenu-prefix {
      margin-right: 20px;
      font-size: 13px;
    }

    .MuiListItemIcon-root {
      margin-left: 4px;
    }

    .MuiListItemText-root {
      margin-top: 0;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      .MuiTypography-root {
        font-size: 13px;
        line-height: 2.5;
        ${props => props.active && "color: #fff;"}
      }
    }

    ${props => props.theme.breakpoints.down("sm")} {
      padding-left: 16px;
    }
  }
`;

Drawer.listitem = (props): JSX.Element => {
  const {
    menuType = drawerMenuType.MAIN,
    onClickMenuItem,
    options,
    collapsed,
    theme,
    name,
    active,
    label,
  } = props;
  const IconComponent =
    icons[name] && (active ? icons[name].active : icons[name].default);

  return (
    <DrawerListItemWrapper menuType={menuType} active={active}>
      <ListItem
        classes={{ root: "drawer-list-item" }}
        onClick={() => onClickMenuItem(props)}
      >
        {IconComponent && (
          <ListItemIcon>
            <IconComponent />
          </ListItemIcon>
        )}
        {menuType === drawerMenuType.SUB && (
          <span className="submenu-prefix">-</span>
        )}
        <ListItemText primary={<span>{label}</span>} />
        {options && (
          <span className="menu-option">
            {collapsed ? <FaChevronRight /> : <FaChevronDown />}
          </span>
        )}
      </ListItem>
      {options && !collapsed && (
        <Drawer.list
          theme={theme}
          type={drawerMenuType.SUB}
          list={{ options }}
          onClickMenuItem={onClickMenuItem}
        />
      )}
    </DrawerListItemWrapper>
  );
};
