import React, { HTMLAttributes } from "react";

import _ from "lodash";
import styled from "styled-components";

const ListViewWrapper = styled.div<
  HTMLAttributes<HTMLDivElement> & { customStyle: Function }
>`
  ${props => props.customStyle && props.customStyle(props)};
`;

const ListViewHeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  background: #f7f9fc;
  padding: 10px;
`;

const ListViewRowWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f7f9fc;
  padding: 10px;
  height: 48px;
`;

type ICellColumnWrapperProps = HTMLAttributes<HTMLDivElement> & {
  alignment: "left" | "right" | "center";
  flex: number;
};
const HeaderColumnWrapper = styled.div<ICellColumnWrapperProps>`
  flex: ${props => props.flex || 1};
  font-size: 12px;
  color: ${props => props.theme.palette.text.secondary};
  display: flex;
  justify-content: ${props => {
    switch (props.alignment) {
      case "left":
        return "flex-start";
      case "right":
        return "flex-end";
      case "center":
        return "center";
      default:
        return "center";
    }
  }};
`;

type IHeaderColumnProps = ICellColumnWrapperProps & { label: string };
const HeaderColumn = (props: IHeaderColumnProps): JSX.Element => (
  <HeaderColumnWrapper {...props}>{props.label}</HeaderColumnWrapper>
);

const CellColumnWrapper = styled.div<ICellColumnWrapperProps>`
  flex: ${props => props.flex || 1};
  font-size: 12px;
  display: flex;
  justify-content: ${props => {
    switch (props.alignment) {
      case "left":
        return "flex-start";
      case "right":
        return "flex-end";
      case "center":
        return "center";
      default:
        return "center";
    }
  }};
`;

const CellColumn = (
  props: ICellColumnWrapperProps & { Cell?: any; value: any },
): JSX.Element => (
  <CellColumnWrapper {...props}>
    {props.Cell ? props.Cell(props) : props.value}
  </CellColumnWrapper>
);

export type ListViewProps = {
  columns: any;
  customStyle: Function;
  data: any;
};

export default ({ customStyle, columns, data }: ListViewProps): JSX.Element => (
  <ListViewWrapper customStyle={customStyle}>
    <ListViewHeaderWrapper>
      {_.map(columns, (column, index) => (
        <HeaderColumn {...column} colType="header" key={String(index)} />
      ))}
    </ListViewHeaderWrapper>
    {_.map(data, (item, rowIndex) => (
      <ListViewRowWrapper key={String(rowIndex)}>
        {_.map(columns, ({ accessor, ...column }, columnIndex) => (
          <CellColumn
            {...column}
            colType="row"
            rowData={item}
            key={String(columnIndex)}
            value={_.get(item, accessor)}
          />
        ))}
      </ListViewRowWrapper>
    ))}
  </ListViewWrapper>
);
