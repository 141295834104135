import React from "react";

import { Clear as ClearIcon } from "@material-ui/icons";
import styled from "styled-components";

const icons = {};

function importAll(r) {
  r.keys().forEach(key => {
    return (icons[key.replace("./", "")] = r(key));
  });
}

importAll(
  require.context("!@svgr/webpack!../../../assets/icons", true, /\.svg$/),
);

export interface IconProps {
  fill?: string;
  group?: "" | "color" | "filled" | "linear" | "thin" | undefined;
  height?: number | string;
  name: string;
  viewBox?: string;
  width?: number | string;
}

export default styled((props: IconProps) => {
  const defaultSize = props.group && props.group === "thin" ? 40 : 24;
  const defaultFill = props.group && props.group === "color" ? "" : "gray";
  const {
    fill = defaultFill,
    group,
    height = defaultSize,
    name,
    width = defaultSize,
    viewBox = `0 0 ${defaultSize} ${defaultSize}`,
    ...restProps
  } = props;

  const { default: Icon } = React.useMemo(() => {
    const icon = icons[`${group ? `${group}/` : ""}${name}.svg`];
    if (typeof icon !== "undefined") {
      return icon;
    }
    return { default: () => <ClearIcon /> };
  }, [group, name]);

  return React.createElement(Icon, {
    fill,
    height,
    width,
    viewBox,
    ...restProps,
  });
})`
  path[fill] {
    fill: ${({ fill }) => fill};
  }
`;
