import React from "react";

import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "styled-components";

const ExpansionPanelWrapper = styled.div``;

const StyledTitle = styled(props => <Typography {...props} />)`
  & {
    font-weight: bold;
    color: ${props => props.theme.palette.text.primary};
  }
`;

const ExpansionPanel = styled(props => <MuiExpansionPanel {...props} />)`
  & {
    margin-bottom: 24px;
  }
`;

const ExpansionPanelSummary = styled(props => (
  <MuiExpansionPanelSummary {...props} />
))`
  & {
    height: 88px;
    padding-left: 10px;
    padding-right: 10px;

    .MuiIconButton-root {
      order: -1;
      margin-right: 5px;
      margin-left: 5px;

      .MuiIconButton-label {
        background: #edf1f7;
        border-radius: 50%;
      }
    }
  }
`;

const ExpansionPanelDetails = styled(props => (
  <MuiExpansionPanelDetails {...props} />
))`
  & {
    padding: 0 0 0 0 !important;
  }
`;

interface IPanel {
  content: string | React.Component;
  id: number | string;
  title: string | React.Component;
  onChange?: (event: React.SyntheticEvent<any>, expanded: boolean) => void;
  expanded?: boolean;
}

export type ExpansionPanelProps = {
  panels: IPanel[];
};

export default ({ panels }: ExpansionPanelProps): JSX.Element => {
  return (
    <ExpansionPanelWrapper>
      {panels.map(({ id, title, expanded, content, onChange }) => (
        <ExpansionPanel expanded={expanded} onChange={onChange} key={id}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id={id}
          >
            <StyledTitle>{title}</StyledTitle>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>{content}</ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </ExpansionPanelWrapper>
  );
};
