import React, { ReactNode } from "react";

import classNames from "classnames";
import { DropTargetMonitor, useDrop } from "react-dnd";

export interface DroppableProps {
  accept: (string | symbol)[] | string | symbol;
  children: ReactNode;
  className?: string;
  data?: any;
  style?: Record<string, any>;
  onDrop?: (item: Record<string, any>, monitor: DropTargetMonitor) => void;
}

export default ({
  accept,
  data,
  className,
  children,
  style,
  onDrop,
}: DroppableProps) => {
  const [, drop] = useDrop({
    accept,
    drop: (item, monitor) => {
      onDrop?.(item, monitor);
    },
  });
  return (
    <div className={classNames(className)} style={{ ...style }} ref={drop}>
      {children}
    </div>
  );
};
