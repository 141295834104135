import React from "react";

import CoreCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styled from "styled-components";

export type CheckboxProps = MuiCheckboxProps & {
  label?: React.ReactNode;
  onChange: Function;
};

export default styled(
  ({ label, className, ...rest }: Readonly<CheckboxProps>) => (
    <FormControlLabel
      control={<CoreCheckbox size="medium" color="primary" {...rest} />}
      label={label}
      className={className}
    />
  ),
)`
  .MuiCheckbox-colorPrimary.Mui-checked:not(.Mui-disabled) {
    color: ${props => props.theme.palette.controlPrimaryColor};
  }
  .MuiCheckbox-root {
    color: ${props => props.theme.palette.controlSecondaryColor};
  }
  .MuiSvgIcon-root {
    font-size: 24px;
  }
`;
