import React from "react";

import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";

const DarkTooltip = withStyles(props => ({
  tooltip: {
    backgroundColor: "#232f3e",
    color: "White",
    boxShadow: "none",
    fontSize: 12,
    fontWeight: "normal",
    padding: "4px 8px",
    borderRadius: "4px",
    lineHeight: "1.33",
    marginTop: "1px",
  },
}))(Tooltip);

const ChildrenWrapper = styled.div`
  display: inline-block;
`;

export type TooltipProps = {
  text: string;
  children: any;
  enabled?: boolean;
};

export default ({
  text = "",
  children,
  enabled = true,
}: TooltipProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(enabled ? true : false);
  };

  return (
    <DarkTooltip
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      title={text}
    >
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </DarkTooltip>
  );
};
