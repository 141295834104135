import React from "react";

import _ from "lodash";

import { FaInfoCircle } from "react-icons/fa";
import styled from "styled-components";

const clsPrefix = "explorer-statistic";

const StatisticWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StatisticIconWrapper = styled.div`
  margin-right: 10px;

  &.large {
    font-size: 32px;

    path[fill],
    path[d],
    svg {
      width: 50px;
      height: 50px;
    }
  }

  &.medium {
    font-size: 25px;

    path[fill],
    path[d],
    svg {
      width: 32px;
      height: 32px;
    }
  }
`;

const StatisticInfoWrapper = styled.div`
  color: ${props => props.theme.palette.text.primary};

  .${clsPrefix}-info-title {
    font-size: 22px;
    font-weight: bold;

    &.large {
      font-size: 26px;
    }
  }

  .${clsPrefix}-info-description {
    display: flex;
    align-items: flex-start;

    &.large {
      font-size: 26px;

      .text {
        width: 100px;
      }
    }

    .text {
      font-size: 13px;
      color: ${props => props.theme.palette.text.secondary};
      font-weight: 500;
      position: relative;

      .info {
        top: 5px;
        right: -20px;
        position: absolute;
      }

      .info svg {
        font-size: 16px;
        color: ${props => props.theme.palette.text.secondary};
      }
    }
  }
`;

type StaticticProps = {
  desc: string;
  IconComponent: React.Component;
  size?: string;
  title: number | string;
};

export default ({ size = "medium", ...props }: StaticticProps): JSX.Element => (
  <StatisticWrapper className={clsPrefix}>
    <StatisticIconWrapper className={`${clsPrefix}-icon ${size}`}>
      {props.IconComponent}
    </StatisticIconWrapper>
    <StatisticInfoWrapper className={`${clsPrefix}-info`}>
      <div className={`${clsPrefix}-info-title ${size}`}>
        {_.isNumber(props.title) ? props.title.toLocaleString() : props.title}
      </div>
      <div className={`${clsPrefix}-info-description ${size}`}>
        <div className="text">
          {props.desc}
          <FaInfoCircle className="info" />
        </div>
      </div>
    </StatisticInfoWrapper>
  </StatisticWrapper>
);
