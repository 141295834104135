import React, { useState } from "react";

import { Input, Select } from "atoms";

import styled from "styled-components";

import BaseMatrix from "molecules/Matrices/BaseMatrix";

import AddItem from "../../AddItem";
import ConfirmationDialog from "../../ConfirmationDialog";

const selectStyle = selectProps => `
  min-width: 20px;
  .MuiSelect-outlined.MuiSelect-outlined {
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

const formControlStyle = formControlProps => `
  max-width: 110px;
  font-size: 16px;
`;

const menuItemStyle = menuItemProps => `
  min-height: 36px!important;
`;

const adderStyle = adderProps => {
  const adderColor = adderProps.theme.palette.info.main;
  return `color: ${adderColor};`;
};

const DialogContentWrapper = styled.div`
  .text {
    margin-top: 0;
  }
`;

type DropdownMatrixProps = {
  disabled: boolean;
  matrix: any;
  onOptionsUpdate?: Function;
  onSelectionUpdate: Function;
  selections: any[];
};

const DropdownMatrix = ({
  matrix,
  selections,
  onSelectionUpdate,
  onOptionsUpdate,
  disabled,
  ...rest
}: DropdownMatrixProps): JSX.Element => {
  const convertArrayToNewlineStrings = arr => arr.join("\r\n");
  const convertNewlineStringsToArray = str => str.split(/\r?\n/);
  const initOptionsDialog = {
    columnIndex: null,
    open: false,
    options: "",
  };
  const [optionsDialog, setOptionsDialog] = useState(initOptionsDialog);
  const cancelEditOptions = () => {
    setOptionsDialog(initOptionsDialog);
  };
  const initiateEditOptions = (_column, colIndex) => {
    const currentOptions = _column[colIndex].options.map(item => item.text);
    const listStr = convertArrayToNewlineStrings(currentOptions);
    setOptionsDialog({
      columnIndex: colIndex,
      open: true,
      options: listStr,
    });
  };
  const handleOptionsEdit = str => {
    setOptionsDialog(obj => ({
      columnIndex: obj.columnIndex,
      open: obj.open,
      options: str,
    }));
  };
  const saveNewOptions = () => {
    const currentOptionsDialog = { ...optionsDialog };
    const newOptionSet = convertNewlineStringsToArray(
      currentOptionsDialog.options,
    );
    const newOptions = newOptionSet.map(op => ({
      id: op,
      text: op,
    }));

    if (onOptionsUpdate) {
      onOptionsUpdate({
        columnIndex: currentOptionsDialog.columnIndex,
        options: newOptions,
      });
    }

    setOptionsDialog(initOptionsDialog);
  };
  const rows = matrix[0];
  const columns = matrix[1];
  // const matrixColumns = columns.map(item => Object.keys(item)[0]);
  const matrixForBase = [rows, columns];

  const handleSelectionUpdate = (rowIndex, colIndex, value) => {
    const updateObject: any = {
      added: null,
      modified: null,
      removed: null,
      selections: [],
    };

    const recentSelections = [...selections] || [];

    const addToUpdateObject = () => {
      updateObject.added = [rowIndex, colIndex, value];
      recentSelections.push(updateObject.added);
      updateObject.selections = recentSelections;
    };

    if (recentSelections.length > 0) {
      let existingIndex: number | null = null;
      recentSelections.forEach((currentSelection, selectionIndex) => {
        if (
          currentSelection[0] === rowIndex &&
          currentSelection[1] === colIndex
        ) {
          existingIndex = selectionIndex;
        }
      });
      // Explicit not null check to handle 0 index
      if (existingIndex !== null) {
        if (value === "") {
          updateObject.removed = [
            recentSelections[existingIndex][0],
            recentSelections[existingIndex][1],
            recentSelections[existingIndex][2],
          ];
          recentSelections.splice(existingIndex, 1);
        } else {
          updateObject.modified = [
            recentSelections[existingIndex][0],
            recentSelections[existingIndex][1],
            recentSelections[existingIndex][2],
          ];
          recentSelections[existingIndex][2] = value;
        }
        updateObject.selections = recentSelections;
      } else {
        addToUpdateObject();
      }
    } else {
      addToUpdateObject();
    }
    // eslint-disable-next-line @typescript-eslint/tslint/config
    onSelectionUpdate && onSelectionUpdate(updateObject);
  };

  const getValue = (rowIndex, colIndex) => {
    let value = "";
    if (selections && selections.length > 0) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of,@typescript-eslint/tslint/config
      for (let i = 0; i < selections.length; i++) {
        const currentSelection = selections[i];
        if (
          currentSelection[0] === rowIndex &&
          currentSelection[1] === colIndex
        ) {
          value = currentSelection[2];
          break;
        }
      }
    }
    return value;
  };

  const getSelectOptions = (rowIndex, colIndex) => {
    const allOptions = [{ value: "", item: "" }];
    if (columns[colIndex]) {
      allOptions.push(
        ...columns[colIndex].options.map(item => ({
          value: item.id,
          label: item.text,
        })),
      );
    }

    return allOptions;
  };

  const renderElement = ({ rowIndex, colIndex }) => (
    <Select
      label=""
      disabled={disabled}
      id={`${rowIndex}-${colIndex}`}
      onChange={selectedValue =>
        handleSelectionUpdate(rowIndex, colIndex, selectedValue)
      }
      value={getValue(rowIndex, colIndex)}
      options={getSelectOptions(rowIndex, colIndex)}
      selectStyle={selectStyle}
      formControlStyle={formControlStyle}
      menuItemStyle={menuItemStyle}
    />
  );

  const renderAdderElement = ({ type, colIndex }) => {
    if (type === "row" && colIndex < columns.length) {
      return (
        <AddItem
          type="row"
          label="Answer Choices"
          handleAdd={() => initiateEditOptions(columns, colIndex)}
          adderStyle={adderStyle}
        />
      );
    }
    return null;
  };

  return (
    <>
      <BaseMatrix
        matrix={matrixForBase}
        renderElement={renderElement}
        renderAdderElement={renderAdderElement}
        {...rest}
      />

      <ConfirmationDialog
        title="Answer Choices"
        open={optionsDialog.open}
        confirmText="Save"
        cancelText="Cancel"
        confirmColor="info"
        onClose={cancelEditOptions}
        onCancel={cancelEditOptions}
        onConfirm={saveNewOptions}
      >
        <DialogContentWrapper>
          <p className={"text"}>Each row represents an individual option</p>
          <Input
            rows={2}
            value={optionsDialog.options}
            onChange={handleOptionsEdit}
          />
        </DialogContentWrapper>
      </ConfirmationDialog>
    </>
  );
};

export default DropdownMatrix;
