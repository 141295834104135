import React from "react";

import Typography, { TypographyProps } from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import styled from "styled-components";

import { default as theme } from "../../themes/default";

export interface TextProps extends Omit<TypographyProps, "variant"> {
  variant?:
    | "caption1"
    | "caption2"
    | "small1"
    | "small2"
    | "label"
    | TypographyProps["variant"];
  customStyle?: () => string;
}

type ParagraphOrSmallOrSpan = "p" | "small" | "span";
const paragraphOrSmallOrSpan = (
  componentProps: TextProps,
): ParagraphOrSmallOrSpan => {
  if (
    componentProps.gutterBottom ||
    componentProps.paragraph ||
    componentProps.align !== "inherit"
  ) {
    return "p";
  }
  return typeof componentProps.variant === "string" &&
    componentProps.variant.includes("small")
    ? "small"
    : "span";
};

const typography: Record<string, any> = theme.typography;
const styles = {
  root: {
    fontFamily: typography.fontFamily,
  },
  ...typography,
};
const useStyles = makeStyles(styles);

const TextComponent = (props: TextProps): JSX.Element => {
  const { children, variant, ...rest } = props;
  const classes = useStyles(props);
  let currentVariant;
  let currentClass;
  let component;

  switch (variant) {
    case "caption1":
    case "caption2":
    case "small1":
    case "small2":
    case "label":
      currentClass = `${classes.root} ${classes[variant]}`;
      component = paragraphOrSmallOrSpan(props);
      currentVariant = "body1";
      break;
    default:
      currentVariant = variant;
  }

  return (
    <Typography
      className={currentClass}
      component={component}
      variant={currentVariant}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default styled(
  (props: TextProps): JSX.Element => {
    return <TextComponent {...props}>{props.children}</TextComponent>;
  },
)`
  ${({ customStyle }: TextProps) => (customStyle ? customStyle() : "")}
`;
