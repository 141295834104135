import React from "react";

import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { StandardTextFieldProps } from "@material-ui/core/TextField/TextField";
import { useTheme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";

import SearchIcon from "@material-ui/icons/Search";

// import { ReactComponent as BellIcon } from "assets/icons/bell.svg";
// import { ReactComponent as CommentIcon } from "assets/icons/comment.svg";
import LogoutIcon from "assets/icons/log-out.svg";
import ManageProfileIcon from "assets/icons/user-cog.svg";
import { Avatar, Menu } from "atoms";

import styled from "styled-components";

import { IExtendedTheme } from "themes/default";

const ToolbarWrapper = styled.div<{ variant?: string }>`
  display: flex;
  width: 100%;
  height: 64px;
  border-radius: 0;
  padding: 12px;
  align-items: center;

  ${({ variant, theme }) =>
    variant === "default"
      ? `border-bottom: 1px solid ${theme.palette.colors.basic[300]}`
      : `background-color: white;
         box-shadow: 0px 0px 17px -7px rgba(0,0,0,0.67);`};

  .input-container {
    min-width: 600px;
    margin-left: 15px;
    padding: 0.6em 1em;
    background-color: ${({ theme }) => theme.palette.colors.basic[300]};
    border-radius: 5px;
    svg {
      margin-right: 15px;
    }
  }

  .explorer-toolbar {
    &_main {
      padding: 0 15px;
      &__drawer-toggle {
        display: none;
      }
      ${props => props.theme.breakpoints.down("sm")} {
        &__drawer-toggle {
          display: inline-block;
        }

        &__search {
          display: none;
        }
      }
    }

    &__icon-list {
      display: flex;
      align-items: center;
    }

    &__icon-container {
      margin: 0 10px;

      &:last-child {
        margin-right: 34px;
      }
    }

    &__icon {
      &_comment,
      &_bell {
        path[fill] {
          fill: #c5cee0;
        }
      }
    }

    &_actions {
      padding: 0 15px;
      margin-left: auto;
      cursor: pointer;
      display: flex;
      align-items: center;

      .explorer-toolbar_actions_content {
        display: flex;
        align-items: center;

        .explorer-toolbar_actions_name {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          font-size: 13px;
          text-transform: capitalize;
          margin-right: 16px;
          margin-left: 20px;
          ${props => props.theme.breakpoints.down("sm")} {
            display: none;
          }

          &_role {
            color: ${props => props.theme.palette.common.grey};
          }
        }
      }
    }
  }

  & {
    ${props => props.theme.breakpoints.down("sm")} {
      border-bottom: 1px solid
        ${props => props.theme.palette.common.whiteShade1};
    }
  }
`;

// The props here is the same that's passed to the ExplorerMenu at runtime.
// It can be used to access theme to build dynamic css rules as well as have rules depend on diff prop values
const explorerMenuStyle = () => `
  width: auto;
`;

const ManageProfileComp = styled(({ className }) => (
  <>
    <ListItemIcon className={className}>
      <ManageProfileIcon
        className="manage-profile-icon"
        fontSize="18"
        width="18"
        height="18"
      />
    </ListItemIcon>
    <ListItemText primary="Manage Profile" />
  </>
))`
  min-width: 26px;
  & .manage-profile-icon {
    filter: invert(42%) sepia(68%) saturate(744%) hue-rotate(159deg)
      brightness(104%) contrast(90%);
  }
`;

const LogoutComp = styled(({ className }) => (
  <>
    <ListItemIcon className={className}>
      <LogoutIcon
        className="logout-icon"
        fontSize="18"
        width="18"
        height="18"
        fill="red"
      />
    </ListItemIcon>
    <ListItemText primary="Logout" />
  </>
))`
  min-width: 26px;
  & .logout-icon {
    filter: invert(47%) sepia(94%) saturate(1722%) hue-rotate(336deg)
      brightness(99%) contrast(105%);
  }
`;

interface IUser {
  name: string;
  photo: any;
  profile: {
    firstName: string;
    photo: any;
  };
}

export type ToolbarProps = {
  children: React.ElementType;
  goManageProfile: Function;
  isTester?: boolean;
  logout: Function;
  onChange?: StandardTextFieldProps["onChange"];
  onToggleDrawer: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  user?: IUser;
  userRole?: string;
  value?: StandardTextFieldProps["value"];
  variant?: "filled" | "default";
};

export default ({
  onToggleDrawer,
  userRole,
  goManageProfile,
  isTester = false,
  children,
  value,
  onChange,
  variant,
  ...restProps
}: ToolbarProps): JSX.Element => {
  const theme: IExtendedTheme = useTheme();
  const user = restProps.user || ({} as IUser);

  const itemActions = [
    {
      comp: ManageProfileComp,
      name: "Manage Profile",
      onClick: () => goManageProfile(),
    },
    {
      comp: LogoutComp,
      name: "Logout",
      onClick: () => {
        // client.resetStore()
        // dispatch(logout({ redirectToSamePath: true }));
        restProps.logout();
      },
    },
  ];

  return (
    <ToolbarWrapper variant={variant} {...restProps}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={onToggleDrawer}
        className="explorer-toolbar_main__drawer-toggle"
      >
        <MenuIcon />
      </IconButton>
      <Input
        classes={{ root: "input-container" }}
        disableUnderline={true}
        startAdornment={
          <SearchIcon htmlColor={theme.palette.colors.basic[600]} />
        }
        placeholder="Search"
        value={value}
        onChange={onChange}
      />
      <div className="explorer-toolbar_actions">
        {children}
        <Menu
          options={itemActions}
          menuStyle={explorerMenuStyle}
          getContentAnchorEl={null}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          closeOnSelect={true}
        >
          <div className="explorer-toolbar_actions_content">
            {(user.name ||
              (isTester && user.profile && user.profile.firstName)) && (
              <>
                <div className="explorer-toolbar_actions_name">
                  {!isTester
                    ? user.name.split(" ")[0]
                    : user.profile.firstName || ""}
                  {userRole && (
                    <span className="explorer-toolbar_actions_name_role">
                      {userRole}
                    </span>
                  )}
                </div>
                <Avatar
                  image={
                    !isTester
                      ? user.photo
                      : user.profile
                      ? user.profile.photo
                      : null
                  }
                  palette={"warning"}
                  title={
                    !isTester
                      ? user.name[0]
                      : user.profile && user.profile.firstName
                      ? user.profile.firstName[0]
                      : ""
                  }
                />
              </>
            )}
          </div>
        </Menu>
      </div>
    </ToolbarWrapper>
  );
};
