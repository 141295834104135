import React from "react";

import Box from "@material-ui/core/Box";
import { Button, WidgetCard } from "atoms";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 626px;

  ${props => props.theme.breakpoints.down("sm")} {
    width: 100%;
    margin: 16px 0;
  }
`;

const cardStyle = () => `
    height: 351px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const customStyle = props => `
    margin-top: -16px;
    & .icon-block {
        margin-bottom: 10px;
    }
    & .header-text {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 5px;
    }
    & .sub-text {
        font-size: 15px;
        color: ${props.theme.palette.text.secondary};
    }
`;

export type MessageCardProps = {
  actionText: string;
  children: React.ReactNode;
  customAction?: any;
  headerText: string;
  noAction?: boolean;
  onActionClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  subText: string;
};

export default ({
  children,
  headerText,
  subText,
  actionText,
  onActionClick,
  noAction = false,
  customAction: CustomAction,
}: MessageCardProps): JSX.Element => {
  return (
    <Wrapper>
      <WidgetCard cardStyle={cardStyle} customStyle={customStyle}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <div className="icon-block">{children}</div>
          <div className="header-text">{headerText}</div>
          <div className="sub-text">{subText}</div>
        </Box>
      </WidgetCard>
      {!noAction ? (
        <Box display="flex" justifyContent="flex-end" mt={2}>
          {!CustomAction ? (
            <Button size="medium" palette="info" onClick={onActionClick}>
              {actionText}
            </Button>
          ) : (
            <CustomAction />
          )}
        </Box>
      ) : null}
    </Wrapper>
  );
};
