import React from "react";

import { Theme } from "@material-ui/core";
import { Avatar } from "atoms";
import styled from "styled-components";

const ProfileAvatarWrapper = styled.div`
  display: flex;
  align-items: center;

  .labelled_avatar_label_section {
    .labelled_avatar_label {
      font-size: 13px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 145px;
    }
  }

  .labelled_avatar_avatar_section {
    padding-right: 15px;
  }

  .labelled_avatar_label_section {
    flex-direction: column;
    justify-content: center;
  }
`;

export type ProfileAvatarProps = {
  avatar?: any;
  avatarColor: string;
  children: React.ReactNode;
  height: number;
  image: any;
  imageOnly?: boolean;
  tagPalette: string;
  tagText: string;
  theme: Theme;
  title: string;
  type: string;
  width: number;
};

export default ({
  width,
  height,
  title,
  avatar,
  image,
  avatarColor,
  tagText,
  tagPalette,
  type,
  imageOnly,
  ...props
}: ProfileAvatarProps): JSX.Element => (
  <ProfileAvatarWrapper {...props}>
    <div className="labelled_avatar_avatar_section">
      {avatar || (
        <Avatar
          theme={props.theme}
          width={width}
          height={height}
          image={image}
          title={title}
          palette={avatarColor}
          type={type || ""}
        />
      )}
    </div>
    {!imageOnly ? (
      <div className="labelled_avatar_label_section">
        <div className="labelled_avatar_label">{title}</div>
        {props.children}
      </div>
    ) : null}
  </ProfileAvatarWrapper>
);
