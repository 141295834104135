import React from "react";

import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel, {
  FormLabelProps as MuiFormLabelProps,
} from "@material-ui/core/FormLabel";
import styled, { css } from "styled-components";

export type FormLabelProps = MuiFormLabelProps & {
  customStyle?: string;
  helper?: string;
  label: string;
  required?: boolean;
};

export default styled(
  ({ children, helper, label, ...rest }): JSX.Element => (
    <div {...rest}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <FormGroup>{children}</FormGroup>
        {helper && <FormHelperText>{helper}</FormHelperText>}
      </FormControl>
    </div>
  ),
)<FormLabelProps>`
  .MuiFormLabel-root {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: ${props => props.theme.palette.colors.basic[600]};
  }

  ${props => {
    let customStyle = props.customStyle || "";

    if (props.required) {
      customStyle = customStyle.concat(`
        .MuiFormLabel-root:after {
          content: "*";
          margin-left: 5px;
        }
      `);
    }

    return css`
      ${customStyle}
    `;
  }}
`;
