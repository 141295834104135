import React from "react";

import PlusIcon from "assets/icons/plus.svg";
import styled from "styled-components";

const PlusIconWrapper = styled.span`
  path[fill] {
    fill: #8f9bb3;
  }
`;

const AdderWrapper = styled.div`
  margin: 30px 0 50px 0;
  border-top: solid 1px #1998d5;
  position: relative;
`;

const AdderButtonWrapper = styled.span`
  position: absolute;
  border: solid 1px #1998d5;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -18px;
  text-align: center;
  line-height: 12px;
  width: 39px;
  padding: 6px 7px 6px 6px;
  background-color: #fff;
  border-radius: 20px;
`;

const AdderGroupWrapper = styled.div`
  position: absolute;
  margin-left: auto;
  padding: 5px;
  margin-right: auto;
  left: 0;
  right: 0;
  line-height: 20px;
  top: -30px;
  min-height: 50px;
  background-color: #fff;
  border: solid 1px #1998d5;
  border-radius: 5px;
  display: flex;
  text-align: center;
  max-width: 400px;
`;

const AdderGroupItemWrapper = styled.div`
  flex: 1;
  .label {
    font-size: 11px;
    color: ${props => props.theme.palette.text.secondary};
  }

  .icon {
    color: #2c3e66;
    font-size: 25px;
    path[fill] {
      fill: #2c3e66;
    }
  }
`;

type AdderGroupItemProps = {
  icon: React.ReactNode;
  label: string;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const AdderGroupItem = ({
  onClick,
  icon,
  label,
}: AdderGroupItemProps): JSX.Element => (
  <AdderGroupItemWrapper onClick={onClick}>
    <div className="icon">{icon}</div>
    <div className="label">{label}</div>
  </AdderGroupItemWrapper>
);

export type AdderProps = {
  options: any[];
};

export default ({ options }: AdderProps): JSX.Element => {
  const [mode, setMode] = React.useState("button");

  const onClick = option => {
    setMode("button");
    option.onClick();
  };

  const handleOptionsClick = option => onClick(option);
  const renderOptions = option => (
    <AdderGroupItem {...option} onClick={handleOptionsClick} />
  );

  const handleClick = () => setMode("group");

  return (
    <AdderWrapper>
      {mode === "button" ? (
        <AdderButtonWrapper>
          <PlusIconWrapper onClick={handleClick}>
            <PlusIcon fill="#8f9bb3" />
          </PlusIconWrapper>
        </AdderButtonWrapper>
      ) : (
        <AdderGroupWrapper>
          {(options || []).map(renderOptions)}
        </AdderGroupWrapper>
      )}
    </AdderWrapper>
  );
};
