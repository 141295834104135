const basic = {
  100: "#ffffff",
  1000: "#151a30",
  1100: "#101426",
  200: "#f7f9fc",
  300: "#edf1f7",
  400: "#e4e9f2",
  500: "#c5cee0",
  600: "#8f9bb3",
  700: "#2e3a59",
  800: "#222b45",
  900: "#192038",
};

export default basic;
