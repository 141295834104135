import styled from "styled-components";

import { basic } from "themes/colors";

export interface DividerProps {
  borderColor?: string;
  borderStyle?: "none" | "solid" | "dashed" | "dotted";
  padding?: number;
  thickness?: number;
}

export default styled.hr`
  ${({
    borderStyle = "solid",
    borderColor = basic[600],
    padding = 40,
    thickness = 3,
  }: DividerProps) => `
    border-style: none;
    border-top: ${thickness}px ${borderStyle} ${borderColor};
    margin: ${padding}px auto;
  `}
`;
