import React from "react";

import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MaterialRadio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import styled, { css } from "styled-components";

const ExplorerRadio = styled(props => (
  <FormControlLabel classes={{ root: "explorer-radio-label" }} {...props} />
))`
  ${props =>
    css`
      ${props.radioStyle ? props.radioStyle(props) : ""}
    `};
  margin-right: 20px;

  .MuiTypography-root {
    font-size: 13px;
  }
`;

const FormControlWrapper = styled(FormControl)<any>`
  .MuiFormHelperText-root {
    color: #f44336;
  }
`;

export type RadioProps = FormControlProps & {
  error?: Error;
  name?: string;
  onChange: Function;
  options: any[];
  register: React.Ref<any>;
  value: any;
};

export default styled(
  ({
    name,
    defaultValue,
    options,
    error,
    register,
    value,
    onChange,
    className,
  }: Readonly<RadioProps>): JSX.Element => {
    const renderOptions = option => (
      <ExplorerRadio
        {...option}
        key={option.value}
        control={<MaterialRadio color="primary" inputRef={register} />}
      />
    );

    return (
      <FormControlWrapper className={className} component="fieldset">
        <RadioGroup
          row={true}
          name={name}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
        >
          {options.map(renderOptions)}
        </RadioGroup>
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </FormControlWrapper>
    );
  },
)`
  .MuiRadio-root {
    color: ${props => props.theme.palette.controlSecondaryColor};
  }

  .MuiRadio-colorPrimary.Mui-checked {
    color: ${props => props.theme.palette.controlPrimaryColor};
  }

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    font-size: 20px;
  }

  .MuiFormControlLabel-root {
    font-size: 13px;
    line-height: 1.85;
    color: ${props => props.theme.palette.controlLabelColor};
  }
`;

// .MuiSvgIcon-root
