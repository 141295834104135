import React from "react";

import { Theme } from "@material-ui/core";
import { MdLayersClear } from "react-icons/md";
import styled from "styled-components";

const EmptyTableWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .empty-table-preview {
    color: #bbb;
    text-align: center;

    .preview-icon {
      font-size: 2.5rem;
    }

    .preview-message {
      font-size: 18px;
    }
  }
`;

const EmptyTable = ({ theme }: { theme?: Theme }): JSX.Element => (
  <EmptyTableWrapper theme={theme}>
    <div className="empty-table-preview">
      <div className="preview-icon">
        <MdLayersClear />
      </div>
      <div className="preview-message">Nothing to show here for now</div>
    </div>
  </EmptyTableWrapper>
);

export default EmptyTable;
