import React, { HTMLAttributes } from "react";

import styled from "styled-components";

const StyledLabel = styled.div<
  HTMLAttributes<HTMLDivElement> & { diffType: string }
>`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  justify-content: center;
  font-weight: 500;
  & .indicators {
    display: flex;
    align-items: center;
    & .count {
      font-size: 12px;
      color: ${props => props.theme.palette.infoInvert.contrastText};
    }
    & .count-change {
      font-size: 10px;
      color: ${props =>
        props.diffType === "negative"
          ? props.theme.palette.warning.contrastText
          : props.diffType === "positive"
          ? props.theme.palette.success.contrastText
          : props.theme.palette.infoInvert.contrastText};
      margin-left: 5px;
      margin-top: 2px;
    }
  }
`;

export type IndicatorLabelProps = {
  count: number;
  difference: number;
  diffType?: string;
  label: string;
  showDiff?: boolean;
};

export default ({
  label,
  count,
  difference,
  showDiff = true,
}: IndicatorLabelProps): JSX.Element => {
  const getDiff = () => {
    if (difference < 0) {
      return `${difference}`;
    } else if (difference > 0) {
      return `+${difference}`;
    }
  };
  return (
    <StyledLabel diffType={difference < 0 ? "negative" : "positive"}>
      <div>{label}</div>
      <div className="indicators">
        <div className="count">{count}</div>
        {showDiff ? <div className="count-change">{getDiff()}</div> : null}
      </div>
    </StyledLabel>
  );
};

// Usage
// diffType default negative if not provided, showDiff true if not provided
// <ExplorerIndicatorLabel label="Location" count="100" difference="20" diffType="positive" showDiff={false}/>
