import { HTMLAttributes } from "react";

import styled, { css } from "styled-components";

const responsiveStyles = props => {
  const responsiveProps = props.responsive;
  let styles = "";

  if (responsiveProps) {
    if (responsiveProps.xs) {
      styles += `
      @media (min-width: ${props.theme.breakpoints.values.xs}px) and (max-width: ${props.theme.breakpoints.values.sm}px) {
        ${responsiveProps.xs}
      }
    `;
    }

    if (responsiveProps.sm) {
      styles += `
      @media (min-width: ${props.theme.breakpoints.values.sm}px) and (max-width: ${props.theme.breakpoints.values.md}px) {
        ${responsiveProps.sm}
      }
    `;
    }

    if (responsiveProps.md) {
      styles += `
      @media (min-width: ${props.theme.breakpoints.values.md}px) and (max-width: ${props.theme.breakpoints.values.lg}px) {
        ${responsiveProps.md}
      }
    `;
    }

    if (responsiveProps.lg) {
      styles += `
      @media (min-width: ${props.theme.breakpoints.values.lg}px) and (max-width: ${props.theme.breakpoints.values.xl}px) {
        ${responsiveProps.lg}
      }
    `;
    }
  }

  return styles;
};

type EditableWrapperProps = {
  editableStyle?: Function;
  pencilStyle?: Function;
};

const EditableWrapper = styled.div<
  HTMLAttributes<HTMLDivElement> & EditableWrapperProps
>`
  position: relative;
  display: inline-block;
  ${props => (props.editableStyle ? props.editableStyle(props) : "")};

  ${props =>
    responsiveStyles({
      ...props,
      responsive: {
        sm: "width: 90%; max-width: 350px;",
        xs: "width: 90%; max-width: 350px;",
      },
    })}

  .pencil-icon {
    cursor: pointer;
    font-size: 13px;
    top: 0;
    padding-left: 20px;
    right: -20px;
    color: ${props => props.theme.palette.text.secondary};
    position: absolute;
    visibility: hidden;

    ${props =>
      css`
        ${props.pencilStyle ? props.pencilStyle(props) : ""}
      `}

    ${props =>
      responsiveStyles({
        ...props,
        responsive: {
          sm: "visibility: visible;",
          xs: "visibility: visible;",
        },
      })}
  }

  &:hover {
    .pencil-icon {
      visibility: visible;
    }
  }
`;

export default EditableWrapper;
