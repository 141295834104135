import React from "react";

import MaterialSwitch, { SwitchProps } from "@material-ui/core/Switch";
import styled from "styled-components";

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ConditionLabel = styled.div`
  font-size: 15px;
  font-weight: bold;
`;

export type ISwitchProps = SwitchProps & {
  conditional?: boolean;
  leftLabel?: string;
  rightLabel?: string;
};

export default styled(
  ({
    conditional,
    leftLabel,
    rightLabel,
    className,
    ...props
  }: Readonly<ISwitchProps>): JSX.Element => (
    <SwitchWrapper className={className}>
      {conditional && <ConditionLabel>{leftLabel}</ConditionLabel>}
      <MaterialSwitch {...props} />
      {conditional && <ConditionLabel>{rightLabel}</ConditionLabel>}
    </SwitchWrapper>
  ),
)`
  font-size: 10px;
  font-weight: 600;
  color: ${props => props.theme.palette.controlFillColor};

  .MuiSwitch-colorPrimary.Mui-checked:not(.Mui-disabled) {
    color: ${props => props.theme.palette.controlPrimaryColor};
  }
  .MuiSwitch-colorPrimary + .MuiSwitch-track {
    opacity: 1;
    background-color: ${props =>
      props.theme.palette.controlSecondaryBackgroundColor};
  }
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: ${props =>
      props.theme.palette.controlPrimaryBackgroundColor};
  }
`;
