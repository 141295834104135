import React from "react";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import RemoveImageIcon from "assets/icons/log-out.svg";
import UploadNewImageIcon from "assets/icons/user-cog.svg";
import { IOptionsProps, Menu } from "atoms"; // todo: circle dependency!!

import { FaPencilAlt } from "react-icons/fa";
import styled from "styled-components";

import EditableWrapper from "atoms/Editable/EditableWrapper";

const RemoveImageComp = styled(({ className }) => (
  <>
    <ListItemIcon className={className}>
      <UploadNewImageIcon
        className="remove-image-icon"
        fontSize="18"
        width="18"
        height="18"
      />
    </ListItemIcon>
    <ListItemText primary="Remove Image" />
  </>
))`
  min-width: 26px;
  & .remove-image-icon {
    color: red;
  }
`;

const UploadNewImageComp = styled(
  ({ className }): JSX.Element => (
    <>
      <ListItemIcon className={className}>
        <RemoveImageIcon
          className="upload-new-icon"
          fontSize="18"
          width="18"
          height="18"
          fill="red"
        />
      </ListItemIcon>
      <ListItemText primary="Upload New Image" />
    </>
  ),
)`
  min-width: 26px;
  & .upload-new-icon {
    color: red;
  }
`;

// The props here is the same that's passed to the ExplorerMenu at runtime.
// It can be used to access theme to build dynamic css rules as well as have rules depend on diff prop values
const explorerMenuStyle = () => `
  width: auto;
  margin-top: 40px;
  margin-left: -20px;
`;

const pencilStyle = () => `
top:10px;
right:10px;
`;

type ImageEditableProps = {
  children: React.ReactNode;
  editableStyle: Function;
  removeImage: Function;
  uploadNewImage: Function;
};

const ImageEditable = ({
  uploadNewImage,
  removeImage,
  editableStyle,
  children,
}: ImageEditableProps): JSX.Element => {
  const [show, set] = React.useState(false);
  const [menuOpen, isMenuOpen] = React.useState(false);

  const itemActions: IOptionsProps[] = [
    {
      comp: UploadNewImageComp,
      name: "Upload New Image",
      onClick: () => {
        uploadNewImage();
      },
    },
    {
      comp: RemoveImageComp,
      name: "Remove Image",
      onClick: () => {
        removeImage();
      },
    },
  ];

  const originItems = {
    anchor: {
      horizontal: "right",
      vertical: "bottom",
    },
    transform: {
      horizontal: "left",
      vertical: "bottom",
    },
  };

  const handleSelect = () => isMenuOpen(true);
  const handleLeave = () => set(false);
  const handleClose = () => {
    isMenuOpen(false);
    set(false);
  };

  const renderExploreMeny = () => (
    <Menu
      options={itemActions}
      menuStyle={explorerMenuStyle}
      getContentAnchorEl={null}
      anchorOrigin={originItems.anchor}
      transformOrigin={originItems.transform}
      onSelect={handleSelect}
      onClose={handleClose}
      closeOnSelect={true}
    >
      <span className="pencil-icon">
        <FaPencilAlt />
      </span>
    </Menu>
  );

  return (
    <EditableWrapper
      editableStyle={editableStyle}
      pencilStyle={pencilStyle}
      onMouseEnter={handleSelect}
      onMouseLeave={handleLeave}
    >
      {children}
      {(show || menuOpen) && renderExploreMeny()}
    </EditableWrapper>
  );
};

export default ImageEditable;
