import React from "react";

import { Grid } from "@material-ui/core";
import { Button } from "atoms";
import moment from "moment";
import styled from "styled-components";

const SurveySummaryWrapper = styled.div`
  padding: 24px;
  background: white;
  border-radius: 4px;

  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__block {
      display: flex;
      flex-direction: column;

      &_left-align {
        align-items: flex-start;
      }

      &_right-align {
        align-items: flex-end;
      }
    }

    &__title {
      font-size: 10px;
      font-weight: normal;
      letter-spacing: normal;
      color: #8f9bb3;

      margin: 0;
    }

    &__subtitle {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.6;
      color: #222b45;

      margin: 0;
    }

    &__date {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.33;
      color: #222b45;

      margin: 0;
    }
  }

  .desc {
    font-size: 10px;
    line-height: normal;
    color: #8f9bb3;

    margin: 16px 0 0 0;
  }

  .manage-row {
    margin: 24px 0 0 0;
    display: flex;

    justify-content: flex-end;

    button {
      margin: 0;
    }
  }
`;

export type SurveySummaryProps = {
  date: Date;
  desc: string;
  id?: number | string;
  isNew: boolean;
  onStartSurvey: Function;
  title: string;
};

export default ({
  id,
  title,
  isNew,
  date,
  desc,
  onStartSurvey,
}: SurveySummaryProps): JSX.Element => {
  const onStartSurveyHandler = React.useCallback(
    (...value) => onStartSurvey(...value),
    [onStartSurvey],
  );

  return (
    <SurveySummaryWrapper>
      <div className={"header"}>
        <Grid
          item={true}
          xs={6}
          sm={6}
          md={6}
          lg={6}
          className={"header__block header__block_left-align"}
        >
          <p className={"header__title"}>{title}</p>
          <p className={"header__subtitle"}>
            {isNew ? "Review Survey" : "Final Project Survey"}
          </p>
        </Grid>
        {date && (
          <Grid
            item={true}
            xs={6}
            sm={6}
            md={6}
            lg={6}
            className={"header__block header__block_right-align"}
          >
            <p className={"header__title"}>Due Date</p>
            <p className={"header__date"}>{moment(date).format("LL")}</p>
          </Grid>
        )}
      </div>
      <p className={"desc"}>{desc}</p>
      <div className={"manage-row"}>
        <Button
          size="medium"
          variant="contained"
          palette={isNew ? "primary" : "info"}
          onClick={() => onStartSurveyHandler(id)}
        >
          {isNew ? "Start Survey" : "Continue Survey"}
        </Button>
      </div>
    </SurveySummaryWrapper>
  );
};
