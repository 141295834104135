import React from "react";

import styled from "styled-components";

import { IExtendedTheme } from "themes/default";

export type ContentBoxProps = {
  children?: React.ReactNode;
  width?: string;
  paddings?: string;
  theme: IExtendedTheme;
};

export default styled(
  ({ children, ...args }: Readonly<ContentBoxProps>): JSX.Element => {
    return <div {...args}>{children}</div>;
  },
)`
  background-color: ${(props: ContentBoxProps) =>
    props.theme.palette.contentBox.backgroundColor};
  border: solid 0.5px
    ${(props: ContentBoxProps) => props.theme.palette.contentBox.borderColor};
  padding: ${({ theme, paddings }: ContentBoxProps) =>
    paddings || theme.sizes.contentBox.defaultPaddings};
  border-radius: 4px;
  max-width: ${({ width, theme }: ContentBoxProps) =>
    width || theme.sizes.contentBox.defaultWidth};
`;
