import React from "react";

import { Dialog, DialogContent } from "@material-ui/core";
import styled from "styled-components";

const StyledDialog = styled(({ className, modalHeight, ...rest }) => {
  const { closeAction, openState, ...others } = rest;
  return (
    <Dialog
      disablePortal={true}
      onClose={closeAction}
      open={openState}
      fullWidth={true}
      className={className}
      classes={{ container: "container", paperFullWidth: "paper-full-width" }}
      BackdropProps={{ classes: { root: "backdrop" } }}
      style={{ position: "absolute" }}
      {...others}
    />
  );
})`
  position: absolute;
  height: ${props => (props.modalHeight ? props.modalHeight : 500)}px;
  & .paper-full-width {
    width: auto;
  }
  & .container {
    overflow: hidden;
  }
  & .backdrop {
    position: absolute;
    background-color: transparent;
  }
`;

export type InPlaceModalProps = {
  children: React.ReactNode;
  modalHeight: number;
  onClose: Function;
  open: boolean;
};

export default ({
  onClose,
  open,
  modalHeight,
  children,
}: InPlaceModalProps): JSX.Element => (
  <StyledDialog
    closeAction={onClose}
    openState={open}
    modalHeight={modalHeight}
  >
    <DialogContent>{children}</DialogContent>
  </StyledDialog>
);
