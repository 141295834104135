import React from "react";

import TableCell from "@material-ui/core/TableCell";
import { SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";

const TableCellWrapper = styled(TableCell)<{ rowHeight?: number }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex: 1;
  height: ${props => props.rowHeight || 72}px;

  .explorer-table.table-cell {
    flex: 1;
    font-size: 13px;
    text-align: ${props => props.align || "center"};
  }
`;

const DragHandle = SortableHandle(({ children }) => <>{children}</>);

const isRowSortable = (index, dataKey) =>
  index >= 0 && dataKey === "__reorder__"; // Header row should not be draggable

type CellProps = {
  alignment: "inherit" | "left" | "center" | "right" | "justify" | undefined;
  Cell: any;
  cellData: any;
  dataKey: string;
  handleClick: (data: object) => void;
  height?: number;
  rowData: any;
  rowIndex: number;
};

const Cell = (props: CellProps): JSX.Element => {
  const {
    Cell: PropsCell,
    cellData,
    alignment,
    height,
    rowData,
    rowIndex,
    dataKey,
  } = props;

  return (
    <TableCellWrapper
      variant="body"
      style={{ height }}
      component="div"
      align={alignment}
      onClick={e => {
        if (props.handleClick) {
          props.handleClick({ rowData });
        }
      }}
    >
      <div className="explorer-table table-cell">
        {PropsCell ? (
          isRowSortable(rowIndex, dataKey) ? (
            <DragHandle>
              <PropsCell {...props} />
            </DragHandle>
          ) : (
            <PropsCell {...props} />
          )
        ) : isRowSortable(rowIndex, dataKey) ? (
          <DragHandle>{cellData}</DragHandle>
        ) : (
          <span>{cellData}</span>
        )}
      </div>
    </TableCellWrapper>
  );

  /* if (isRowSortable(rowIndex)) {
    console.log("CONSIDERATION _____ -----")
    return <div className="dragColumn"><DragHandle>{CellComponent}</DragHandle></div>;
  } else {
    return <div>{CellComponent}</div>;
  } */
};

export default Cell;
