export default {
  date: {
    format: "dd/mm/yyyy",
  },
  platformContent: {
    faq: "FAQ",
    help: "HELP",
    privacy: "PRIVACY_POLICY",
    terms: "TERMS_OF_USE",
    contactUs: "CONTACT_US",
    legal: "LEGAL_FOOTER",
  },
  subscription: {
    monthly: {
      name: "Monthly Price",
      type: "MONTHLY",
    },
    yearly: {
      name: "Annual Price",
      type: "YEARLY",
    },
  },
  avatarPalette: {
    solid: {
      green: "solidSuccess",
      red: "solidWarning",
      blue: "solidInfoLight",
      tangerine: "solidTangerine",
    },
    light: {
      green: "success",
      red: "warning",
      blue: "infoLight",
      tangerine: "tangerine",
    },
  },
  roles: {
    companyAdmin: "COMPANY_ADMIN",
    platformAdmin: "PLATFORM_ADMIN",
    projectCoordinator: "PROJECT_COORDINATOR",
    projectManager: "PROJECT_MANAGER",
  },
  projectStatuses: {
    active: "ACTIVE",
    archived: "ARCHIVED",
    completed: "COMPLETED",
    terminated: "TERMINATED",
  },
  wideSurveyStatuses: {
    active: "ACTIVE",
    archived: "ARCHIVED",
    closed: "CLOSED",
    collectingResponses: "COLLECTING_RESPONSED",
    draft: "DRAFT",
    paused: "PAUSED",
    scheduled: "SCHEDULED",
  },
  projectSurveyStatuses: {
    active: "ACTIVE",
    archived: "ARCHIVED",
    closed: "CLOSED",
    draft: "DRAFT",
    paused: "PAUSED",
    scheduled: "SCHEDULED",
  },
  questionStatus: {
    active: "ACTIVE",
    archived: "ARCHIVED",
  },
  questionType: {
    checkbox: "CHECKBOX",
    checkboxMatrix: "CHECKBOX_MATRIX",
    radio: "RADIO",
    radioMatrix: "RADIO_MATRIX",
    selectMatrix: "SELECT_MATRIX",
    text: "TEXT",
  },
  announcementStatuses: {
    active: "ACTIVE",
    archived: "ARCHIVED",
  },
  documentStatuses: {
    active: "ACTIVE",
    archived: "ARCHIVED",
  },
  integrationSections: [
    {
      allowMultiple: true,
      id: 1,
      title: "Contract Management Integrations",
      visible: true,
      integrations: [
        {
          id: "CONTRACT_CENTRAL",
          groupId: 1,
          title: "Contract Central",
          desc:
            "Use Amazon’s Contract lifecycle management tool for contract templates, execution and archive.",
          image: "aws.png",
          attributes: {
            redirectUri: {
              required: "Required",
              label: "Redirect URI",
            },
            integrationKey: {
              encrypt: true,
              required: "Required",
              label: "Integration Key",
            },
            secretKey: {
              encrypt: true,
              required: "Required",
              label: "Secret Key",
            },
          },
          multi: false,
        },
        {
          id: "DOCUSIGN",
          groupId: 1,
          title: "DocuSign",
          desc:
            "Integrate direct with Docusign to manage contract templates, execution and archive.",
          image: "docu_sign.png",
          attributes: {
            redirectUri: {
              required: "Required",
              label: "Redirect URI",
            },
            integrationKey: {
              encrypt: true,
              required: "Required",
              label: "Integration Key",
            },
            secretKey: {
              encrypt: true,
              required: "Required",
              label: "Secret Key",
            },
          },
          multi: false,
        },
      ],
    },
    {
      id: 2,
      title: "Issue Management Integration",
      visible: true,
      integrations: [
        {
          id: "JIRA",
          groupId: 2,
          title: "Jira",
          desc:
            "Integrate with Jira Server or Jira Cloud to push bugs & feature requests direct to your issue tracking provider.",
          image: "jira.png",
          attributes: {
            baseUrl: {
              required: "Required",
              label: "Base URL",
            },
            username: {
              required: "Required",
              label: "Username",
            },
            password: {
              encrypt: true,
              required: "Required",
              label: "Password",
            },
            projectName: {
              required: "Required",
              label: "Project Name",
            },
          },
          multi: true,
        },
      ],
    },
    {
      id: 3,
      title: "Compliance Integrations",
      visible: false,
      allowMultiple: true,
    },
    {
      id: 4,
      title: "Shipping Integrations",
      visible: true,
      allowMultiple: true,
      integrations: [
        {
          id: "SHOPIFY",
          groupId: 4,
          title: "Shopify",
          desc:
            "Utilize the Shopify integration to ship & manage products for testing.",
          image: "shopify.png",
          attributes: {
            redirectUri: {
              required: "Required",
              label: "Redirect URI",
            },
            username: {
              encrypt: true,
              required: "Required",
              label: "Client ID",
            },
            password: {
              required: "Required",
              encrypt: true,
              label: "Client Secret",
            },
            code: {
              required: "Required",
              label: "Code",
            },
          },
          multi: false,
        },
        {
          id: "UPS",
          groupId: 4,
          title: "UPS",
          desc:
            "Setup your UPS Account to manage return labels and shipping status.",
          image: "ups.png",

          attributes: {
            redirectUri: {
              required: "Required",
              label: "Redirect URI",
            },
            username: {
              encrypt: true,
              required: "Required",
              label: "Client ID",
            },
            password: {
              required: "Required",
              encrypt: true,
              label: "Client Secret",
            },
            code: {
              required: "Required",
              label: "Code",
            },
          },
          multi: false,
        },
      ],
    },
    {
      id: 5,
      title: "Custom Cohorting Integrations",
      visible: false,
      allowMultiple: true,
    },
  ],
  companyRoles: {
    admin: "ADMINISTRATOR",
    projectManager: "PROJECT_MANAGER",
    projectCoord: "PROJECT_COORDINATOR",
    tester: "TESTER",
  },
  companyRolesLabel: {
    ADMINISTRATOR: "Company Administrator",
    PROJECT_MANAGER: "Project Manager",
    PROJECT_COORDINATOR: "Project Coordinator",
  },
  projectRoles: {
    projectManager: "PROJECT_MANAGER",
    projectCoord: "PROJECT_COORDINATOR",
  },
  projectRolesLabel: {
    PROJECT_MANAGER: "Project Manager",
    PROJECT_COORDINATOR: "Project Coordinator",
  },
  projectHealth: {
    GREEN: {
      value: "GREEN",
      theme: "success",
    },
    YELLOW: {
      value: "YELLOW",
      theme: "tangerine",
    },
    RED: {
      value: "RED",
      theme: "warning",
    },
  },
  answerType: {
    text: "TEXT",
    radio: "RADIO",
    checkbox: "CHECKBOX",
    radioMatrix: "RADIO_MATRIX",
    checkboxMatrix: "CHECKBOX_MATRIX",
    selectMatrix: "SELECT_MATRIX",
  },
  surveySteps: {
    details: "survey-details",
    questions: "questions",
    targetAudience: "target-audience",
    preview: "preview",
    compose: "compose",
  },
  surveySectionTypes: {
    pagebreak: "PAGE_BREAK",
    sectionbreak: "SECTION_BREAK",
    texteditor: "TEXT_BLOCK",
    question: "QUESTION",
  },
  surveySectionTypeMap: {
    pageBreak: "PAGE_BREAK",
    sectionBreak: "SECTION_BREAK",
    textBlock: "TEXT_BLOCK",
    question: "QUESTION",
  },
  surveyTypes: {
    activity: {
      entities: ["project"],
      type: "ACTIVITY",
      name: "activity",
      title: "Activity Survey",
      description:
        "This is a scenario-based survey that is focused on allowing testers in a focus group to is either proactively or reactively complete a set of questions based upon an activity",
    },
    companyWide: {
      entities: ["company"],
      type: "COMPANY_WIDE",
      name: "companyWide",
      title: "Company Wide Survey",
      description:
        "This survey is only to qualify cohorts of users to participate in a focus group based upon a set of requirements for participation measured in this survey.",
    },
    qualification: {
      entities: ["project"],
      type: "QUALIFICATION",
      name: "qualification",
      title: "Qualification Survey",
      description:
        "This survey is only to qualify cohorts of users to participate in a focus group based upon a set of requirements for participation measured in this survey.",
    },
    weekly: {
      entities: ["project"],
      type: "WEEKLY",
      name: "weekly",
      title: "Weekly Survey",
      description:
        "This is a scoped and scheduled survey with the same questions week over week to measure whether or not performance or issues improve.",
    },
    impression: {
      entities: ["project"],
      type: "IMPRESSIONS",
      name: "impression",
      title: "Impression Survey",
      description:
        "This a survey format that is geared to measure initial and final sentiment on a product or service. NPS, CSAT and FIP questions are generally incorporated.",
    },
    testerProfile: {
      entities: ["company"],
      type: "TESTER_PROFILE",
      name: "testerProfile",
      title: "Tester Profile Survey",
      description: "",
    },
  },
};
