import { useEffect, useRef } from "react";

export default function useAfterFirstRender(cb: () => void, deps: any[]): void {
  const isFirstRender = useRef<boolean>(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      cb();
    } else {
      isFirstRender.current = false;
    }
  }, deps);
}
