export const extractContent = s => {
  let span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
};

/* eslint-disable */
export const uuidv4 = (s) => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};