import React from "react";

import moment, { unitOfTime } from "moment";
import styled from "styled-components";

const CountdownWrapper = styled.div`
  .explorer-countdown__warning {
    color: ${props => props.theme.palette.warning.contrastText};
  }
`;

export type CountdownProps = {
  limit: number;
  type: string;
  unit: unitOfTime.Diff;
  value: string;
};

export default ({
  type,
  unit,
  value,
  limit,
  ...props
}: CountdownProps): JSX.Element => {
  const [difference, setDifference] = React.useState<number>(0);

  React.useEffect(() =>
    setDifference(moment(value).diff(moment(new Date()), unit)),
  );

  return (
    <CountdownWrapper {...props}>
      {!value && (
        <span className="explorer-countdown__text">No End Date Specified</span>
      )}
      {type === "basic" &&
        value &&
        (difference < limit ? (
          <span className="explorer-countdown__warning">
            {difference < 0
              ? `Expired, ${Math.abs(difference)} ${unit} ago`
              : `${difference} ${unit} remaining`}
          </span>
        ) : (
          <span className="explorer-countdown__text">
            {moment(value).format("DD MMMM YYYY")}
          </span>
        ))}
    </CountdownWrapper>
  );
};
