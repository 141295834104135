import React, { useCallback } from "react";

import { Icon, IconProps } from "atoms";
import styled from "styled-components";

const WIDTH_OFFSET = 1200;
type WrapperProps = {
  active: boolean;
  completed?: boolean;
  isClickable: boolean;
};
export type StepItemProps = WrapperProps & {
  count?: number;
  first?: boolean;
  last?: boolean;
  label: string;
  labelSecondary?: string;
  handleClick?: (e: StepItemProps) => void;
  variant: string;
  textAnchor?: string;
  width?: number;
  widthOffset?: number;
  widthOffsetStroke?: number;
  borderWidth?: number;
  activeStepIconProps?: IconProps;
  completedStepIconProps?: IconProps;
};

const changeRegularStepItemBackgroundColor = (props: any) => {
  if (props.variant !== "circle") {
    if (props.active) {
      return props.theme.palette.StepItem.activeBackground;
    } else {
      return props.theme.palette.StepItem.disabledBackground;
    }
  } else {
    return "transparent";
  }
};

const changeNumberBoxBackgroundColor = (props: any) => {
  if (props.variant !== "circle") {
    if (props.active) {
      return props.theme.palette.StepItem.activeText;
    } else {
      return props.theme.palette.StepItem.disabledBox;
    }
  } else {
    return "#fff";
  }
};

const RegularStepItem = styled.div<StepItemProps>`
  display: flex;
  background-color: ${props => changeRegularStepItemBackgroundColor(props)};
  padding-top: 1.4em;
  padding-bottom: 1.4em;
  margin: ${({ variant }) => (variant === "circle" ? "0" : "0 0.2em")};
  align-items: ${({ variant }) =>
    variant === "circle" ? "center" : undefined};

  .StepItemNumberBox {
    background-color: ${props => changeNumberBoxBackgroundColor(props)};
    font-weight: 600;
    font-size: 15px;
    padding: ${({ variant }) => (variant === "circle" ? "5px 4px" : "10px")};
    width: 40px;
    height: 40px;
    text-align: center;
    border: ${({ variant }) =>
      variant === "circle" ? "4px solid" : undefined};
    border-color: ${({ active, completed, theme }) =>
      active || completed
        ? theme.palette.StepItem.activeSuccess
        : theme.palette.StepItem.disabledBasic};
    border-radius: ${({ variant }) => (variant == "circle" ? "50%" : "4px")};
    margin: ${({ variant }) =>
      variant === "circle" ? "auto 0 auto 0" : "auto 16px auto 24px"};
    cursor: ${({ isClickable }) => (isClickable ? "pointer" : "not-allowed")};

    span {
      color: ${({ active, theme }) =>
        active
          ? theme.palette.StepItem.activeBackground
          : theme.palette.StepItem.disabledText};
    }
  }

  .StepItemCircleBox {
    background-color: ${props => changeNumberBoxBackgroundColor(props)};
    font-weight: 600;
    font-size: 15px;
    padding: ${({ variant }) => (variant === "circle" ? "5px 4px" : "10px")};
    width: ${({ width }) => (width ? `${width}px` : "40px")};
    height: ${({ width }) => (width ? `${width}px` : "40px")};
    text-align: center;
    border: ${({ variant, borderWidth }) =>
      variant === "circle"
        ? `${borderWidth ? borderWidth : 2}px solid`
        : undefined};
    border-color: ${({ active, completed, theme }) =>
      active || completed
        ? theme.palette.StepItem.activeSuccess
        : theme.palette.StepItem.disabledBasic};
    border-radius: ${({ variant }) => (variant == "circle" ? "50%" : "4px")};
    margin: ${({ variant }) =>
      variant === "circle" ? "auto 0 auto 0" : "auto 16px auto 24px"};
    cursor: ${({ isClickable }) => (isClickable ? "pointer" : "not-allowed")};

    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: ${({ active, theme }) =>
        active
          ? theme.palette.StepItem.activeBackground
          : theme.palette.StepItem.disabledText};
    }
  }

  .StepItemTextWrapper {
    cursor: ${({ isClickable }) => (isClickable ? "pointer" : "not-allowed")};
    p {
      &:first-child {
        color: ${({ active, theme }) =>
          active
            ? theme.palette.StepItem.activeText
            : theme.palette.StepItem.disabledText};
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 0;
      }
      &:nth-child(2) {
        color: ${({ theme }) => theme.palette.StepItem.disabledText};
        font-size: 12px;
        font-weight: 400;
        margin-top: 0;
      }
    }
  }
`;

const CircleStepItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StepItemConnector = styled.div<{
  active?: boolean;
  completed: boolean;
  first: boolean;
  widthOffset?: number;
  widthOffsetStroke?: number;
}>`
  border-left: ${({ first, widthOffset }) =>
    first ? undefined : `${widthOffset || 84}px solid`};
  border-left-color: ${({ active, completed, theme }) =>
    active || completed
      ? theme.palette.StepItem.activeSuccess
      : theme.palette.StepItem.disabledBasic};
  height: ${({ widthOffsetStroke }) => `${widthOffsetStroke || 2}px`};
`;

const PolylineWrapper = styled.path<WrapperProps>`
  stroke-width: 1.5px;
  fill: ${({ active, theme }) =>
    active ? theme.palette.infoLight.main : "#fff"};
  stroke: ${({ active, theme }) =>
    active ? theme.palette.infoLight.contrastText : "#ccc"};
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "not-allowed")};
  vector-effect: non-scaling-stroke;
`;
const TextWrapper = styled.text<WrapperProps>`
  fill: ${({ active, theme }) =>
    active
      ? theme.palette.infoLight.contrastText
      : theme.palette.text.secondary};
  font-size: 15px !important;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "not-allowed")};
`;
const calculatePoints = (count, width, first, widthOffset) => {
  if (width === 0 || width > (widthOffset || WIDTH_OFFSET)) {
    return first
      ? `M4,0 L196,0 Q 202 0 204 4 L218,31 Q 220 35 218 39
        L202,66 Q 200 70 196,70
        L6,70 Q 0 70 0 64
        L0,6 Q 0 0 6 0`
      : `M6,0 L196,0 Q 202 0 204 4 L218,31 Q 220 35 218 39
        L202,66 Q 200 70 196,70
        L6,70 Q 0 70 4 64 L4,64
        L16,40 Q 18 35 16 32
        L2,4 Q 0 0 6 0`;
  } else {
    const itemWidth = width / count - 20;
    const height = itemWidth / 3;
    const nip = itemWidth / 10;
    const semiHeight = height / 2;
    return first
      ? `M4,0 L${itemWidth - 4},0 Q${itemWidth} 0 ${itemWidth + 2} 4
      L${itemWidth + nip - 2},${semiHeight - 4} Q ${
          itemWidth + nip
        } ${semiHeight} ${itemWidth + nip - 2},${semiHeight + 4}
      L${itemWidth + 4},${height - 4} Q${itemWidth} ${height} ${
          itemWidth - 2
        } ${height}
      L4,${height} Q0 ${height} 0 ${height - 6}
      L0,4 Q 0 0 4 0`
      : `M4,0 L${itemWidth - 4},0 Q${itemWidth} 0 ${itemWidth + 2} 4
        L${itemWidth + nip - 2},${semiHeight - 4} Q ${
          itemWidth + nip
        } ${semiHeight} ${itemWidth + nip - 2},${semiHeight + 4}
        L${itemWidth + 4},${height - 4} Q${itemWidth} ${height} ${
          itemWidth - 2
        } ${height}
        L4,${height} Q0 ${height} 4 ${height - 6}
        L${nip - 4},${semiHeight + 6} Q ${nip} ${semiHeight} ${nip - 4} ${
          semiHeight - 6
        }
        L2,4 Q 0 0 4 0`;
  }
};
const calculateDimensions = (count, width, widthOffset) => {
  if (width === 0 || width > (widthOffset || WIDTH_OFFSET)) {
    return [225, 70];
  } else {
    const itemWidth = width / count;
    return [itemWidth, itemWidth / 3];
  }
};

const checkStepItemIcon = (count: number) => {
  if (count) return count.toString();
};

export default (props: StepItemProps): JSX.Element => {
  const {
    first,
    last,
    label,
    labelSecondary,
    active,
    completed,
    count,
    width,
    widthOffset,
    isClickable,
    handleClick,
    variant,
    borderWidth,
    widthOffsetStroke,
    activeStepIconProps,
    completedStepIconProps,
  } = props;
  const points = calculatePoints(count, width, first, widthOffset);
  const [w, h] = calculateDimensions(count, width, widthOffset);
  const clickHandler = useCallback(() => {
    if (handleClick && isClickable) {
      handleClick(props);
    }
  }, [handleClick, isClickable]);
  switch (variant) {
    case "arrow":
      return (
        <svg
          style={{ overflow: "visible" }}
          width={`${w}`}
          height={`${h}`}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          onClick={clickHandler}
        >
          <PolylineWrapper
            active={active}
            d={points}
            isClickable={isClickable}
          />
          <TextWrapper
            x={w / 2.3 + 3}
            y={w / 5.4}
            active={active}
            textAnchor="middle"
            isClickable={isClickable}
          >
            {label}
          </TextWrapper>
        </svg>
      );
    case "circle":
      return (
        <CircleStepItemWrapper>
          <RegularStepItem
            label={label}
            onClick={clickHandler}
            isClickable={isClickable}
            active={active}
            completed={completed}
            borderWidth={borderWidth}
            variant={"circle"}
            width={width}
          >
            <StepItemConnector
              first={first || false}
              completed={completed || false}
              active={active || false}
              widthOffset={widthOffset}
              widthOffsetStroke={widthOffsetStroke}
            />
            <div className="StepItemCircleBox">
              <>
                {activeStepIconProps && active && (
                  <Icon
                    group={activeStepIconProps.group}
                    fill={activeStepIconProps.fill}
                    name={activeStepIconProps.name}
                    width={activeStepIconProps.width}
                    height={activeStepIconProps.height}
                    viewBox={activeStepIconProps.viewBox}
                  />
                )}
              </>
              <>
                {completedStepIconProps && completed && !active && (
                  <Icon
                    width={completedStepIconProps.width}
                    height={completedStepIconProps.height}
                    viewBox={completedStepIconProps.viewBox}
                    group={completedStepIconProps.group}
                    fill={completedStepIconProps.fill}
                    name={completedStepIconProps.name}
                  />
                )}
              </>
            </div>
          </RegularStepItem>
        </CircleStepItemWrapper>
      );
    default:
      return (
        <RegularStepItem
          label={label}
          onClick={clickHandler}
          isClickable={isClickable}
          active={active}
          variant={"regular"}
        >
          <div className="StepItemNumberBox">
            <span>{count && checkStepItemIcon(count)}</span>
          </div>
          <div className="StepItemTextWrapper">
            <p>{label}</p>
            <p>{labelSecondary}</p>
          </div>
        </RegularStepItem>
      );
  }
};
