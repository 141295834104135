import React from "react";

import { useDropzone } from "react-dropzone";
import { MdCancel } from "react-icons/md";
import styled from "styled-components";

import { IExtendedTheme } from "../../themes/default";

type BaseImageProps = {
  theme: IExtendedTheme;
};

type ImageWrapperProps = BaseImageProps & {
  hasImage?: boolean;
  height?: string;
  width?: string;
};

const ImageWrapper = styled.div<ImageWrapperProps>`
  width: ${props =>
    props.width || props.theme.sizes.imagePickerDefaultSize.width};
  height: ${props => {
    if (props.height) {
      return props.height;
    }

    return props.hasImage
      ? "100%"
      : props.theme.sizes.imagePickerDefaultSize.height;
  }};
  border-radius: ${(props: BaseImageProps) =>
    props.theme.sizes.imagePickerDefaultSize.borderRadius};
  background: ${(props: BaseImageProps) =>
    props.theme.palette.imagePickerBackgroundColor};

  img {
    width: 100%;
    height: 100%;
  }
`;

const ImagePickerWrapper = styled.div<BaseImageProps>`
  position: relative;
  display: inline-block;

  .close-icon {
    position: absolute;
    top: -10px;
    cursor: pointer;
    right: -10px;
    font-size: ${(props: BaseImageProps) =>
      props.theme.sizes.imagePickerDefaultSize.closeIconSize};
    color: ${(props: BaseImageProps) =>
      props.theme.palette.imagePickerCloseIconColor};
  }
`;

type ImageProps = { alt?: string; src: string | null };
const Image = ({ src, alt }: ImageProps) => (
  <img src={src as string} alt={alt} />
);

export type ImagePickerProps = ImageWrapperProps & {
  defaultValue?: any;
  name: string;
  setFormRegister: Function;
  setFormValue: Function;
};

export default ({
  defaultValue,
  height,
  width,
  setFormRegister,
  setFormValue,
  name,
  theme,
}: ImagePickerProps): JSX.Element => {
  const image =
    defaultValue && [defaultValue.url, ...defaultValue.variations].join("/");
  const [url, setUrl] = React.useState<string | null>(image);

  const onFileDrop = (files: any[]): void => {
    setFormValue(name, files[0]);
    setUrl(URL.createObjectURL(files[0]));
  };

  React.useEffect(() => {
    setFormRegister({ name, value: defaultValue }, {});
  }, [name, setFormRegister, setFormValue, defaultValue]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/png", "image/jpeg", "image/svg+xml"],
    multiple: false,
    onDrop: React.useCallback(onFileDrop, []),
  });

  const removeImage = React.useCallback(() => {
    setUrl(null);
    setFormValue(name, null);
  }, []);

  return (
    <ImagePickerWrapper theme={theme}>
      {url && (
        <div
          role="button"
          className="close-icon"
          tabIndex={0}
          onKeyUp={removeImage}
          onClick={removeImage}
        >
          <MdCancel />
        </div>
      )}
      <div {...getRootProps()}>
        <ImageWrapper height={height} width={width} hasImage={!!url}>
          {url && <Image src={url} />}
        </ImageWrapper>

        <input name={name} {...getInputProps()} />
      </div>
    </ImagePickerWrapper>
  );
};
