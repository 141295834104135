import React from "react";

import { Button, RawColorPicker, RawColorPickerProps, Toggle } from "atoms";

import styled, { css } from "styled-components";

export enum positions {
  top = "top",
  bottom = "bottom",
  left = "left",
  right = "right",
}

const PickerWrapper = styled.div<{ position: positions }>`
  position: absolute;
  display: inline-block;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.27);
  z-index: 1000;
  width: 220px;

  filter: drop-shadow(0px -2px 2px rgba(0, 0, 0, 0.27));

  input {
    border-radius: 4px;
  }

  ${({ position }) => {
    switch (position) {
      case positions.top:
        return css`
          bottom: calc(100% + 10px);
          left: 50%;
          transform: translateX(-50%);
        `;

      case positions.left:
        return css`
          top: 50%;
          right: calc(100% + 10px);
          transform: translateY(-50%);
        `;

      case positions.right:
        return css`
          top: 50%;
          left: calc(100% + 10px);
          transform: translateY(-50%);
        `;

      default:
        return css`
          top: calc(100% + 10px);
          left: 50%;
          transform: translateX(-50%);
        `;
    }
  }}
  &:after {
    position: absolute;
    content: "";
    width: 25px;
    height: 10px;
    background-color: white;

    ${({ position }) => {
      switch (position) {
        case positions.top:
          return css`
            bottom: 0;
            left: 50%;
            clip-path: polygon(50% 100%, 0 0, 100% 0);
            transform: translateY(100%) translateX(-50%);
          `;

        case positions.left:
          return css`
            width: 10px;
            height: 25px;
            top: 50%;
            left: 100%;
            clip-path: polygon(100% 50%, 0 0, 0 100%);
            transform: translateY(-50%) translateX(0);
          `;

        case positions.right:
          return css`
            width: 10px;
            height: 25px;
            top: 50%;
            right: 100%;
            clip-path: polygon(0 50%, 100% 0, 100% 100%);
            transform: translateY(-50%) translateX(0);
          `;

        default:
          return css`
            top: 0;
            left: 50%;
            clip-path: polygon(50% 0, 0 100%, 100% 100%);
            transform: translateY(-100%) translateX(-50%);
          `;
      }
    }}
  }
`;

const Swatch = styled.div<{ selectedColor?: string }>`
  width: 25px;
  height: 25px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: ${({ selectedColor }) => selectedColor};
`;

const ButtonWrapper = styled(Button)`
  width: 140px;
  justify-content: flex-start;
`;

export interface ColorPickerProps extends RawColorPickerProps {
  children?: React.ReactElement;
  position?: positions;
}

export default ({
  children,
  color,
  defaultColor = "azure",
  position = positions.bottom,
  ...props
}: ColorPickerProps) => {
  const colorVar = color ?? defaultColor;

  return (
    <Toggle>
      <div
        style={{
          position: "relative",
          display: "inline-block",
        }}
      >
        <Toggle.Button>
          <ButtonWrapper
            // variant="outlined"
            buttonTheme="outline"
            palette="primary"
            removeSideMargin
            size="large"
            startIcon={<Swatch selectedColor={color ?? defaultColor} />}
          >
            {colorVar.includes("#")
              ? colorVar.substring(1).toUpperCase()
              : colorVar}
          </ButtonWrapper>
        </Toggle.Button>

        <Toggle.Content>
          <PickerWrapper position={position}>
            {children}
            <RawColorPicker {...props} color={color ?? defaultColor} />
          </PickerWrapper>
        </Toggle.Content>
      </div>
    </Toggle>
  );
};
