import React from "react";

import MaterialTab, { TabProps } from "@material-ui/core/Tab";
import styled, { css } from "styled-components";

export type ITabProps = TabProps & { palette: number | string };

export default styled(
  ({ label, ...rest }: ITabProps): JSX.Element => (
    <MaterialTab
      classes={{ wrapper: "xp-wrapper", selected: "xp-selected" }}
      label={label}
      {...rest}
    />
  ),
)`
  ${props => {
    let customStyle = "";
    const { palette } = props;
    const themePalette = props.theme.palette;
    if (palette) {
      customStyle = customStyle.concat(`
    text-transform: none;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    min-width: 80px;
    min-height: 30px;
    font-weight: bold;
    padding: 1.2em 3.5em;

    border: 2px solid ${themePalette[palette].tabs.border};

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-right: none;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-left: none;
    }

    &:hover {
      color: ${themePalette[palette].tabs.contrastText}
    }
    &.xp-selected {
        color: ${themePalette[palette].tabs.contrastText};
        background-color: ${themePalette[palette].tabs.main};
    }
    &.xp-wrapper {

    }`);
    }
    return css`
      ${customStyle}
    `;
  }}
`;
