import React, { Fragment } from "react";

import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

import { Icon } from "atoms";
import styled from "styled-components";

import { IExtendedTheme } from "themes/default";

const Link = () => <Icon name="link" />;

const useStyles = makeStyles((theme: IExtendedTheme) => {
  return {
    textfieldRoot: {
      "& .MuiInput-underline:after": {
        borderBottomColor: theme.palette.colors.primary[500],
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: theme.palette.colors.primary[500],
        },
      },
      "& label.Mui-focused": {
        color: theme.palette.colors.primary[500],
      },
    },
  };
});

const CustomLinkWrapper = styled.div`
  position: relative;

  .ql-custom-link {
    &__dropdown {
      transition: opacity 0.5s ease-in-out;
      min-width: 510px;

      background: white;

      position: absolute;
      top: 100%;
      left: 0;
      z-index: 20;
      padding: 16px 16px 12px 16px;

      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);

      border-radius: 4px;

      &.show {
        visibility: visible;
        opacity: 1;
      }

      &.hide {
        visibility: hidden;
        opacity: 0;
      }
    }

    &__input-block {
      width: 100%;
      height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:not(:first-child) {
        margin-top: 7px;
      }
    }

    &__input {
      height: 48px;
      font-family: AmazonEmber;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;

      width: calc(100% - 134px);

      margin: 0;

      .MuiOutlinedInput-input {
        height: 100%;
      }
    }

    &__btn {
      width: 118px !important;
      height: 48px !important;
      border-radius: 4px;
      background: ${({ theme }) =>
        theme.palette.colors.primary[100]} !important;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: ${({ theme }) => theme.palette.colors.primary[500]} !important;
      outline: none;

      &:active {
        background: ${({ theme }) =>
          theme.palette.colors.primary[300]} !important;
      }
      margin: 0 0 0 16px;
    }

    &__link {
      font-family: AmazonEmber;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #8f9bb3;

      margin-top: 4px;
      padding-left: 17px;

      display: block;
    }
  }
`;

export type CustomLinkProps = {
  link: string;
  onInsertLink: (link: string) => void;
  text: string;
};

const CustomLink = props => {
  const { link, text } = props;

  const [isShowDropdown, toggleDropdown] = React.useState<boolean>(false);
  const [customText, updateText] = React.useState<string>(text ? text : "");
  const [userLink, updateUserLink] = React.useState<string>("");

  let contentBlock: HTMLElement | null = null;

  const handleClickOutside = event => {
    if (
      isShowDropdown &&
      contentBlock &&
      !contentBlock.contains(event.target)
    ) {
      toggleDropdown(false);
    }
  };
  window.addEventListener("click", handleClickOutside);

  const styles = useStyles();

  return (
    <CustomLinkWrapper
      className={"ql-formats"}
      ref={item => {
        contentBlock = item;
      }}
    >
      <Tooltip title="Link">
        <button
          className={"ql-custom-link"}
          data-tooltip="Link"
          onClick={() => toggleDropdown(!isShowDropdown)}
        >
          <Link />
        </button>
      </Tooltip>
      <div
        className={`ql-custom-link__dropdown ${
          isShowDropdown ? "show" : "hide"
        }`}
      >
        {link && (
          <Fragment>
            <div className={"ql-custom-link__input-block"}>
              <TextField
                classes={{ root: styles.textfieldRoot }}
                label="Button Title"
                value={customText}
                onChange={e => updateText(e.target.value)}
                className={"ql-custom-link__input"}
                margin="normal"
                variant="outlined"
              />
              <button
                className={"ql-custom-link__btn"}
                onClick={() => props.onInsertLink(customText)}
              >
                Insert Link
              </button>
            </div>
            <a
              href={link}
              target={"_blank"}
              rel="noopener noreferrer"
              className={"ql-custom-link__link"}
            >
              {link}
            </a>
          </Fragment>
        )}
        {!link && (
          <Fragment>
            <div className={"ql-custom-link__input-block"}>
              <TextField
                classes={{ root: styles.textfieldRoot }}
                label="Button Title"
                value={customText}
                onChange={e => updateText(e.target.value)}
                className={"ql-custom-link__input"}
                margin="normal"
                variant="outlined"
              />
            </div>
            <div className={"ql-custom-link__input-block"}>
              <TextField
                classes={{ root: styles.textfieldRoot }}
                label="Button Link"
                value={userLink}
                onChange={e => updateUserLink(e.target.value)}
                className={"ql-custom-link__input"}
                margin="normal"
                variant="outlined"
              />
              <button
                className={"ql-custom-link__btn"}
                onClick={() =>
                  props.onInsertLink({
                    text: customText,
                    url: userLink,
                  })
                }
              >
                Insert Link
              </button>
            </div>
            <a
              href={link}
              target={"_blank"}
              rel="noopener noreferrer"
              className={"ql-custom-link__link"}
            >
              {link}
            </a>
          </Fragment>
        )}
      </div>
    </CustomLinkWrapper>
  );
};

CustomLink.defaultProps = {
  link: "",
  onInsertLink: () => ({}),
  text: "",
};

export default CustomLink;
