import React from "react";

import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Materi from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Input } from "atoms";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import styled from "styled-components";

import AddItem from "../../AddItem";
import EditRowCol from "../../EditRow";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditColWrapper = styled.div`
  margin-left: auto;
`;

const EditableInput = ({ type, collectionIndex, value, onLabelUpdate }) => (
  <Input
    value={value}
    onChange={ev =>
      onLabelUpdate && onLabelUpdate(type, collectionIndex, ev.target.value)
    }
    required={true}
  />
);

const GroupItem = ({
  mode,
  disabled = false,
  collectionIndex,
  option,
  onOptionLabelUpdate,
  onRemoveOption,
}) => {
  const getLabel = ({
    collectionIndex: cIndex,
    option: opt,
    onOptionLabelUpdate: labelUpdate,
  }) => {
    if (mode !== "edit") {
      return opt.label;
    }
    return (
      <EditableInput
        type="radio"
        onLabelUpdate={labelUpdate}
        collectionIndex={cIndex}
        value={opt.label}
      />
    );
  };
  return (
    <Box display="flex">
      <FormControlLabel
        disabled={disabled}
        key={option.name}
        control={<Materi value={option.value} size="small" color="primary" />}
        label={getLabel({ collectionIndex, option, onOptionLabelUpdate })}
      />
      {mode === "edit" ? (
        <EditColWrapper>
          <EditRowCol
            index={collectionIndex}
            onRemove={rowIndex => {
              // console.log(rowIndex);
              onRemoveOption(rowIndex);
            }}
          />
        </EditColWrapper>
      ) : null}
    </Box>
  );
};

const SortableGroupItem = SortableElement(GroupItem);

const GroupComp = ({
  value,
  name,
  onChange,
  options,
  onOptionLabelUpdate,
  onRemoveOption,
  mode,
  disabled,
}) => (
  <RadioGroup name={name} value={value} onChange={onChange}>
    {options.map((option, index) => {
      const Item = mode === "edit" ? SortableGroupItem : GroupItem;
      return (
        <Item
          key={index}
          mode={mode}
          index={index}
          collectionIndex={index}
          option={option}
          onOptionLabelUpdate={onOptionLabelUpdate}
          onRemoveOption={onRemoveOption}
          disabled={disabled}
        />
      );
    })}
  </RadioGroup>
);

const SortableGroupComp = SortableContainer(GroupComp);

type SurveyElementRadioGroupProps = {
  disabled?: boolean;
  mode?: any;
  name: string;
  onAddOption?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onChange: Function;
  onOptionLabelUpdate?: Function;
  onRemoveOption?: Function;
  onReorderOption?: Function;
  options: any[];
  value: any;
};
const SurveyElementRadioGroup = ({
  value,
  name,
  onChange,
  options,
  onOptionLabelUpdate,
  onRemoveOption,
  onReorderOption,
  onAddOption,
  mode,
  disabled = false,
}: SurveyElementRadioGroupProps): JSX.Element => {
  const Container = mode === "edit" ? SortableGroupComp : GroupComp;

  return (
    <Wrapper>
      <Container
        value={value}
        name={name}
        onChange={onChange}
        options={options}
        onOptionLabelUpdate={onOptionLabelUpdate}
        onRemoveOption={onRemoveOption}
        mode={mode}
        disabled={disabled}
        useDragHandle={true}
        onSortEnd={({ oldIndex, newIndex }) => {
          if (onReorderOption) {
            onReorderOption({ oldIndex, newIndex });
          }
        }}
      />
      {mode === "edit" ? (
        <Box mt={2}>
          <AddItem type="option" label="Add Option" handleAdd={onAddOption} />
        </Box>
      ) : null}
    </Wrapper>
  );
};

export default SurveyElementRadioGroup;
