import React from "react";

import Box from "@material-ui/core/Box";
import MaterialCheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Input } from "atoms";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import styled from "styled-components";

import AddItem from "../../AddItem";
import EditRowCol from "../../EditRow";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditColWrapper = styled.div`
  margin-left: auto;
`;

const EditableInput = ({ type, collectionIndex, value, onLabelUpdate }) => (
  <Input
    value={value}
    onChange={ev =>
      onLabelUpdate && onLabelUpdate(type, collectionIndex, ev.target.value)
    }
    required={true}
  />
);

const GroupItem = ({
  mode,
  disabled = false,
  collectionIndex,
  option,
  onOptionLabelUpdate,
  onChange,
  onRemoveOption,
}) => {
  const getLabel = ({ collectionIndex, option, onOptionLabelUpdate }) => {
    if (mode !== "edit") {
      return option.label;
    }
    return (
      <EditableInput
        type="checkbox"
        onLabelUpdate={onOptionLabelUpdate}
        collectionIndex={collectionIndex}
        value={option.label}
      />
    );
  };

  const renderMaterialCheckbox = () => (
    <MaterialCheckbox
      disabled={disabled}
      key={option.name}
      checked={option.checked}
      onChange={event => onChange(event.target.checked, collectionIndex)}
      name={option.name}
      color="primary"
      size="small"
    />
  );

  return (
    <Box display="flex">
      <FormControlLabel
        control={renderMaterialCheckbox()}
        label={getLabel({ collectionIndex, onOptionLabelUpdate, option })}
      />
      {mode === "edit" ? (
        <EditColWrapper>
          <EditRowCol
            index={collectionIndex}
            onRemove={rowIndex => {
              // console.log(rowIndex);
              onRemoveOption(rowIndex);
            }}
          />
        </EditColWrapper>
      ) : null}
    </Box>
  );
};

const SortableGroupItem = SortableElement(GroupItem);

const GroupComp = ({
  options,
  onOptionLabelUpdate,
  onRemoveOption,
  onChange,
  mode,
  disabled,
}) => (
  <div>
    {options.map((option, index) => {
      const Item = mode === "edit" ? SortableGroupItem : GroupItem;
      return (
        <Item
          key={index}
          mode={mode}
          index={index}
          collectionIndex={index}
          option={option}
          onOptionLabelUpdate={onOptionLabelUpdate}
          onRemoveOption={onRemoveOption}
          onChange={onChange}
          disabled={disabled}
        />
      );
    })}
  </div>
);

const SortableGroupComp = SortableContainer(GroupComp);

type CheckboxProps = {
  disabled?: boolean;
  mode?: string;
  onAddOption?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onChange: Function;
  onOptionLabelUpdate?: Function;
  onRemoveOption?: Function;
  onReorderOption?: Function;
  options: any[];
};
const Checkbox = ({
  options,
  onChange,
  mode,
  onOptionLabelUpdate,
  onRemoveOption,
  onReorderOption,
  onAddOption,
  disabled,
}: CheckboxProps): JSX.Element => {
  const Container = mode === "edit" ? SortableGroupComp : GroupComp;
  return (
    <Wrapper>
      <Container
        onChange={onChange}
        options={options}
        onOptionLabelUpdate={onOptionLabelUpdate}
        onRemoveOption={onRemoveOption}
        mode={mode}
        disabled={disabled}
        useDragHandle={true}
        onSortEnd={({ oldIndex, newIndex }) => {
          if (onReorderOption) {
            onReorderOption({ oldIndex, newIndex });
          }
        }}
      />
      {mode === "edit" ? (
        <Box mt={2}>
          <AddItem type="option" label="Add Option" handleAdd={onAddOption} />
        </Box>
      ) : null}
    </Wrapper>
  );
};

export default Checkbox;
