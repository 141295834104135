import React from "react";

import { IconProps, StepItem, StepItemProps } from "atoms";
import styled from "styled-components";

const StepperWrapper = styled.div<{ variant: "regular" | "arrow" | "circle" }>`
  ${({ variant }) => {
    switch (variant) {
      case "regular":
        return "display: grid; grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))";

      case "circle":
        return "display: flex; flex-direction: row";
    }
  }}
`;

export type StepperStepProps = {
  id: number;
  label: string;
  labelSecondary?: string;
  textAnchor?: string | undefined;
  width?: number | undefined;
  widthOffset?: number | undefined;
  widthOffsetStroke?: number;
  completed?: boolean;
  borderWidth?: number;
  activeStepIconProps?: IconProps;
  completedStepIconProps?: IconProps;
};

export type StepperProps = {
  activeStepKey: number;
  completed: boolean;
  clickable?: boolean;
  steps: StepperStepProps[];
  variant: "regular" | "arrow" | "circle";
  handleClick?(step: StepItemProps): void;
};

export default ({
  steps,
  activeStepKey,
  variant,
  handleClick,
  clickable = true,
}: StepperProps): JSX.Element => {
  return (
    <StepperWrapper variant={variant}>
      {steps.map((step, index) => {
        return (
          <StepItem
            {...step}
            variant={variant}
            isClickable={clickable}
            active={activeStepKey === step.id}
            key={step.id}
            first={index === 0}
            count={index + 1}
            handleClick={handleClick}
            last={steps[steps.length - 1].id === step.id ? true : false}
          />
        );
      })}
    </StepperWrapper>
  );
};
