import React from "react";

import { WidgetCard } from "atoms";
import _ from "lodash";
import styled from "styled-components";

const clsPrefix = "widget-list";

const WidgetListItemWrapper = styled.div`
  & {
    height: 56px;
    border-bottom: 1px solid #edf1f7;
    display: flex;
    align-items: center;
    padding: 0 24px;
  }
`;

const WidgetListItemColWrapper = styled.div`
  flex: 1;
  font-size: 15px;
  color: ${props => props.theme.palette.text.secondary};

  &.right {
    text-align: right;
  }
`;

const WidgetListItem = ({
  columns,
  item,
}: {
  columns: IColumn[];
  item: IData;
}): JSX.Element => (
  <WidgetListItemWrapper>
    {_.map(columns, ({ name: key, alignment }, index) => (
      <WidgetListItemColWrapper
        key={String(index)}
        className={`${clsPrefix}-item-col ${alignment}`}
      >
        {item[key]}
      </WidgetListItemColWrapper>
    ))}
  </WidgetListItemWrapper>
);

const WidgetListNoRecordWrapper = styled.div`
  & {
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;

    .no-records {
      font-size: 15px;
      color: ${props => props.theme.palette.text.secondary};
    }
  }
`;

interface IData {
  count: number;
  name: string;
}

interface IColumn {
  alignment: "left" | "right";
  key: string;
  name: string;
}

export type WidgetListProps = {
  actions: React.ReactNode;
  columns: IColumn[];
  data: IData[];
  noData: React.ReactNode | string;
  title: string;
};

export default ({
  data,
  title,
  actions,
  columns,
  ...props
}: Readonly<WidgetListProps>): JSX.Element => (
  <WidgetCard title={title} actions={actions}>
    {_.map(data, (item, index) => (
      <WidgetListItem key={String(index)} item={item} columns={columns} />
    ))}
    <WidgetListNoRecordWrapper>
      {!data.length && <div className="no-records">{props.noData}</div>}
    </WidgetListNoRecordWrapper>
  </WidgetCard>
);
