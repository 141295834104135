import React, { HTMLAttributes } from "react";

import {
  Drawer,
  DrawerProps,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import CollapseIcon from "assets/icons/collapse-sidemenu.svg";
import CollapseRightIcon from "assets/icons/menu-collapse-right.svg";

import logoWhite from "assets/images/logo-white.png";
import _ from "lodash";
import styled from "styled-components";

import icons from "./icons";

import { IExtendedTheme } from "../../themes/default";

const DrawerWrapper = styled(Drawer)<DrawerProps & { drawer: any }>`
  flex-shrink: 0;
  white-space: nowrap;

  .MuiPaper-root.MuiDrawer-paper {
    background: ${props => props.theme.palette.secondary.main};
    width: ${props => (props.open ? props.drawer.open : props.drawer.closed)}px;
    overflow: hidden;
    transition: width 0.2s;
  }
`;

const DrawerTopWrapper = styled.div`
  display: flex;
  height: 64px;
  align-items: center;
  background-color: #192435;
  margin-bottom: 22px;
  padding: 0 12px;

  .MuiButtonBase-root {
    margin-left: auto;
    .MuiIconButton-label {
      color: ${props => props.theme.palette.tertiary.contrastText};
      .MuiSvgIcon-root {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }
`;

const DrawerList = styled(List)`
  color: ${props => props.theme.palette.tertiary.contrastText};
`;

const DrawerListItemWrapper = styled.div<
  HTMLAttributes<HTMLDivElement> & { active: boolean }
>`
  & .MuiListItem-root {
    ${props =>
      props.active && `background: ${props.theme.palette.drawer.foreground};`};
    display: flex;
    align-items: center;
    cursor: pointer;

    .MuiListItemIcon-root {
      margin-left: 4px;
    }

    .MuiListItemText-root {
      margin-top: 0;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      .MuiTypography-root {
        font-size: 13px;
        line-height: 2.5;
        ${props => props.active && "color: #fff;"}
      }
    }
  }
`;

const DrawerListItem = styled(
  ({ name, icon, onClickMenuItem, options, index, ...props }) => (
    <DrawerListItemWrapper {...props}>
      <ListItem classes={{ root: "drawer-list-item" }}>
        <ListItemIcon>{props.active ? icon.active : icon.default}</ListItemIcon>
        <ListItemText primary={<span>{props.label}</span>} />
      </ListItem>
      {options && (
        <DrawerList theme={props.theme} key={String(index)}>
          {options.map(item => (
            <DrawerListItem
              key={item.name}
              onClick={() => onClickMenuItem(item)}
              {...item}
            />
          ))}
        </DrawerList>
      )}
    </DrawerListItemWrapper>
  ),
)``;

const DrawerLogoWrapper = styled.div`
  padding: 20px 0;
  img {
    height: 20px;
    margin-top: 10px;
  }
`;

const DrawerLogo = props => (
  <DrawerLogoWrapper {...props}>
    <img alt="Amazon Northstar" src={logoWhite} />
  </DrawerLogoWrapper>
);

type NDrawerProps = {
  drawer: any;
  groups: any[];
  onClickMenuItem: Function;
  onSelectMenu: Function;
  open: boolean;
  setOpen: Function;
  theme: IExtendedTheme;
};

export default ({
  theme,
  drawer,
  open,
  groups,
  onClickMenuItem,
  onSelectMenu,
  ...props
}: NDrawerProps): JSX.Element => (
  <DrawerWrapper open={open} drawer={drawer} variant="permanent" theme={theme}>
    <DrawerTopWrapper>
      {open && <DrawerLogo />}
      <IconButton onClick={() => props.setOpen(!open)}>
        {!open ? <CollapseIcon /> : <CollapseRightIcon />}
      </IconButton>
    </DrawerTopWrapper>
    {_.map(groups, (list, index) => (
      <DrawerList theme={theme} key={String(index)}>
        {list.options.map(item => (
          <DrawerListItem
            icon={icons[item.name]}
            onClickMenuItem={onClickMenuItem}
            onClick={() => onSelectMenu(item)}
            key={item.name}
            {...item}
          />
        ))}
      </DrawerList>
    ))}
  </DrawerWrapper>
);
