import React from "react";

import { Theme } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import constants from "config/constants";
import _ from "lodash";
import sample from "lodash/sample";
import styled, { css } from "styled-components";
import { getImageUrl, isEmail } from "utils";

const palettes = ["success", "warning", "infoLight", "tertiary"];

type commonStyleProps = { width: number };

type AvatarWrapperProps = {
  avatarstyle?: any;
  height: number;
  palette: number | string;
};

type ImageBlockProps = {
  src?: string;
};

const AvatarWrapper = styled(Avatar)<AvatarWrapperProps & commonStyleProps>`
  border-radius: 4px;
  font-size: ${props => props.width / 2.25}px;
  display: flex;

  width: ${({ width }) => (_.isNumber(width) ? `${width}px` : width || "40px")};
  height: ${({ height }) =>
    _.isNumber(height) ? `${height}px` : height || "40px"};

  background-color: ${({ theme, palette }) => theme.palette[palette].main};
  color: ${({ theme, palette }) => theme.palette[palette].contrastText};
  ${props => {
    const avatarStyle = props.avatarstyle || "";
    return css`
      ${avatarStyle}
    `;
  }};
`;

const ImageBlock = styled.i<ImageBlockProps>`
  display: inline-block;
  width: 100%;
  height: 100%;

  background: url(${props => (props.src ? props.src : "")}) no-repeat center;
  background-size: cover;
`;

export type AvatarProps = {
  avatarStyle?: any;
  className?: string;
  height?: number;
  image?: object | string;
  imageAlt?: string;
  palette?: any;
  theme?: Theme;
  title: string;
  type?: string;
  variant?: "circle" | "square" | "rounded";
  width?: number;
};

export default ({
  width = 40,
  height = 40,
  image,
  imageAlt,
  theme,
  palette,
  title,
  type,
  avatarStyle,
  variant,
  ...rest
}: AvatarProps): JSX.Element => {
  let avatarText = "";
  if (!image) {
    avatarText = (title[0] || "").toUpperCase();
    if (type === "user") {
      palette = palette || constants.avatarPalette.light.green;
      if (isEmail(title)) {
        avatarText = title.substring(0, 2).toUpperCase();
      } else {
        const nameParts = title.split(" ");
        if (nameParts.length > 1) {
          avatarText = `${(nameParts[0][0] || "").toUpperCase()}${(
            nameParts[nameParts.length - 1][0] || ""
          ).toUpperCase()}`;
        } else {
          avatarText = `${(nameParts[0][0] || "").toUpperCase()}`;
        }
      }
    }
  } else if (typeof image === "object") {
    image = getImageUrl(image) as string;
  }

  return (
    <AvatarWrapper
      theme={theme}
      width={width}
      height={height}
      palette={palette || sample(palettes)}
      avatarstyle={avatarStyle}
      variant={variant}
      {...rest}
    >
      {!image && <span>{avatarText}</span>}
      {image && <ImageBlock aria-labelledby={imageAlt || title} src={image} />}
    </AvatarWrapper>
  );
};
