import React from "react";

import styled from "styled-components";

import Checkbox from "./Elements/Checkbox";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & .question-text {
    margin-bottom: 20px;
  }
`;
type SurveyCheckboxProps = {
  mode?: string;
  onChange: Function;
  options: {}[];
  question?: string;
};
const SurveyCheckbox = ({
  options,
  onChange,
  question,
  mode,
}: SurveyCheckboxProps): JSX.Element => (
  <Wrapper>
    {question && <div className="question-text">{question}</div>}
    <Checkbox options={options} onChange={onChange} />
  </Wrapper>
);

export default SurveyCheckbox;
