import React, { HTMLAttributes } from "react";

import {
  Card,
  CardContent,
  CardContentProps,
  CardProps,
  Typography,
} from "@material-ui/core";
import styled, { css } from "styled-components";

const CardTitle = styled(Typography)`
  font-size: 15px;
  font-weight: bold;
`;

type CardContentWrapperProps = CardContentProps & { customStyle?: any };
const CardContentWrapper = styled(
  ({ customStyle, ...props }): JSX.Element => <CardContent {...props} />,
)<CardContentWrapperProps>`
  padding: 0 !important;
  ${props => (props.customStyle ? props.customStyle(props) : "")};
`;

const CardHeaderSection = styled.div<
  HTMLAttributes<HTMLDivElement> & { sectionStyle?: Function }
>`
  height: 64px;
  padding: 0 24px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
`;

const CardActions = styled.div`
  margin-left: auto;
`;

const CardStartActions = styled.div`
  margin-left: 0;
`;

type CardWrapperProps = CardProps & { cardStyle?: any };
const CardWrapper = styled(
  ({ cardStyle, ...props }): JSX.Element => <Card {...props} />,
)<CardWrapperProps>`
  ${props =>
    css`
      ${props.cardStyle ? props.cardStyle(props) : ""}
    `}
`;

const ContentBodyWrapper = styled.div<
  HTMLAttributes<HTMLDivElement> & { contentStyle?: any }
>`
  ${props => (props.contentStyle ? props.contentStyle(props) : "")};
`;

export type WidgetCardProps = CardWrapperProps & {
  actions?: React.ReactNode;
  beforeactions?: any[];
  cardStyle?: Function;
  children: React.ReactNode;
  contentStyle?: Function;
  customStyle?: Function;
  headerCustomStyle?: Function;
};

export default ({
  customStyle,
  contentStyle,
  ...props
}: WidgetCardProps): JSX.Element => (
  <CardWrapper {...props}>
    <CardContentWrapper customStyle={customStyle}>
      {(props.title || props.actions || props.beforeactions) && (
        <CardHeaderSection sectionStyle={props.headerCustomStyle}>
          <CardTitle variant="h6">{props.title}</CardTitle>
          {props.beforeactions && (
            <CardStartActions>{props.beforeactions}</CardStartActions>
          )}
          <CardActions>{props.actions}</CardActions>
        </CardHeaderSection>
      )}
      {contentStyle ? (
        <ContentBodyWrapper contentStyle={contentStyle}>
          {props.children}
        </ContentBodyWrapper>
      ) : (
        props.children
      )}
    </CardContentWrapper>
  </CardWrapper>
);
