import React from "react";

import LocationIcon from "assets/icons/distribution.svg";
import styled from "styled-components";

import InputPreview from "./BasePreview";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
`;

interface IAddress {
  address1?: string;
  address2?: string;
  city?: string;
  country?: string;
  state?: string;
  zip?: string;
}

type AddressPreviewProps = {
  address: IAddress;
  reverse: boolean;
  sub: string;
};

export default ({
  address,
  sub,
  reverse,
}: AddressPreviewProps): JSX.Element => {
  const {
    country = "",
    address1 = "",
    address2 = "",
    city = "",
    state = "",
    zip = "",
  } = address;
  const emailComp = (
    <Wrapper>
      {(address1 || address2) && (
        <p>
          {address1 ? `${address1},` : ""} {address2 ? `${address2},` : ""}
        </p>
      )}
      {(city || state || zip) && (
        <p>
          {city ? `${city},` : ""} {state ? `${state},` : ""}{" "}
          {zip ? `${zip},` : ""}
        </p>
      )}
      {country && <p>{country}</p>}
    </Wrapper>
  );
  return (
    <InputPreview
      InputIcon={LocationIcon}
      main={emailComp}
      sub={sub}
      reverse={reverse}
    />
  );
};
