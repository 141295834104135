import React from "react";

import classNames from "classnames";
import { DragObjectWithType, DragSourceMonitor, useDrag } from "react-dnd";

export interface DraggableProps extends DragObjectWithType {
  children: React.ReactNode;
  className?: string;
  data: any;
  style?: Record<string, any>;
  onDrag(
    item: { data?: Record<string, any>; type: string | symbol },
    monitor?: DragSourceMonitor,
  ): void;
  onDrop(item: any, monitor?: DragSourceMonitor): void;
}

export default ({
  data,
  className,
  children,
  style,
  onDrag,
  onDrop,
  type,
  ...rest
}: DraggableProps) => {
  const [, drag] = useDrag({
    begin: monitor => {
      onDrag?.({ data, type }, monitor);
    },
    end: onDrop,
    item: { type, data },
  });
  return (
    <div
      className={classNames(className)}
      style={{ display: "inline-block", ...style }}
      ref={drag}
      {...rest}
    >
      {children}
    </div>
  );
};
