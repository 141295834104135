import React from "react";

import { Typography } from "@material-ui/core";
import _ from "lodash";

import styled from "styled-components";

const TapperWrapper = styled(({ active, disabled, ...props }) => (
  <div {...props} />
))`
  & {
    width: 136px;
    padding: 15px 10px 5px 10px;
    border-radius: 4px;
    text-align: center;
    position: relative;
    border: 1px solid
      ${props =>
        props.active && props.disabled
          ? props.theme.palette.text.secondary
          : props.active
          ? props.theme.palette.primary.main
          : props.theme.palette.tertiary.border};
  }

  .tail {
    width: 0;
    margin: auto;
    height: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: -10px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 9px solid
      ${props =>
        props.active && props.disabled
          ? props.theme.palette.text.secondary
          : props.theme.palette.primary.main};
  }
`;

const TapperLabelWrapper = styled(({ active, ...props }) => (
  <Typography {...props} />
))`
  & {
    font-size: 13px;
    padding-top: 5px;
    width: 90px;
    height: 40px;
    margin: auto;
    font-weight: bold;
    color: ${props =>
      props.theme.palette.text[props.active ? "primary" : "secondary"]};
  }
`;

const TapperIconCounterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: relative;
`;

const TapperIconWrapper = styled(({ active, ...props }) => <div {...props} />)`
  margin-left: 5px;
  margin-right: 5px;
  color: ${props =>
    props.theme.palette.text[props.active ? "primary" : "secondary"]};

  & > span {
    display: flex;
    align-items: center;
  }
`;

const TapperCountWrapper = styled(({ active, ...props }) => <div {...props} />)`
  font-size: 13px;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: ${props => (props.active ? "500" : "normal")};
  color: ${props =>
    props.theme.palette.text[props.active ? "primary" : "secondary"]};
`;

export type TapRadioProps = {
  active: boolean;
  count?: number;
  disabled: boolean;
  IconComponent: JSX.Element;
  label?: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export default ({
  label,
  IconComponent,
  count,
  active,
  onClick,
  disabled,
}: Readonly<TapRadioProps>): JSX.Element => (
  <TapperWrapper
    onClick={
      disabled
        ? () => {
            /* */
          }
        : onClick
    }
    active={active}
    disabled={!!disabled}
    className="explorer-tap-radio"
  >
    <TapperLabelWrapper active={active}>{label}</TapperLabelWrapper>
    <TapperIconCounterWrapper>
      <TapperIconWrapper active={active}>{IconComponent}</TapperIconWrapper>
      <TapperCountWrapper active={active}>
        {_.isNumber(count) ? count.toLocaleString() : count}
      </TapperCountWrapper>
    </TapperIconCounterWrapper>
    {active && <div className="tail" />}
  </TapperWrapper>
);
