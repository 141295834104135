import React, { HtmlHTMLAttributes } from "react";

import { Icon, Switch, Tooltip, TooltipProps, WidgetCard } from "atoms";

import styled from "styled-components";

import { IExtendedTheme } from "themes/default";

import ConfirmationDialog from "../ConfirmationDialog";

const IconIndicator = styled.div<
  HtmlHTMLAttributes<HTMLDivElement> & { active?: boolean }
>`
  & path[fill] {
    fill: ${({ active }) => (active ? "blue" : "#bbb")};
  }
`;

const ActivationIconWrapper = styled.span<
  HtmlHTMLAttributes<HTMLSpanElement> & { active?: boolean }
>`
  svg {
    path[fill] {
      fill: ${({ active, theme }) =>
        active ? theme.palette.primary.main : theme.palette.text.secondary};
    }
  }
`;

const IconActivateButtonWrapper = styled.span`
  margin-left: ${props => props.theme.sizes.questionCard.iconButton.marginLeft};
  margin-right: ${props =>
    props.theme.sizes.questionCard.iconButton.marginRight};
  margin-top: ${props => props.theme.sizes.questionCard.iconButton.marginTop};
  margin-bottom: ${props =>
    props.theme.sizes.questionCard.iconButton.marginBottom};

  &:hover {
    cursor: pointer;
  }
`;
type IconActivateButtonProps = {
  icon: JSX.Element;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};
const IconActivateButton = ({
  onClick,
  icon,
}: IconActivateButtonProps): JSX.Element => (
  <IconActivateButtonWrapper onClick={onClick}>
    {icon}
  </IconActivateButtonWrapper>
);

const ToolTipWrapper = ({
  text,
  children,
  enabled,
}: TooltipProps): JSX.Element => {
  return (
    <Tooltip text={text} enabled={enabled}>
      {children}
    </Tooltip>
  );
};

const ActionItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${props => props.theme.sizes.questionCard.actionItem.marginLeft};
  margin-right: ${props =>
    props.theme.sizes.questionCard.actionItem.marginRight};

  .label {
    color: ${props => props.theme.palette.text.secondary};
    font-size: ${props => props.theme.sizes.questionCard.actionItem.fontSize};
    line-height: ${props =>
      props.theme.sizes.questionCard.actionItem.lineHeight};
  }
`;
type IActionItem = {
  component: JSX.Element;
  label: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  toolTipText: string;
};
const ActionItem = ({
  onClick,
  component,
  label,
  toolTipText,
}: IActionItem): JSX.Element => (
  <ToolTipWrapper text={toolTipText} enabled={true}>
    <ActionItemWrapper onClick={onClick}>
      {component}
      <div className="label">{label}</div>
    </ActionItemWrapper>
  </ToolTipWrapper>
);

const customStyle = () => `
  position: relative;
`;

const cardStyleComponent = () => `
  margin-bottom: 40px;
`;

const QuestionCardContentWrapper = styled.div`
  padding: ${props => props.theme.sizes.questionCard.content.padding};
  margin-bottom: ${props => props.theme.sizes.questionCard.actions.height};
`;

const QuestionCardFooterWrapper = styled.div`
  background-color: ${props => props.theme.palette.colors.basic["200"]};
  height: ${props => props.theme.sizes.questionCard.actions.height};
  display: flex;
  width: 100%;
  position: absolute;
  bottom: -${props => props.theme.sizes.questionCard.actions.height};
  padding: ${props => props.theme.sizes.questionCard.actions.padding};

  .left-actions {
    display: flex;
    padding: ${props => props.theme.sizes.questionCard.actions.left.padding};
  }

  .right-actions {
    padding: ${props => props.theme.sizes.questionCard.actions.right.padding};
    display: flex;
    margin-left: auto;
  }
`;

export type QuestionCardProps = {
  cardStyle: any;
  children: React.ReactNode;
  hasConditions?: boolean;
  isConditional?: boolean;
  isLinkable?: boolean;
  isLinked?: boolean;
  isRequireable?: boolean;
  isRequired?: boolean;
  isShareable?: boolean;
  isShared?: boolean;
  onChangeConditional?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  onChangeRequired?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  onChangeShared?: (isShared: boolean) => void;
  onDelete: () => void;
  onDuplicate: () => void;
  onOpenFeaturedLink?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  toolTipText: string;
  withToolTip: boolean;
  theme?: IExtendedTheme;
  controlName?: string;
};

export default ({
  children,
  onDelete,
  onDuplicate,
  cardStyle,
  isLinkable,
  onOpenFeaturedLink,
  isLinked,
  isShareable,
  onChangeShared,
  isShared,
  isRequireable,
  onChangeRequired,
  isRequired,
  isConditional,
  onChangeConditional,
  hasConditions,
  toolTipText,
  theme,
  controlName = "question",
}: Readonly<QuestionCardProps>): JSX.Element => {
  const [confirmation, setConfirmation] = React.useState<any>({ open: false });

  const onDeleteQuestion = () => {
    setConfirmation({
      confirmColor: "warning",
      confirmText: "Yes, Delete",
      message: `Are you sure you want to delete this ${controlName}?`,
      onConfirm: () => {
        setConfirmation({ open: false });
        onDelete();
      },
      open: true,
      title: "Delete Question",
    });
  };

  const onDuplicateQuestion = () => {
    setConfirmation({
      confirmColor: "infoLight",
      confirmText: "Yes, Duplicate",
      message: `Are you sure you want to duplicate this ${controlName}?`,
      onConfirm: () => {
        setConfirmation({ open: false });
        onDuplicate();
      },
      open: true,
      title: "Duplicate Question",
    });
  };

  const onChecked = handler => event => handler(event.target.checked);

  return (
    <React.Fragment>
      <WidgetCard
        cardStyle={cardStyle || cardStyleComponent}
        customStyle={customStyle}
      >
        <QuestionCardContentWrapper>{children}</QuestionCardContentWrapper>
        <QuestionCardFooterWrapper>
          <div className="left-actions">
            <ActionItem
              toolTipText={toolTipText}
              label=""
              onClick={onDuplicateQuestion}
              component={
                <IconActivateButton
                  icon={
                    <ActivationIconWrapper>
                      <Icon
                        name="templates"
                        height={theme?.sizes.questionCard.actionItem.iconSize}
                        width={theme?.sizes.questionCard.actionItem.iconSize}
                      />
                    </ActivationIconWrapper>
                  }
                />
              }
            />
            <ActionItem
              toolTipText={toolTipText}
              label=""
              onClick={onDeleteQuestion}
              component={
                <IconActivateButton
                  icon={
                    <ActivationIconWrapper>
                      <Icon
                        name="dustbin"
                        height={theme?.sizes.questionCard.actionItem.iconSize}
                        width={theme?.sizes.questionCard.actionItem.iconSize}
                      />
                    </ActivationIconWrapper>
                  }
                />
              }
            />
          </div>
          <div className="right-actions">
            {isLinkable && (
              <ActionItem
                toolTipText={toolTipText}
                label="Link Feature"
                component={
                  <IconActivateButton
                    onClick={onOpenFeaturedLink}
                    icon={
                      <IconIndicator active={isLinked}>
                        <Icon
                          name="link"
                          height={theme?.sizes.questionCard.actionItem.iconSize}
                          width={theme?.sizes.questionCard.actionItem.iconSize}
                        />
                      </IconIndicator>
                    }
                  />
                }
              />
            )}
            {isShareable && (
              <ActionItem
                toolTipText={toolTipText}
                label="Add to Question Bank"
                component={
                  <IconActivateButton
                    onClick={() => onChangeShared && onChangeShared(!isShared)}
                    icon={
                      isShared ? (
                        <IconIndicator>
                          <Icon name="questionBank-active" />
                        </IconIndicator>
                      ) : (
                        <Icon name="questionBank" />
                      )
                    }
                  />
                }
              />
            )}
            {isRequireable && (
              <ActionItem
                toolTipText={toolTipText}
                label="Required"
                component={
                  <Switch
                    checked={isRequired}
                    onChange={onChecked(onChangeRequired)}
                  />
                }
              />
            )}
            {isConditional && (
              <ActionItem
                toolTipText={toolTipText}
                label="Conditional Logic"
                component={
                  <Switch
                    checked={hasConditions}
                    onChange={onChecked(onChangeConditional)}
                  />
                }
              />
            )}
          </div>
        </QuestionCardFooterWrapper>
      </WidgetCard>
      <ConfirmationDialog
        title={confirmation.title}
        open={confirmation.open}
        confirmText={confirmation.confirmText}
        cancelText={"Cancel"}
        confirmColor={confirmation.confirmColor}
        onClose={() => setConfirmation({ open: false })}
        onCancel={() => setConfirmation({ open: false })}
        onConfirm={confirmation.onConfirm}
      >
        {confirmation.message}
      </ConfirmationDialog>
    </React.Fragment>
  );
};
