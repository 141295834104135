import React from "react";

import styled from "styled-components";

import SurveyElementRadioGroup from "./Elements/Radio";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & .question-text {
    margin-bottom: 20px;
  }
`;

type SurveyRadioGroupProps = {
  name: string;
  onChange: Function;
  options: { label: any; value: any }[];
  question?: any;
  value: any;
  mode?: string;
};
const SurveyRadioGroup = ({
  value,
  name,
  onChange,
  options,
  question,
  mode,
}: SurveyRadioGroupProps): JSX.Element => (
  <Wrapper>
    {question && <div className="question-text">{question}</div>}
    <SurveyElementRadioGroup
      value={value}
      options={options}
      name={name}
      onChange={onChange}
    />
  </Wrapper>
);

export default SurveyRadioGroup;
