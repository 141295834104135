import React, { HTMLAttributes } from "react";

import BadgeIcon from "assets/icons/badge.svg";
import _ from "lodash";
import sample from "lodash/sample";
import styled, { css } from "styled-components";

const palettes = ["success", "warning", "infoLight", "tertiary"];

const commonStyle = css<HTMLAttributes<HTMLDivElement> & { width: number }>`
  width: ${({ width }) => width}px;
  height: 100%;
  border-radius: 4px;
  font-size: ${props => props.width / 2.25}px;
  display: flex;
`;

type ImageWrapperProps = HTMLAttributes<HTMLDivElement> & any;
const ImageWrapper = styled.div<ImageWrapperProps>`
  ${commonStyle};
  img {
    width: ${({ width }) =>
      _.isNumber(width) ? `${width}px` : width || "40px"};
    height: ${({ height }) =>
      _.isNumber(height) ? `${height}px` : height || "40px"};
  }

  .icon {
    circle {
      fill: ${props => {
        if (props.theme === "orange") {
          return "#fff4e5";
        }

        if (props.theme === "green") {
          return "#f3f9e7";
        }

        if (props.theme === "blue") {
          return "#f0faff";
        }

        if (props.theme === "yellow") {
          return "#fff8cc";
        }
      }};
    }

    path {
      &:nth-child(3) {
        fill: ${props => {
          if (props.theme === "orange") {
            return "#ffd280";
          }

          if (props.theme === "green") {
            return "#c8ee56";
          }

          if (props.theme === "blue") {
            return "#97d8f7";
          }

          if (props.theme === "yellow") {
            return "#ffe266";
          }
        }};
      }

      fill: ${props => {
        if (props.theme === "orange") {
          return "#ffe4bc";
        }

        if (props.theme === "green") {
          return "#daf678";
        }

        if (props.theme === "blue") {
          return "#c0e8fb";
        }

        if (props.theme === "yellow") {
          return "#ffee99";
        }
      }};
    }
  }
`;

export type BadgeProps = ImageWrapperProps & {
  height?: number;
  width?: number;
};

export default ({
  width = 40,
  height = 40,
  theme,
  ...rest
}: BadgeProps): JSX.Element => {
  return (
    <ImageWrapper
      theme={theme}
      width={width}
      height={height}
      palette={sample(palettes)}
      {...rest}
    >
      <BadgeIcon
        // theme={theme}
        width={width}
        height={height}
        className={"icon"}
      />
    </ImageWrapper>
  );
};
