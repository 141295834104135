import React, { useEffect } from "react";

import MaterialMenu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";
import styled, { css } from "styled-components";

const ITEM_HEIGHT = 48;

// @Mohib, The fact that .Muimenu-paper class is to be used is mentioned here - https://material-ui.com/api/menu/#css
export const StyledMenu = styled(MaterialMenu)<
  MenuProps & { menuStyle?: Function }
>`
  // todo: Unused component?
  & .MuiMenu-paper {
    max-height: ${ITEM_HEIGHT * 4.5}px;
    width: 20ch;
    ${props =>
      css`
        ${props.menuStyle ? props.menuStyle(props) : ""}
      `}
  }
`;

// @Mohib, The rule name that's used here which is "paper" is also mentioned
// here - https://material-ui.com/api/menu/#css

// Same as above, but with own defined class name.
// Also by explicitly specifying the styled-comp specific prop which is "menuStyle"
// here, we are preventing it to be passed to child React comp
const ReStyledMenu = styled(
  ({ menuStyle, ...rest }): JSX.Element => (
    <MaterialMenu classes={{ paper: "custom-paper-class-name" }} {...rest} />
  ),
)`
  & .custom-paper-class-name {
    max-height: ${ITEM_HEIGHT * 4.5}px;
    width: 20ch;
    ${props =>
      css`
        ${props.menuStyle ? props.menuStyle(props) : ""}
      `}
  }
`;

// @Mohib, explanation of handling nested style - https://material-ui.com/guides/interoperability/#deeper-elements-2

/* MUST - Externalize styles. menuStyle is a method that returns all the custom style
 that's to be added. The fact that you are using it here to set that as style for the
  paper is hidden from the user of 'Menu' and thus hiding styling implementation details */

/* MUST - You almost every time need to use {...rest} so that all props that the
underlying material comp supports can be used by the calling component if needed.
For example here, if the user wants to open the menu by providing position co-ords
or wants to state the exact point of the anchor element where the menu is to be
anchored to he can't without us letting in the reqd props */

/* MUST - Provide hooks to end user actions as a principle everywhere so that
action specific handling can be done if required anywhere where the component is used */

/* MENU specific - menus anchor by two ways - either by element or position, so we need
 to take into consideration both, and not just anchor by element
anchorPosition: {<position coords>}, right-click support can't be provided without supporting postion */

/* MUST - Have to account for when child items are elements/comp anything other than a text node */

export interface IOptionsProps extends MenuItemProps {
  comp: any;
  disabled?: boolean;
  name: string;
  value?: any;
}

export type ExplorerMenuProps = {
  anchorOrigin?: object;
  anchorPosition?: object;
  children: React.ReactNode;
  closeOnSelect?: boolean;
  getContentAnchorEl?: any;
  menuStyle?: Function;
  onClose?: Function;
  onSelect?: Function;
  openOnContext?: boolean;
  options: IOptionsProps[];
  transformOrigin: object;
};

export default ({
  options,
  children,
  anchorPosition,
  openOnContext,
  menuStyle,
  onSelect,
  onClose,
  closeOnSelect,
  ...rest
}: ExplorerMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const anchorPositionProp: any = {};

  const setAnchorPositioning = () => {
    if (anchorPosition) {
      anchorPositionProp.anchorReference = "anchorPosition";
      anchorPositionProp.anchorPosition = anchorPosition;
    }
  };

  useEffect(setAnchorPositioning, [anchorPosition]);

  const open = !anchorPosition ? Boolean(anchorEl) : Boolean(anchorPosition);

  const handleClick = event => {
    event.preventDefault();
    if (!anchorPosition) {
      setAnchorEl(event.currentTarget);
    }
    if (onSelect) {
      onSelect(event);
    }
  };

  const handleClose = () => {
    if (!anchorPosition) {
      setAnchorEl(null);
    }
    if (onClose) {
      onClose();
    }
  };

  const actionHandler = !openOnContext
    ? { onClick: handleClick }
    : { onContextMenu: handleClick };

  const handleMenuItemClick = option => e => {
    option.onClick(option.value, e);
    if (closeOnSelect) {
      setAnchorEl(null);
    }
  };

  const renderMenuItems = (option: IOptionsProps) => (
    <MenuItem
      key={option.name}
      disabled={option.disabled}
      onClick={handleMenuItemClick(option)}
    >
      {option.comp ? <option.comp /> : option.name}
    </MenuItem>
  );

  return (
    <>
      <div {...actionHandler}>{children}</div>
      <ReStyledMenu
        id="long-menu"
        keepMounted={true}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        menuStyle={menuStyle}
        {...rest}
        {...anchorPositionProp}
      >
        {options.map(renderMenuItems)}
      </ReStyledMenu>
    </>
  );
};
