import React, { HTMLAttributes } from "react";

import { Tooltip } from "@material-ui/core";
import { FaChevronRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import Text from "../Text";

const BreadcrumbItemWrapper = styled.div<
  HTMLAttributes<HTMLDivElement> & { isLast: boolean }
>`
  color: ${props => props.theme.palette.colors.basic[props.isLast ? 800 : 600]};

  .link {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
  }

  .icon {
    color: ${props => props.theme.palette.colors.basic[500]};
    margin-left: 3px;
    margin-right: 3px;
  }
`;

const BreadcrumbItem = (props): JSX.Element => {
  const { isFirst, isLast, item } = props;
  const history = useHistory();

  const title = item.hasOwnProperty("title") ? item.title : item;
  const link = item.hasOwnProperty("link") ? item.link : null;

  return (
    <BreadcrumbItemWrapper {...props}>
      <Tooltip title={title} placement="bottom">
        <Text
          noWrap={true}
          variant={"body2"}
          className={link ? "link" : ""}
          onClick={() => {
            return link ? history.push(link) : null;
          }}
        >
          {title}
        </Text>
      </Tooltip>
      {!isLast && (
        <div className="icon">
          <FaChevronRight size={8} />
        </div>
      )}
    </BreadcrumbItemWrapper>
  );
};

interface ILink {
  link: string;
  title: string;
}

export type BreadcrumbEntityProps = {
  title: string;
  link?: string;
};

export type BreadcrumbProps = {
  breadcrumb: Array<BreadcrumbEntityProps | string>;
};

export default styled(
  ({ ...props }: Readonly<BreadcrumbProps>): JSX.Element => (
    <div {...props}>
      {props.breadcrumb.map((item, key) => (
        <BreadcrumbItem
          {...props}
          item={item}
          key={String(key)}
          isFirst={key === 0}
          isLast={props.breadcrumb.length - 1 === key}
        />
      ))}
    </div>
  ),
)`
  display: flex;
  align-items: center;
`;
