import React, { ReactElement } from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";
import MaterialSelect, { SelectProps } from "@material-ui/core/Select";
import _ from "lodash";

import { RiArrowDownSLine } from "react-icons/ri";
import styled, { css } from "styled-components";

type SelectCustomProps = {
  borderStyle?: string;
  iconHint?: any;
  isFilled?: boolean;
  isSmall?: boolean;
  labelIcon?: boolean;
  withHint?: boolean;
};

const switchBorderStyle = (borderStyle, propsThemePalette) => {
  switch (borderStyle) {
    case "active":
      return propsThemePalette.activeBorder;
    case "success":
      return propsThemePalette.successBorder;
    case "error":
      return propsThemePalette.errorBorder;
    case "ghost":
      return propsThemePalette.ghostBorder;
    default:
      return propsThemePalette.defaultBorder;
  }
};

const ExplorerSelect = styled(
  ({ selectStyle, className, ...rest }): JSX.Element => (
    <MaterialSelect {...rest} MenuProps={{ classes: { paper: className } }} />
  ),
)`
  min-width: 100px;
  // Dropdown of Select Styling
  && {
    background-color: ${props => props.menuItemColor};
    top: 62px !important;
    ul {
      padding: 0;
    }
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    padding-left: 10px;
    padding-top: 13px;
    padding-bottom: 13px;
  }
  .MuiSelect-select:focus {
    background: none;
  }
  .MuiSelect-selectMenu {
    min-height: initial !important;
  }
  .MuiSelect-icon {
    font-size: 24px;
    color: #aaa;
  }
  ${props =>
    css`
      ${props.selectStyle ? props.selectStyle(props) : ""}
    `}
`;

const ExplorerFormControl = styled(
  ({ formControlStyle, showInputLabel, ...rest }) => <FormControl {...rest} />,
)`
  //fieldset {
  //  top: 0;
  //  legend {
  //    display: none;
  //  }
  //}

  .MuiOutlinedInput-notchedOutline {
    border-color: ${props =>
      switchBorderStyle(props.borderStyle, props.theme.palette.Select)};
  }

  fieldset {
    legend {
      max-width: 0;
    }
  }
  ${props =>
    css`
      ${!props.showInputLabel
        ? `
      fieldset {
        top: 0;
        legend {
          display: none;
          max-width: 0;
        }
      }`
        : ""}
      ${props.formControlStyle ? props.formControlStyle(props) : ""}
    `}
`;

type SMItemProps = MenuItemProps & {
  innerRef?: React.Ref<any>;
  menuItemStyle?: Function;
};
const SMItem = styled(
  ({ menuItemStyle, innerRef, ...rest }: SMItemProps): JSX.Element => (
    <MenuItem ref={innerRef} {...(rest as any)} />
  ),
)`
  &:hover {
    border-radius: 4px;
    background-color: ${props => props.theme.palette.Select.menuItemColor};
  }
  ${props =>
    css`
      ${props.menuItemStyle ? props.menuItemStyle(props) : ""}
    `}
`;
// Intermediary to handle ref forwarding
const SelectMenuItem = React.forwardRef(
  (props: SMItemProps, ref: React.Ref<any>) => (
    <SMItem innerRef={ref} {...props} />
  ),
);

const RenderHint = styled.span<SelectCustomProps>`
  color: #8f9bb3;
  font-size: ${props =>
    props.isSmall ? "13px !important" : "15px !important"};
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const StyledInputLabel = styled(InputLabel)<SelectCustomProps>`
  color: ${props => (props.isFilled ? "#222b45" : "#8f9bb3")};
  font-size: ${props => (props.isSmall ? "13px" : "15px")};
  position: ${props => (props.labelIcon ? "absolute" : "")};
  top: ${props => (props.labelIcon ? "50%" : "")};
  left: ${props => (props.labelIcon ? "10px" : "")};
  transform: ${props => (props.labelIcon ? "translateY(-50%)" : "")};
  padding-left: ${props => (props.iconHint ? "30px" : "10px")};
  svg {
    left: ${props => (props.iconHint ? "0" : "30px")};
  }
  &.MuiInputLabel-shrink {
    transform: ${props =>
      props.labelIcon
        ? "translateY(-50%) !important"
        : "translate(14px, 16px) scale(1)"};
  }
`;

export type ISelectProps = SelectProps &
  SelectCustomProps & {
    borderStyle?: SelectCustomProps;
    dropdownStyle?: object;
    formControlStyle?: object;
    hint?: any;
    iconHint?: SelectCustomProps;
    isFilled?: SelectCustomProps;
    isSmall?: SelectCustomProps;
    labelIcon?: SelectCustomProps;
    menuItemStyle?: any;
    multiple?: boolean;
    onChange: (event: React.ChangeEvent) => void;
    options: any[];
    renderHint?: ReactElement;
    selectStyle?: object;
    showInputLabel?: object;
    showNone?: boolean;
    withHint?: SelectCustomProps;
  };
export default ({
  id,
  name,
  label,
  options,
  value,
  dropdownStyle,
  selectStyle,
  formControlStyle,
  menuItemStyle,
  showInputLabel,
  onChange,
  multiple,
  showNone,
  withHint,
  hint,
  iconHint,
  isFilled,
  isSmall,
  borderStyle,
  labelIcon,
  ...props
}: ISelectProps): JSX.Element => (
  <ExplorerFormControl
    variant="outlined"
    formControlStyle={formControlStyle}
    showInputLabel={showInputLabel}
    borderStyle={borderStyle}
  >
    {showInputLabel && label && id && (
      <React.Fragment>
        <StyledInputLabel
          id={id + "-label"}
          isFilled={false}
          isSmall={false}
          labelIcon={labelIcon}
          iconHint={iconHint}
        >
          {iconHint ? (
            <RenderHint isSmall={isSmall}>{iconHint}</RenderHint>
          ) : (
            ""
          )}
          {!value && label ? label : ""}
          {withHint ? <RenderHint isSmall={isSmall}>{hint}</RenderHint> : ""}
        </StyledInputLabel>
      </React.Fragment>
    )}
    <ExplorerSelect
      id={id}
      name={name}
      IconComponent={RiArrowDownSLine}
      style={dropdownStyle}
      onChange={e => onChange(e.target.value)}
      labelId={id + "-label"}
      label={label}
      borderStyle={borderStyle}
      selectStyle={selectStyle}
      {...(!_.isUndefined(multiple) ? { multiple } : {})}
      {...(!_.isUndefined(value) ? { value } : {})}
      {...props}
    >
      {showNone && (
        <SelectMenuItem value="" menuItemStyle={menuItemStyle}>
          <em>None</em>
        </SelectMenuItem>
      )}
      {(options || []).map(({ value, label }) => (
        <SelectMenuItem key={value} value={value} menuItemStyle={menuItemStyle}>
          {label}
        </SelectMenuItem>
      ))}
    </ExplorerSelect>
  </ExplorerFormControl>
);
