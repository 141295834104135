import React, { useState } from "react";

import { Input, Text } from "atoms";
import constants from "config/constants";
import styled from "styled-components";

import * as Matrices from "../Matrices";
import CheckboxMatrix from "../Matrices/Checkbox";
import DropdownMatrix from "../Matrices/Dropdown";
import SurveyCheckbox from "../Survey/Checkbox";
import SurveyRadioGroup from "../Survey/Radio";

const { Radio: RadioMatrix } = Matrices;

const { answerType } = constants;

const QuestionCardPreviewWrapper = styled.div<{ palette?: string }>`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;

  .question__text {
    margin: 10px 0 0 0;
    color: #222b45;

    &_note {
      color: ${({ theme, palette = "primary" }) => theme.palette[palette].main};
    }
  }
`;

const usePrevious = value => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

interface IQuestionInfo {
  config?: any;
  text?: string;
  type?: string;
}

export type QuestionCardPreviewProps = {
  onChange: Function;
  palette?: string;
  questionInfo?: IQuestionInfo;
  required?: boolean;
  showText?: boolean;
};

export default ({
  questionInfo = {},
  showText = false,
  required = false,
  onChange,
  palette,
}: QuestionCardPreviewProps): JSX.Element => {
  const { type, config, text } = questionInfo;

  let rows: any[] = [];
  let columns: any[] = [];

  let defaultSelectionSet: any[] = [];
  let defaultOptionList: any[] = [];
  let defaultRadioVal = "";
  let defaultTextField = "";

  let configType = "";
  switch (type) {
    case answerType.radio:
      configType = "radio";
      const selectedRadio = config.radio.answers.find(x => x.checked);
      defaultRadioVal = selectedRadio ? selectedRadio.text : "";
      break;

    case answerType.checkbox:
      configType = "checkbox";
      defaultOptionList = config.checkbox.answers
        .filter(x => x.checked)
        .map(x => {
          return x.text;
        });
      break;

    case answerType.checkboxMatrix:
      configType = "checkboxMatrix";
      defaultSelectionSet = config.checkboxMatrix.selectionSet
        ? [...config.checkboxMatrix.selectionSet]
        : [];
      break;

    case answerType.radioMatrix:
      configType = "radioMatrix";
      defaultSelectionSet = config.radioMatrix.selectionSet
        ? [...config.radioMatrix.selectionSet]
        : [];
      break;

    case answerType.selectMatrix:
      configType = "selectMatrix";
      defaultSelectionSet = config.selectMatrix.selectionSet
        ? [...config?.selectMatrix?.selectionSet]
        : [];
      break;

    case answerType.text:
      configType = "text";
      defaultTextField = config && config.text ? config.text.text : "";
      break;

    default:
  }

  if (config && config[configType]) {
    rows = config[configType].rows ? config[configType].rows : [];
    columns = config[configType].answers
      ? config[configType].answers
      : config[configType].columns
      ? config[configType].columns
      : [];
  } else {
    rows = config && config.rows ? config.rows : [];
    columns =
      config && config.answers
        ? config.answers
        : config && config.columns
        ? config.columns
        : [];
  }

  columns = columns.map(column => {
    if (column.answers) {
      return {
        ...column,
        options: column.answers,
      };
    }
    return column;
  });

  const surveyRadioOptions = columns.length
    ? columns.map(x => {
        return { value: x.text, label: x.text };
      })
    : [];

  const surveyCheckboxOptions = columns.length
    ? columns.map(x => {
        return {
          checked: defaultOptionList.includes(x.text),
          label: x.text,
          name: x.id,
        };
      })
    : [];

  const [selectionSet, setSelectionSet] = useState(defaultSelectionSet);
  const [radioVal, setRadioVal] = useState(defaultRadioVal);
  const [textField, setTextField] = useState(defaultTextField);
  const [options, setOptions] = useState(surveyCheckboxOptions);

  const radioValRef = usePrevious(radioVal);
  const textFieldRef = usePrevious(textField);
  const selectionSetRef = usePrevious(selectionSet);
  const optionsRef = usePrevious(options);

  const onChangeHandler = React.useCallback((...value) => onChange(...value), [
    onChange,
  ]);

  React.useEffect(() => {
    let isUpdated = false;
    if (
      (optionsRef !== undefined && optionsRef !== options) ||
      (radioValRef !== undefined && radioValRef !== radioVal) ||
      (textFieldRef !== undefined && textFieldRef !== textField) ||
      (selectionSetRef !== undefined && selectionSetRef !== selectionSet)
    ) {
      isUpdated = true;
    }

    if (isUpdated) {
      onChangeHandler({
        options,
        radioVal,
        selectionSet,
        textField,
      });
    }
  }, [
    radioVal,
    selectionSet,
    options,
    textField,
    onChange,
    onChangeHandler,
    optionsRef,
    radioValRef,
    textFieldRef,
    selectionSetRef,
  ]);

  return (
    <QuestionCardPreviewWrapper palette={palette}>
      {(showText || (!showText && type === answerType.text)) && (
        <Text className={"question__text"} variant={"subtitle1"}>
          {text} {required && <span className={"question__text_note"}>*</span>}
        </Text>
      )}

      {type === answerType.radio && (
        <SurveyRadioGroup
          value={radioVal}
          options={surveyRadioOptions}
          name="test-survey"
          onChange={event => {
            setRadioVal(event.target.value);
          }}
          mode="preview"
        />
      )}

      {type === answerType.checkbox && (
        <SurveyCheckbox
          options={options}
          onChange={(checkedStatus, index) => {
            setOptions(opts => {
              const currentOptions = [...opts];
              const updatedOption = currentOptions[index];
              updatedOption.checked = checkedStatus;
              return currentOptions;
            });
          }}
          mode="preview"
        />
      )}

      {type === answerType.radioMatrix && (
        <RadioMatrix
          matrix={[rows, columns]}
          disabled={false}
          selections={selectionSet}
          onSelectionUpdate={({ added, removed, modified, selections }) => {
            setSelectionSet(selections);
          }}
        />
      )}

      {type === answerType.checkboxMatrix && (
        <CheckboxMatrix
          matrix={[rows, columns]}
          disabled={false}
          selections={selectionSet}
          onSelectionUpdate={({ added, removed, modified, selections }) => {
            setSelectionSet(selections);
          }}
        />
      )}

      {type === answerType.selectMatrix && (
        <DropdownMatrix
          matrix={[rows, columns]}
          disabled={false}
          selections={selectionSet}
          onSelectionUpdate={({ added, removed, modified, selections }) => {
            setSelectionSet(selections);
          }}
        />
      )}

      {type === answerType.text && (
        <Input
          multiline={true}
          rows={4}
          value={textField}
          onChange={e => {
            setTextField(e.target.value);
          }}
        />
      )}
    </QuestionCardPreviewWrapper>
  );
};
