import React, { useEffect, useRef, useState } from "react";

import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";

export interface ControlledTooltipProps extends Omit<TooltipProps, "children"> {
  children: (
    ref: React.MutableRefObject<HTMLElement | null>,
  ) => React.ReactElement;
}

export default ({ children, ...props }: ControlledTooltipProps) => {
  const [isTooltipVisible, setTooltipState] = useState(false);
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      const { top, right, bottom, left } = ref.current.getBoundingClientRect();
      const handler = e => {
        if (e.x > left && e.x < right && e.y > top && e.y < bottom) {
          setTooltipState(true);
        } else {
          setTooltipState(false);
        }
      };
      window.addEventListener("mousemove", handler);
      return () => window.removeEventListener("mousemove", handler);
    }
  }, []);
  return (
    <Tooltip open={isTooltipVisible} {...props}>
      {children(ref)}
    </Tooltip>
  );
};
