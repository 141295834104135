import React, { HTMLAttributes } from "react";

import InputBase, { InputBaseProps } from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import styled from "styled-components";

const SearchWrapper = styled.div<
  HTMLAttributes<HTMLDivElement> & { filled?: boolean }
>`
  position: relative;
  display: flex;
  border-radius: 4px;
  background-color: ${props => (props.filled ? "#edf1f7" : "transparent")};
  width: 500px;
  height: 40px;

  &:hover {
    background-color: ${props => (props.filled ? "#f7f9fc" : "#f7f9fc")};
  }

  &:focus-within {
    background-color: ${props => (props.filled ? "#edf1f7" : "#f7f9fc")};
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: ${props => (props.filled ? "#4a99f9" : "transparent")};
  }

  .search-icon {
    display: flex;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    width: 50px;
    color: #c5cee0;
    height: 100%;
    margin-left: 10px;
  }

  .MuiInputBase-root {
    color: inherit;
    position: relative;

    .MuiInputBase-input {
      font-size: 13px;
      width: 450px;
      padding: 5px 15px;
      border-color: transparent;
      box-shadow: none;
      opacity: 1;
    }
  }
`;

export type SearchProps = InputBaseProps & {
  filled?: boolean;
};
export default (props: Readonly<SearchProps>): JSX.Element => (
  <SearchWrapper filled={props.filled}>
    <div className="search-icon">
      <SearchIcon />
    </div>
    <InputBase
      placeholder="Search…"
      inputProps={{ "aria-label": "search" }}
      {...props}
    />
  </SearchWrapper>
);
