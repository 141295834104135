import React, { HTMLAttributes } from "react";

import classNames from "classnames";

import { FaInfoCircle } from "react-icons/fa";
import styled from "styled-components";

import Text from "../Text";

type Layout = { layout: "icon-left" | "icon-right" };
type InfoCardWrapperProps = HTMLAttributes<HTMLDivElement> & Layout;

const InfoCardWrapper = styled.div<InfoCardWrapperProps>`
  background: ${props => props.theme.palette.InfoCard.backgroundColor};
  border: 1px solid ${props => props.theme.palette.InfoCard.borderColor};
  border-radius: 4px;
  padding: 12px 24px;
  position: relative;
  padding-${props => (props.layout === "icon-left" ? "left" : "right")}: 40px;

  .header {
    .icon {
      position: absolute;
      top: 10px;
      ${props => (props.layout === "icon-left" ? "left" : "right")}: 10px;
      color: ${props => props.theme.palette.InfoCard.iconColor};
    }

    .title {
      margin-bottom: 12px;
      color: ${props => props.theme.palette.InfoCard.textColor};
    }
  }

  .content {
    .description {
      color: ${props => props.theme.palette.InfoCard.textColor};
    }

    .children {
      margin-top: 12px;
    }
  }

  .hidden {
    display: none;
  }
`;

export type InfoCardProps = Layout & {
  children?: React.ReactNode;
  description: string;
  title?: string;
};

export default ({
  title,
  description,
  layout,
  children,
}: InfoCardProps): JSX.Element => (
  <InfoCardWrapper layout={layout}>
    <header className="header">
      <div className={classNames("title", { hidden: !title?.length })}>
        <Text noWrap={true} variant={"subtitle1"}>
          {title}
        </Text>
      </div>
      <div className="icon">
        <FaInfoCircle size={20} />
      </div>
    </header>
    <div className="content">
      <div className="description">
        <Text>{description}</Text>
      </div>
      <div
        className={classNames("children", {
          hidden: typeof children === "undefined",
        })}
      >
        {children}
      </div>
    </div>
  </InfoCardWrapper>
);
