/* eslint-disable */
import IconButton from '@material-ui/core/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import React  from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled, { css } from "styled-components";
import { get, isEqual } from "lodash";
import { extractContent } from "utils/helper";
import ColorPicker from "./component/colorPicker";
import CustomLink from "./component/customLink";
import CustomToolbar from "./component/customToolbar";
import ControlledTooltip from "./component/ControlledTooltip";
import ImageResize from "quill-image-resize-module";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import colorPalette from "themes/default";
import SmartBreak, { lineBreakMatcher } from "./plugins/smartBreak";
import ImageWithStyle from "./plugins/styledImage";
import ParagraphBlot from "./plugins/customParagpaph";
import PlainClipboard from './plugins/plainClipboard'
import { IColor, IEditorConfig } from "./interfaces";

import { red, blue, green, orange, yellow, grey } from "@material-ui/core/colors";

import { Icon } from "atoms";

const AlignmentCenter = () => <Icon name="align-center" />;
const AlignmentJustify = () => <Icon name="align-justify" />;
const AlignmentLeft = () => <Icon name="align-left" />;
const AlignmentRight = () => <Icon name="align-right" />;
const Bold = () => <Icon name="bold" />;
const Upload = () => <Icon name="cloud-upload-black" />;
const DownArrow = () => <Icon name="dropdown-arrow-down" />;
const Italic = () => <Icon name="italic" />;
const Link = () => <Icon name="link" />;
const ListBullet = () => <Icon name="list-bullet" />;
const ListNumber = () => <Icon name="list-numbers" />;
const Redo = () => <Icon name="redo" />;
const Underline = () => <Icon name="underline" />;
const Undo = () => <Icon name="undo" />;

const icons = Quill.import("ui/icons");
icons.bold = <Bold />;
icons.italic = <Italic />;
icons.underline = <Underline />;
icons.color = "";
icons.background = "";
icons.link = <Link />;
icons.image = <Upload />;
icons.list = <ListNumber />;
icons.list = <ListBullet />;
icons.align = <AlignmentLeft />;
icons.align = <AlignmentRight />;
icons.align = <AlignmentCenter />;
icons.align = <AlignmentJustify />;

Quill.debug('error'); // 'error', 'warn', 'log', or 'info'
Quill.register('modules/imageResize', ImageResize);
Quill.register(SmartBreak, true);
Quill.register(ImageWithStyle, true);
Quill.register(ParagraphBlot, true);
Quill.register('modules/clipboard', PlainClipboard, true)

// this is a zero-width-space character
const ZWSP = "​";


type DefaultEditorProps = {
  wrapClassName?: string,
  config?: IEditorConfig,
  value?: string,
  disabled?: boolean,
  onBlur?: () => {},
  onChange?: () => {},
  onError?: () => {},
  onFocus?: () => {},
  plugins?: object[],
  input?: {
    onFocus?: () => {},
    onBlur?: () => {},
    onChange?: () => {},
    value?: '',
  }
};

export type RichEditorProps = DefaultEditorProps;

const CustomEditorWrapper = styled.div<any>`
  width: 100%;

  ${props => {
    let fontsCSS = '';
    let fontSizeCSS = '';
    const token = props.token;
    const fontList = props.fonts;
    if (Array.isArray(fontList) && fontList.length) {
      fontsCSS += `font-family: "${fontList[0].value}"`;

      fontList.forEach(({ value }) => {
        fontsCSS += `
        #toolbar_${token} span[data-label="${value}"]::before {
          font-family: "${value}";
        }

        .ql-font-${value} {
          font-family: "${value}";
        }`
      });
    }

    const sizeList = props.sizes
    if (Array.isArray(sizeList) && sizeList.length) {
      sizeList.forEach((size) => {
        fontsCSS += `
            #toolbar_${token} .ql-size span[data-value="${size}px"]::before {
              font-size: ${size}px !important;
            }`
      })
    }

    if (props.defaultFontSize) {
      fontSizeCSS = `
        p {
          font-size: ${props.defaultFontSize};
        }`
    }

    return css`${fontsCSS}${fontSizeCSS}`;
  }}

  .ql-editor {
    overflow-y: scroll;
    resize: vertical;

    .ql-align-justify {
      white-space: normal;
    }
  }

  .ql-formats {
    float: left;
    margin: 0;
    outline: none;

    &_custom-arrow {
      position: relative;

      .ql-picker {
        z-index: 5;
        margin-right: 5px;
      }

      .ql-custom-down-arrow {
        z-index: 2;
        position: absolute;
        right: 0;
        width: 20px;
        height: 20px;

        path {
          &:nth-child(2) {
            fill: #8f9bb3;
          }
        }
      }

      &:hover {
        .ql-custom-down-arrow {
          path {
            &:nth-child(2) {
              fill: ${colorPalette.palette.primary.dark};
            }
          }
        }
      }
    }

    &_size {
      .ql-picker {
        width: 40px;

        .ql-picker-label {
          width: 100%;

          &:before {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    &_heading {
      .ql-picker {
        width: 100px;

        .ql-picker-label {
          width: 100%;

          &:before {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    &_family {
      .ql-picker.ql-font {
        width: 100px;
        min-width: 100px;

        .ql-picker-label {
          width: 100%;

          &:before {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .ql-picker-options {
    border-radius: 4px;

    .ql-picker-item {
      outline: none;

      &.ql-selected,
      &:hover {
        color: ${colorPalette.palette.primary.dark};
      }
    }
  }

  .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    z-index: 1501;
  }

  .ql-toolbar {
    border: 1px solid #eee;

    .ql-formats {
      float: left;
      height: 32px;

      margin: 0;
      padding: 0;

      background: none;
      border: none;
      cursor: pointer;

      display: inline-flex;
      align-items: center;
      margin-right: 0px;


      &:hover {
        .ql-picker-label, .ql-custom-down-arrow {
          color: ${colorPalette.palette.primary.dark};
        }
      }
    }

  }

  .ql-container {
    border: 1px solid #eee;
    border-top: none;

    &.ql-disabled {
      border-color: transparent;
    }
  }

  .ql-snow .ql-picker-label {
    outline: none;
    border: none;

    &.ql-active {
      color: black;
    }

    svg {
      display: none;
    }
  }

  .ql__custom-toolbar {
    display: block;
    transition: max-height 0.25s ease-in-out, padding-top 0.25s ease-in-out,
      padding-bottom 0.25s ease-in-out;
    max-height: 300px;

    &_disabled {
      max-height: 0;

      padding-top: 0;
      padding-bottom: 0;

      overflow: hidden;

      border-color: transparent;
    }
  }

  .ql-delimiter {
    width: 1px;
    min-width: 1px;
    height: 32px;

    background: ${colorPalette.palette.secondaryLight.main};

    display: inline-block;
    float: left;

    margin: 0 5px 0 9px;
  }

  .ql-editor {
    padding: 15px 30px;
    min-height: var(--ck-sample-rich-editor-min-height);
  }

  .ql-font {
    min-width: 140px;
  }

  .ql-text-color {
    position: relative;

    .ql-color {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .ql-text-background-color {
    position: relative;

    .ql-background {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .ql-tooltip {
    .ql-action {
      &:after {
        border-right: none;
      }
    }

    .ql-remove {
      display: none;
    }
  }

  .ql-snow.ql-toolbar button {
    width: 32px;
    height: 32px;

    border-radius: 5.3px;
    padding: 3px 0px;
    margin: 0px 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;

    &.ql-active,
    &:hover {
      background: ${colorPalette.palette.colors.primary[100]};

      svg {
        path {
          &:nth-child(2) {
            fill: ${colorPalette.palette.primary.dark};
          }
        }
      }
    }
  }
`;
const sahdesToUse = ["300","400","500","600","700","800"];
const colorify = (colorObj, name: string, length?: number) => {
  const result = Object.keys(colorObj)
    .filter((key) => sahdesToUse.includes(key))
    .map((key) => ({ color: colorObj[key], label: name + key }));
  if (length !== undefined) return result.slice(0, length);
  return result;
};

const defaultConfig: IEditorConfig = {
  fontBackgroundColor: {
    colors: [
      {
        color: "transparent",
        label: "Transparent",
      },
      ...colorify(grey, "grey", 5),
      ...colorify(red, "red"),
      ...colorify(blue, "blue"),
      ...colorify(orange, "orange"),
      ...colorify(green, "green"),
      ...colorify(yellow, "yellow")
    ],
    columns: 6,
  },
  fontColor: {
    colors: [
      {
        color: "hsl(0, 0%, 0%)",
        label: "Black",
      },
      {
        color: "hsl(0, 0%, 30%)",
        label: "Dim grey",
      },
      {
        color: "hsl(0, 0%, 60%)",
        label: "Grey",
      },
      {
        color: "hsl(0, 0%, 90%)",
        label: "Light grey",
      },
      {
        color: "#fff",
        label: "White",
      },
    ],
    columns: 3,
  },
  fontFamily: {
    options: [
      {
        label: "Arial",
        value: "Arial",
      },
      {
        label: "Sans Serif",
        value: "sans-serif",
      },
      {
        label: "Georgia",
        value: "Georgia",
      },
      {
        label: "Charcoal",
        value: "Charcoal",
      },
      {
        label: "Tahoma",
        value: "Tahoma",
      },
      {
        label: "Geneva",
        value: "Geneva",
      },
      {
        label: "Verdana",
        value: "Verdana",
      },
    ],
  },
  fontSize: {
    options: [9, 11.5, 12, 14, 16, 18, 22, 24],
    defaultSize: 14,
  },
  heading: {
    options: [
      {
        class: "ck-heading_paragraph",
        model: "paragraph",
        title: "Paragraph",
        view: "paragraph",
      },
      {
        class: "ck-heading_heading1",
        model: "heading1",
        title: "Heading 1",
        view: "h2",
      },
      {
        class: "ck-heading_heading2",
        model: "heading2",
        title: "Heading 2",
        view: "h3",
      },
    ],
  },
  staticLink: {
    defaultText: "Learn Less",
    staticUrl: "https://google.com",
  },
  toolbar: {
    items: [
      "undo",
      "redo",
      "|",
      "heading",
      "|",
      "fontFamilyDropdown",
      "|",
      "fontSizeDropdown",
      "customUpload",
      "|",
      "bold",
      "italic",
      "underline",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "link",
      "imageUpload",
      "|",
      "alignment:left",
      "alignment:center",
      "alignment:right",
      "alignment:justify",
      "|",
      "numberedList",
      "bulletedList",
    ],
  },
};

const capitalize = (lower) => {
  return lower.charAt(0).toUpperCase() + lower.substring(1);
};

const RichEditor = (props): JSX.Element => {
  const { onChange, onFocus, onBlur, onInit, disabled, config, value, wrapClassName, input } = props;

  const getKeyBindings = () => {
    const keyBindings = {
      justifiedTextSpacebarFixForFirefox: {},
      nextLine: {
        key: 13,
        shiftKey: false,
        handler: function (range, context) {
          this["quill"].insertText(range.index, '\n', Quill["sources"].USER);
          this["quill"].setSelection(range.index + (!context.offset ? 2 : 1), Quill["sources"].SILENT);
        }
      },
      linebreak: {
        key: 13,
        shiftKey: true,
        handler: function (range, context) {
          if (context.format.list) {
            let currentLeaf = this["quill"].getLeaf(range.index)[0]
            let nextLeaf = this["quill"].getLeaf(range.index + 1)[0]

            this["quill"].insertEmbed(range.index, 'break', true, Quill["sources"].USER);

            // Insert a second break if:
            // At the end of the editor, OR next leaf has a different parent (<p>)
            if (nextLeaf === null || (currentLeaf.parent !== nextLeaf.parent)) {
              this["quill"].insertEmbed(range.index, 'break', true, Quill["sources"].USER);
            }

            let isUseTrick = false;
            if (currentLeaf.value() === '\n' && nextLeaf && currentLeaf.parent === nextLeaf.parent) {
              const tagName = get(currentLeaf, 'parent.children.head.domNode.tagName', '');
              if (tagName === 'BR') {
                isUseTrick = true;
                const headIndex = this["quill"].getIndex(currentLeaf.parent.children.head);
                this["quill"].insertText(headIndex, ZWSP, Quill["sources"].SILENT);
                this["quill"].insertEmbed(range.index + 1, 'break', true, Quill["sources"].USER);
                this["quill"].insertEmbed(range.index + 2, 'break', true, Quill["sources"].USER);
              }
            }

            // Now that we've inserted a line break, move the cursor forward
            this["quill"].setSelection(range.index + (isUseTrick ? 2 : 1), Quill["sources"].SILENT);

          } else {
            this["quill"].insertText(range.index, '\n', Quill["sources"].USER);
            this["quill"].setSelection(range.index + (!context.offset ? 2 : 1), Quill["sources"].SILENT);
          }
        }
      },
      removeList: {
        key: 'backspace',
        format: ['list'],
        handler: function (range, context) {
          if (context.offset === 0) {
            // When backspace on the first character of a list,
            // remove the list instead
            this["quill"].format('list', false, Quill["sources"].USER);
          } else {
            const nextLeafText = this["quill"].getText(range.index + 1, 1)
            const previousLeaf = this["quill"].getLeaf(range.index - 1)[0] || {};

            if (!nextLeafText || previousLeaf.value() === ZWSP) {
              this["quill"].deleteText(range.index - 1, 1, Quill["sources"].SILENT);
              let currentLeaf = this["quill"].getLeaf(range.index)[0]
              const headIndex = this["quill"].getIndex(currentLeaf.parent.children.head) || 0;

              if (headIndex === range.index - 1) {
                this["quill"].setSelection(range.index - 1, Quill["sources"].SILENT);
                this["quill"].format("list", false, Quill["sources"].USER);
                return false;
              }

              if (previousLeaf.value() !== ZWSP) {
                this["quill"].insertEmbed(range.index - 1, 'break', true, Quill["sources"].SILENT);
                return false;
              }
            }

            // Otherwise propogate to Quill's default
            return true;
          }
        }
      },
      removeCharacter: {
        key: 'backspace',
        handler: function (range, context) {
          if (range.index > 0) {
            const previousLeaf = this["quill"].getLeaf(range.index - 1)[0];
            const isList = context.format.list;
            const tagName = get(previousLeaf, 'domNode.tagName', '');

            if (previousLeaf && tagName === 'BR' && !isList) {
              this["quill"].deleteText(range.index - (range.length ? 0 : 2), range.length ? range.length : 2, Quill["sources"].USER);
              this["quill"].setSelection(range.index - (range.length ? 0 : 2), Quill["sources"].SILENT);

            } else {
              return true;

            }
          } else {
            return true;

          }
        }
      },
      indentList: {
        key: "tab",
        format: ["list"],
        handler: function (range, context) {
          if (context.format.list === "ordered") {
            return false;
          } else {
            if (context.collapsed && context.offset !== 0) return true;
            this["quill"].format('indent', '+1', Quill["sources"].USER);
          }
        }
      },
    };
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    if (isFirefox) {
      keyBindings.justifiedTextSpacebarFixForFirefox = {
        key: ' ',
        handler: (range, context) => {
          if (context.format.align === "justify" && context.prefix.length >= context.offset) {
            // this is the end of the paragraph / line, and user pressed spacebar, quill will ignore this / delete this, so insert an extra space here.
            // Quill.insertText(range.index, ' ', 'user');
            return true;
          }
          return true;
        },
      };
    }

    return keyBindings;
  };

  const [modules, setModules] = React.useState({
    history: {
      delay: 1000
    },
    toolbar: {
      handlers: {},
      syntax: false,
    },
    keyboard: {
      bindings: getKeyBindings(),
    },
    clipboard: {
      matchers: [
        ['BR', lineBreakMatcher]
      ]
    },
  });
  const [formats, setFormats] = React.useState<string[]>([]);
  const [toolbarItemList, setToolbarItemList] = React.useState<any[]>([]);
  const [val, setVal] = React.useState("");
  const [token] = React.useState(Math.random().toString(36).substring(2, 15));
  const [fontList, setFontList] = React.useState<any[]>([]);
  const [sizeList, setSizeList] = React.useState<any[]>([])
  const [defaultFontSize, setDefaultFontSize] = React.useState<string>('12px');
  const [storedConfig, setStoredConfig] = React.useState<any>(null);

  const quillRef: any = React.useRef(null);

  const onChangeHandler = React.useCallback(
    (...data) => {
      if (onChange) {
        onChange(...data);
      }
      if (input && input.onChange) {
        input.onChange(...data);
      }
    },
    [onChange]
  );

  const onFocusHandler = React.useCallback(
    (...data) => {
      if (onFocus) {
        onFocus(...data);
      }
      if (input && input.onFocus) {
        input.onFocus(...data);
      }
    },
    [onFocus]
  );

  const onBlurHandler = React.useCallback(
    (...data) => {
      if (onBlur) {
        onBlur(...data);
      }
      if (input && input.onBlur) {
        input.onBlur();
      }
    },
    [onBlur]
  );

  const onInitHandler = React.useCallback(
    (...data) => {
      if (onInit) {
        onInit(...data);
      }
    },
    [onInit]
  );

  const customEditorEvent = (event, data) => {
    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection() || get(editor, "selection.savedRange", null);

    switch (event) {
      case "undo":
        editor.history.undo();
        break;

      case "redo":
        editor.history.redo();
        break;

      case "insertCustomLink":
        const url = get(config, "staticLink.staticUrl", null) || get(defaultConfig, "staticLink.staticUrl", null);
        if (url && data) {
          const cursorPosition = selection ? selection.index : 0;
          editor.insertText(cursorPosition, data, "link", url);
          editor.setSelection(cursorPosition + data.length);
        }
        break;

      case "insertUserLink":
        if (data != null && data.url && data.text) {
          const cursorPosition = selection ? selection.index : 0;
          editor.insertText(cursorPosition, data.text, "link", data.url);
          editor.setSelection(cursorPosition + data.text.length);
        }
        break;

      case "updateFontColor":
      case "updateFontBackgroundColor":
        if (selection && data) {
          setTimeout(() => {
            editor.formatText(selection.index,
              selection.length,
              event === "updateFontColor" ? {
                color: data
              } : {
                background: data
              });
            editor.format(event === "updateFontColor" ? "color" : "background", data);
          }, 10)
        }
        break;

      default:
    }
  };

  React.useEffect(() => {
    if (!isEqual(storedConfig, config)) {
      const newToolbarItemList: any[] = [];
      const moduleHelperList:(string | object)[] = [];
      const formatList: string[] = ['indent'];
      const newModules = {
        history: {
          delay: 1000
        },
        toolbar: {
          container: `#toolbar_${token}`,
          handlers: {},
          syntax: false,
        },
        keyboard: {
          bindings: getKeyBindings(),
        },
        clipboard: {
          matchers: [
            ['BR', lineBreakMatcher]
          ]
        },
        imageResize: false
      };

      let defaultColor = '';
      let toolbarConfig = defaultConfig;
      if (Array.isArray(config?.toolbar?.items)) {
        toolbarConfig = config;
      }
      const toolbarItems = get(toolbarConfig, "toolbar.items", []);
      toolbarItems.forEach((item) => {
        switch (item) {
          case "undo":
            if (!moduleHelperList.includes("undo")) {
              moduleHelperList.push("undo");
              newModules.toolbar.handlers = {
                ...newModules.toolbar.handlers,
                undo: () => customEditorEvent("undo", null)
              };

              newToolbarItemList.push(
                <Tooltip title="Undo">
                  <IconButton disableRipple={true} className="ql-undo">
                    <Undo />
                  </IconButton>
                </Tooltip>
              );
            }
            break;

          case "redo":
            if (!moduleHelperList.includes("redo")) {
              moduleHelperList.push("redo");
              newModules.toolbar.handlers = {
                ...newModules.toolbar.handlers,
                redo: () => customEditorEvent("redo", null)
              };

              newToolbarItemList.push(
                <Tooltip title="Redo">
                  <IconButton disableRipple={true} className="ql-redo">
                    <Redo />
                  </IconButton>
                </Tooltip>
              );
            }
            break;

          case "heading":
            if (!moduleHelperList.includes("header")) {
              moduleHelperList.push("header");

              const headerPropValues = get(toolbarConfig, "heading.options", []);
              const headerDefaultValues = get(defaultConfig, "heading.options", []);
              const headerValues = headerPropValues || headerDefaultValues;

              newToolbarItemList.push(
                <ControlledTooltip title="Heading">
                  {(ref) => (
                    <span
                      ref={ref}
                      className="ql-formats ql-formats_custom-arrow ql-formats_heading"
                    >
                      <select
                        className="ql-header"
                        defaultValue={
                          headerValues.length
                            ? headerValues[0].view.replace(/\D/g, "")
                            : ""
                        }
                      >
                        {headerValues.map(({ view, title }, key) => (
                          <option key={key} value={view.replace(/\D/g, "")}>
                            {title}
                          </option>
                        ))}
                      </select>
                      <ExpandMoreIcon className={"ql-custom-down-arrow"} />
                    </span>
                  )}
                </ControlledTooltip>,
              );
            }

            if (!formatList.includes("header")) {
              formatList.push("header");
            }
            break;

          case "fontFamily":
          case "fontFamilyDropdown":
            if (!moduleHelperList.includes("font")) {
              moduleHelperList.push("font");

              const defaultFontFamilyValues = get(defaultConfig, "fontFamily.options", []);
              const propsFontFamilyValues = get(toolbarConfig, "fontFamily.options", []);
              const fontFamilyValues = propsFontFamilyValues || defaultFontFamilyValues;
              setFontList([...fontFamilyValues]);

              // const Font = Quill.import("formats/font");
              // Font.whitelist = [];
              // fontFamilyValues.forEach((font) => {
              //   Font.whitelist.push(font.value);
              // });
              // Quill.register(Font, true);
              const Font = Quill.import('attributors/style/font');
              delete Font.whitelist;
              Quill.register(Font);

              newToolbarItemList.push(
                <ControlledTooltip title="Font">
                  {(ref) => (
                    <span
                      ref={ref}
                      className={
                        "ql-formats ql-formats_custom-arrow ql-formats_family"
                      }
                    >
                      <select
                        className="ql-font"
                        defaultValue={
                          fontFamilyValues.length
                            ? fontFamilyValues[0].value
                            : ""
                        }
                      >
                        {fontFamilyValues.map(({ label, value }, key) => (
                          <option key={key} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                      <ExpandMoreIcon className={"ql-custom-down-arrow"} />
                    </span>
                  )}
                </ControlledTooltip>,
              );
            }

            if (!formatList.includes("font")) {
              formatList.push("font");
            }
            break;

          case "fontSize":
          case "fontSizeDropdown":
            if (!moduleHelperList.includes("size")) {
              moduleHelperList.push("size");

              const defaultFontSizeOptions = get(defaultConfig, "fontSize.options", []);
              const propsFontSizeOptions = get(toolbarConfig, "fontSize.options", []);

              const defaultFontSizeValue = get(defaultConfig, "fontSize.defaultSize", []);
              const propsFontSizeValue = get(toolbarConfig, "fontSize.defaultSize", []);

              const fontSizeOptions = propsFontSizeOptions || defaultFontSizeOptions;
              const fontSizeValue =  propsFontSizeValue || defaultFontSizeValue;

              const initFontSize = fontSizeOptions?.includes(fontSizeValue) ? `${fontSizeValue}px` : `${fontSizeOptions[0]}px`
              setDefaultFontSize(initFontSize);
              setSizeList(fontSizeOptions)

              const Size = Quill.import("attributors/style/size");
              Size.whitelist = [];
              fontSizeOptions.forEach((size) => {
                Size.whitelist.push(`${size}px`);
              });
              Quill.register(Size, true);

              newToolbarItemList.push(
                <ControlledTooltip title="Font size">
                  {(ref) => (
                    <span
                      ref={ref}
                      className={
                        "ql-formats ql-formats_custom-arrow ql-formats_size"
                      }
                    >
                      <select className="ql-size" defaultValue={initFontSize}>
                        {fontSizeOptions.map((i, key) => (
                          <option key={key} value={`${i}px`}>
                            {i}
                          </option>
                        ))}
                      </select>
                      <ExpandMoreIcon className={"ql-custom-down-arrow"} />
                    </span>
                  )}
                </ControlledTooltip>
              );
            }

            if (!formatList.includes("size")) {
              formatList.push("size");
            }
            break;

          case "bold":
          case "italic":
          case "underline":
            if (!moduleHelperList.includes(item)) {
              moduleHelperList.push(item);
              newToolbarItemList.push(
                <Tooltip title={capitalize(item)}>
                  <IconButton disableRipple={true} className={`ql-${item}`}>
                    {item === "bold" && <Bold />}
                    {item === "italic" && <Italic />}
                    {item === "underline" && <Underline />}
                  </IconButton>
                </Tooltip>
              );
            }

            if (!formatList.includes(item)) {
              formatList.push(item);
            }
            break;

          case "fontColor":
            if (!moduleHelperList.includes("color")) {
              moduleHelperList.push("color");

              let colorList: IColor[] | undefined = defaultConfig?.fontColor?.colors;
              let columnCount = 3;
              if (
                toolbarConfig.fontColor &&
                toolbarConfig.fontColor.colors &&
                Array.isArray(toolbarConfig.fontColor.colors)
              ) {
                colorList = toolbarConfig.fontColor.colors;
              }

              if (toolbarConfig.fontColor && toolbarConfig.fontColor.columns) {
                columnCount = toolbarConfig.fontColor.columns;
              }

              if (colorList && colorList.length) {
                defaultColor = colorList[0].color;
              }

              newToolbarItemList.push(
                <ColorPicker
                  tooltip={"Font Color"}
                  icon={"font color"}
                  colorList={colorList}
                  columnCount={columnCount}
                  onSelectColor={(color:string) =>
                    customEditorEvent("updateFontColor", color)
                  }
                />
              );
            }

            if (!formatList.includes("color")) {
              formatList.push("color");
            }
            break;

          case "fontBackgroundColor":
            if (!moduleHelperList.includes("background")) {
              moduleHelperList.push("background");

              let colorList: IColor[] | undefined = defaultConfig?.fontBackgroundColor?.colors;
              if (
                toolbarConfig.fontBackgroundColor &&
                toolbarConfig.fontBackgroundColor.colors &&
                Array.isArray(toolbarConfig.fontBackgroundColor.colors)
              ) {
                colorList = toolbarConfig.fontBackgroundColor.colors;
              }

              let columnCount =
                toolbarConfig?.fontBackgroundColor?.columns ??
                defaultConfig?.fontBackgroundColor?.columns;
                newToolbarItemList.push(
                <ColorPicker
                  tooltip={"Font Background Color"}
                  icon={"font background color"}
                  colorList={colorList}
                  columnCount={columnCount}
                  onSelectColor={(color) =>
                    customEditorEvent("updateFontBackgroundColor", color)
                  }
                />
              );
            }

            if (!formatList.includes("background")) {
              formatList.push("background");
            }
            break;

          case "insertStaticLink":
            if (!moduleHelperList.includes(item)) {
              moduleHelperList.push(item);

              const link = get(toolbarConfig, "staticLink.staticUrl", null) || get(defaultConfig, "staticLink.staticUrl", null);
              const text = get(toolbarConfig, "staticLink.defaultText", null) || get(defaultConfig, "staticLink.defaultText", null);

              newToolbarItemList.push(
                <CustomLink
                  link={link}
                  text={text}
                  onInsertLink={(t) => customEditorEvent("insertCustomLink", t)}
                />
              );
            }

            if (!formatList.includes(item)) {
              formatList.push(item);
            }
            break;

          case "link":
            if (!moduleHelperList.includes(item)) {
              moduleHelperList.push(item);

              newToolbarItemList.push(
                <CustomLink
                  link={""}
                  text={""}
                  onInsertLink={(linkInfo) =>
                    customEditorEvent("insertUserLink", linkInfo)
                  }
                />
              );
            }

            if (!formatList.includes(item)) {
              formatList.push(item);
            }
            break;

          case "imageUpload":
            if (!moduleHelperList.includes("image")) {
              moduleHelperList.push("image");
              newToolbarItemList.push(
                <Tooltip title="Insert image">
                  <IconButton disableRipple={true} className="ql-image">
                    <Upload />
                  </IconButton>
                </Tooltip>
              );

              Quill.register('modules/imageResize', ImageResize); // register globally for QuillJS

              if (!newModules.imageResize) {
                newModules.imageResize = true;
                // newModules.imageResize = {
                //   // parchment: Quill.import('parchment')
                //   // modules: ['Resize', 'DisplaySize', 'Toolbar']
                // };
              }
            }

            if (!formatList.includes("image")) {
              formatList.push("image");
            }
            break;

          case "alignment:left":
          case "alignment:center":
          case "alignment:right":
          case "alignment:justify":
          {
            const direction = item.replace("alignment:", "");
            const block = { align: direction === "left" ? null : direction };

            if (!moduleHelperList.includes(block)) {
              moduleHelperList.push(block);

              newToolbarItemList.push(
                <Tooltip title={`Align ${direction}`}>
                  <IconButton
                    className="ql-align"
                    value={direction !== "left" ? direction : ""}
                  >
                    {direction === "left" && <AlignmentLeft />}
                    {direction === "center" && <AlignmentCenter />}
                    {direction === "right" && <AlignmentRight />}
                    {direction === "justify" && <AlignmentJustify />}
                  </IconButton>
                </Tooltip>
              );
            }

            if (!formatList.includes("align")) {
              formatList.push("align");
            }
          }
            break;

          case "numberedList":
          case "bulletedList":
            if (item === "numberedList") {
              if (!moduleHelperList.includes({ list: "ordered" })) {
                moduleHelperList.push({ list: "ordered" });
                newToolbarItemList.push(
                  <Tooltip title="Numbered list">
                    <IconButton disableRipple={true} className="ql-list" value="ordered">
                      <ListNumber />
                    </IconButton>
                  </Tooltip>
                );
              }
            }

            if (item === "bulletedList") {
              if (!moduleHelperList.includes({ list: "bullet" })) {
                moduleHelperList.push({ list: "bullet" });
                newToolbarItemList.push(
                  <Tooltip title="Bulleted list">
                    <IconButton disableRipple={true} className="ql-list" value="bullet">
                      <ListBullet />
                    </IconButton>
                  </Tooltip>
                );
              }
            }

            if (!formatList.includes("list")) {
              formatList.push("list");
            }
            break;

          case "|":
            newToolbarItemList.push(<span className="ql-delimiter" />);
            break;

          default:
        }
      });

      setModules({...newModules});
      setFormats(formatList);
      setToolbarItemList(newToolbarItemList);
      setStoredConfig({ ...config });
      if (value || (input && input.value)) {
        let fixedVal = value || input.value
        fixedVal = fixedVal
          .replace(/<\D*><br><[^<]*/gm, '')
          .replace(/><br><\/span>/g, '></span>')

        setTimeout(() => {
          setVal(fixedVal);
        }, 15);
      }

      if (defaultColor) {
        setTimeout(() => {
          customEditorEvent('updateFontColor', defaultColor)
        }, 15)
      }

      onInitHandler(true);
    }
  },              [config]);

  const onChangeContentEvent = (content, delta, source, editor) => {
    setVal(content);
    // onChangeHandler(content);

    onChangeHandler("customEvent", {
      ...editor,
      textData: extractContent(content),
      getData: () => content
    });
  };

  const onChangeSelection = (range, source, editor) => {
    // console.log("RANGE CHANGED: ", range);
  }

  const onFocusEvent = (range, source, editor) => {
    onFocusHandler(range, source, editor);
  };

  const onBlurEvent = (previousRange, source, editor) => {
    onBlurHandler(previousRange, source, editor);
  };

  return (
    <CustomEditorWrapper
      className={`${wrapClassName} rich-editor`}
      fonts={fontList}
      sizes={sizeList}
      defaultFontSize={defaultFontSize}
      token={token}>
      <CustomToolbar
        toolbarItemList={toolbarItemList}
        token={token}
        disabled={disabled}
      />
      <ReactQuill
        ref={quillRef}
        theme="snow"
        readOnly={disabled}
        value={val}
        onChange={onChangeContentEvent}
        onChangeSelection={onChangeSelection}
        onFocus={onFocusEvent}
        onBlur={onBlurEvent}
        modules={modules}
        // formats={formats}
      />
    </CustomEditorWrapper>
  );
};

RichEditor.defaultProps = {
  wrapClassName: undefined,
  config: {},
  value: '',
  disabled: false,
  onBlur: undefined,
  onChange: undefined,
  onError: undefined,
  onFocus: undefined,
  plugins: []
};

export default RichEditor;
