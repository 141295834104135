import React from "react";

import AddItemIcon from "assets/icons/add-item.svg";
import styled, { css } from "styled-components";

// ${props => props.theme.palette.tertiary.border}

const Adder = styled(({ adderStyle, ...rest }) => <div {...rest} />)`
  display: flex;
  color: ${props => props.theme.palette.infoInvert.contrastText};
  cursor: pointer;
  font-size: 13px;
  svg {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
  ${props =>
    css`
      ${props.adderStyle ? props.adderStyle(props) : ""}
    `}
`;

export type AddItemProps = {
  adderStyle?: Function;
  handleAdd?: (e: React.MouseEvent<HTMLDivElement>) => void;
  label: string;
  type: "row" | "column" | "option";
};
export default ({
  type,
  label,
  handleAdd,
  ...rest
}: Readonly<AddItemProps>): JSX.Element => (
  <Adder onClick={handleAdd} {...rest}>
    <AddItemIcon />
    <div>{label}</div>
  </Adder>
);
