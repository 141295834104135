import React from "react";

import Badge from "@material-ui/core/Badge";
import Collapse from "@material-ui/core/Collapse";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import MUIListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useTheme } from "@material-ui/core/styles";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ExpandMore from "@material-ui/icons/ExpandMore";
import styled from "styled-components";

import { IExtendedTheme } from "../../themes/default";

const DrawerWrapper = styled.div`
  .MuiPaper-root.MuiDrawer-paper {
    background-color: ${props => props.theme.palette.secondary.main};
    color: ${props => props.theme.palette.colors.basic[600]};
    width: 270px;
  }
`;

const HeaderWrapper = styled.div`
  .header {
    background-color: ${props => props.theme.palette.drawer.foreground};
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80px;
    font-size: 24px;

    .content {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      & * {
        margin: 0 0.15em;
      }
    }
  }
`;

const ListItemWrapper = styled(MUIListItem)<{
  subItem?: boolean;
}>`
  padding-top: 1em;
  padding-bottom: 1em;
  ${props => (props.subItem ? "padding-left: 35px" : "")};

  &:hover {
    background-color: ${props => props.theme.palette.drawer.foreground};
  }
  &.Mui-selected {
    background-color: ${props => props.theme.palette.drawer.foreground};
    color: ${props => props.theme.palette.colors.basic[100]};
    &:hover {
      background-color: ${props => props.theme.palette.drawer.foreground};
    }
  }

  .MuiBadge-badge {
    background-color: ${props => props.theme.palette.colors.danger[500]};
    color: ${props => props.theme.palette.colors.basic[100]};
    font-size: 14px;
  }

  .right-icon {
    transform: translateX(55%);
  }

  .label .MuiTypography-root {
    font-size: 14px;
  }
`;

const Dash = styled.span`
  display: inline-block;
  width: 10px;
  height: 1px;
  background-color: ${props => props.theme.palette.colors.basic[600]};
`;

const Header = props => {
  return (
    <HeaderWrapper>
      <div className="header">
        <div className="content">{props.children}</div>
        {props.Icon && <div className="icon">{props.Icon}</div>}
      </div>
    </HeaderWrapper>
  );
};

const CustomListIcon = styled(ListItemIcon)<{ isDash: boolean }>`
  ${({ isDash }) => (isDash ? "padding-left: 8px" : "")};
  ${({ isDash }) => (isDash ? "min-width: 45px" : "")};
`;

export interface ListItemProps {
  Icon?: null | React.ElementType;
  IconRight?: null | React.ElementType;
  notifications?: number;
  selected?: boolean;
  subItem?: boolean;
  text: string;
  onClick?(): void;
}
export const ListItem = ({
  onClick,
  subItem,
  selected,
  ...props
}: ListItemProps) => {
  const theme = useTheme() as IExtendedTheme;
  return (
    <ListItemWrapper
      classes={{ selected: "list-item-selected" }}
      onClick={onClick}
      button={true}
      disableRipple={true}
      subItem={subItem}
      selected={selected}
    >
      {props.Icon !== null ? (
        <CustomListIcon isDash={props.Icon === undefined}>
          {props.Icon === undefined ? (
            <Dash />
          ) : (
            <props.Icon htmlColor="white" />
          )}
        </CustomListIcon>
      ) : null}
      <ListItemText className="label" primary={props.text} />
      <Badge badgeContent={props.notifications} />

      {props.IconRight ? (
        <props.IconRight
          className="right-icon"
          htmlColor={theme.palette.colors.basic[600]}
        />
      ) : null}
    </ListItemWrapper>
  );
};

export interface ExpandableListItemProps {
  children: any;
  Icon?: null | React.ElementType;
  openByDefault?: boolean;
  text: string;
}
export const ExpandableListItem = props => {
  const [expanded, expandListItem] = React.useState(props.openByDefault);
  return (
    <>
      <ListItem
        subItem={false}
        onClick={() => expandListItem(!expanded)}
        notifications={1}
        text={props.text}
        IconRight={expanded ? ExpandMore : ChevronRight}
        Icon={props.Icon}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit={true}>
        <List component="div" disablePadding={true}>
          {props.children}
        </List>
      </Collapse>
    </>
  );
};

export interface TransformDrawerProps {
  children: any;
  HeaderIcon?: null | React.ElementType;
  headerText: string;
}

export default ({ headerText, HeaderIcon, children }: TransformDrawerProps) => {
  return (
    <DrawerWrapper>
      <Drawer variant="persistent" anchor="left" open={true}>
        <Header>
          {HeaderIcon ? <HeaderIcon /> : null}
          <span>{headerText}</span>
        </Header>
        <List>{children}</List>
      </Drawer>
    </DrawerWrapper>
  );
};
