import React from "react";

import Slider, {
  SliderProps as MuiSliderProps,
} from "@material-ui/core/Slider";
import styled from "styled-components";

const StyledSlider = styled(Slider)`
  margin-top: 30px;

  & .MuiSlider-thumb {
    background: ${props => props.theme.palette.quaternary.main};
  }

  & .MuiSlider-rail {
    background: #e4e9f2;
  }

  & .MuiSlider-track {
    background: ${props => props.theme.palette.infoLight.main};
  }

  & .MuiSlider-valueLabel {
    color: ${props => props.theme.palette.quaternary.main};
  }
`;

export type SliderProps = MuiSliderProps & {};

export default ({ onChange, ...rest }: SliderProps) => {
  const handleChange: any = React.useCallback(
    (e, v) => onChange && onChange(v),
    [onChange],
  );

  return (
    <StyledSlider
      {...rest}
      onChange={handleChange}
      valueLabelDisplay="auto"
      aria-labelledby="range-slider"
    />
  );
};
