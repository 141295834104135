import React from "react";

import Grid, { GridProps } from "@material-ui/core/Grid";
import styled from "styled-components";

type FormColumnProps = GridProps & { customStyle?: string };

export default styled(
  ({ customStyle, ...props }: FormColumnProps): JSX.Element => (
    <Grid classes={{ root: "form-column" }} item {...props} />
  ),
)`
  ${props => props.customStyle};
`;
