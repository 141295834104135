import React from "react";

import styled from "styled-components";

export type BasePreviewProps = {
  className?: string;
  InputIcon: any;
  main: any;
  reverse?: boolean;
  sub: string;
};

const BasePreview = styled(
  ({
    className,
    InputIcon,
    main,
    sub,
    reverse = false,
  }: BasePreviewProps): JSX.Element => (
    /* if(React.isValidElement(main))
    main = <main/>
  if(React.isValidElement(sub))
    sub = <sub/> */
    <div className={className}>
      <InputIcon className="input-icon" fontSize={30} width={30} height={30} />
      <div className="input-text-block">
        {reverse && <span className="sub-text">{sub}</span>}
        <span>{main}</span>
        {!reverse && <span className="sub-text">{sub}</span>}
      </div>
    </div>
  ),
)<BasePreviewProps & {}>`
  display: flex;
  font-size: 15px;
  align-items: ${props => (props.reverse ? "flex-start" : "flex-end")};
  & .input-icon path[fill] {
    fill: ${props => props.theme.palette.tertiary.border};
  }
  & .input-text-block {
    margin-left: 10px;
    display: flex;
    flex-direction: column;

    & .sub-text {
      color: ${props => props.theme.palette.infoInvert.contrastText};
      font-size: 12px;
    }
  }
`;

export default BasePreview;
