import { Quill } from "react-quill";

const Delta = Quill.import("delta");
const Break = Quill.import("blots/break");
const Embed = Quill.import("blots/embed");

export const lineBreakMatcher = () => {
  const newDelta = new Delta();
  newDelta.insert({ break: "" });
  return newDelta;
};

class SmartBreak extends Break {
  length = () => {
    return 1;
  };

  value = () => {
    return "\n";
  };

  insertInto = (parent, ref) => {
    Embed.prototype.insertInto.call(this, parent, ref);
  };
}
SmartBreak.blotName = "break";
SmartBreak.tagName = "BR";

export default SmartBreak;
