import React, { forwardRef, useCallback, useImperativeHandle } from "react";

import LinearProgress from "@material-ui/core/LinearProgress";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { getImageUrl } from "utils";

import { IExtendedTheme } from "../../themes/default";

type BaseImageProps = {
  theme: IExtendedTheme;
};

type ImageWrapperProps = BaseImageProps & {
  hasImage?: boolean;
  height?: string;
  width?: string;
};

const ImageWrapper = styled.div<ImageWrapperProps>`
  width: ${props =>
    props.width || props.theme.sizes.imagePickerDefaultSize.width};
  height: ${props => {
    if (props.height) {
      return props.height;
    }

    return props.hasImage
      ? "100%"
      : props.theme.sizes.imagePickerDefaultSize.height;
  }};
  border-radius: 4px;
  background: ${(props: BaseImageProps) =>
    props.theme.palette.imagePickerBackgroundColor};

  img {
    width: 100%;
    height: 100%;
  }
`;

const PhotoPickerWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const PhotoUploadProgress = styled(LinearProgress)<BaseImageProps>`
  position: absolute;
  bottom: 0;
  background-color: transparent;
  width: 100%;
  & .MuiLinearProgress-bar1Determinate {
    background-color: ${props => props.theme.palette.info.main};
  }
`;

type ImageProps = {
  alt?: string;
  src: string;
};

const Image = ({ src, alt }: ImageProps): JSX.Element => (
  <img src={src} alt={alt} />
);

export type PhotoPickerProps = ImageWrapperProps & {
  children: React.ReactNode;
  initImage: any;
  name?: string;
  onFileChange: (f: File[] | null) => void;
  uploadProgress?: number;
};

export default forwardRef(
  (
    {
      initImage,
      onFileChange,
      children,
      uploadProgress,
      theme,
      name,
      height,
      width,
    }: PhotoPickerProps,
    ref: React.Ref<any>,
  ): JSX.Element => {
    const image = getImageUrl(initImage);
    const [currentUrl, setCurrentUrl] = React.useState(image);

    const onFileDrop = useCallback(files => {
      setCurrentUrl(URL.createObjectURL(files[0]));
      onFileChange(files);
    }, []);

    const { getRootProps, getInputProps, open } = useDropzone({
      accept: ["image/png", "image/jpeg", "image/svg+xml"],
      multiple: false,
      onDrop: onFileDrop,
    });

    const removeImage = () => {
      setCurrentUrl(null);
      onFileChange(null);
    };

    useImperativeHandle(ref, () => ({
      openFileWindow: () => open(),
      removeCurrentImage: () => removeImage(),
    }));

    return (
      <PhotoPickerWrapper>
        <div {...getRootProps()}>
          <ImageWrapper
            height={height}
            width={width}
            hasImage={!!(currentUrl || initImage)}
          >
            <Image src={(currentUrl as string) || initImage} />
          </ImageWrapper>

          <input name={name} {...getInputProps()} />
          {!!uploadProgress && (
            <PhotoUploadProgress variant="determinate" value={uploadProgress} />
          )}
        </div>
      </PhotoPickerWrapper>
    );
  },
);
