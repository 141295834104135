import AgreementsActiveIcon from "assets/icons/agreements-active.svg";
import AgreementsIcon from "assets/icons/agreements.svg";
import AppsActive from "assets/icons/apps-active.svg";
import Apps from "assets/icons/apps.svg";
import BallIconActive from "assets/icons/bell-active.svg";
import BallIcon from "assets/icons/bell.svg";
import CompanyBillingIcon from "assets/icons/billing.svg";
import CompanyBillingActiveIcon from "assets/icons/billing.svg";
import CompanyDetailsActiveIcon from "assets/icons/company-details-active.svg";
import CompanyDetailsIcon from "assets/icons/company-details.svg";
import CompanyProjectsActiveIcon from "assets/icons/company-projects-active.svg";
import CompanyProjectsIcon from "assets/icons/company-projects.svg";
import CompanyTeamsActiveIcon from "assets/icons/company-team-active.svg";
import CompanyTeamsIcon from "assets/icons/company-team.svg";
import DevicesActiveIcon from "assets/icons/devices-active.svg";
import DevicesIcon from "assets/icons/devices.svg";
import DistributionActiveIcon from "assets/icons/distribution-active.svg";
import DistributionIcon from "assets/icons/distribution.svg";
import DocumentsActiveIcon from "assets/icons/documents-active.svg";
import DocumentsIcon from "assets/icons/documents.svg";
import FocusGroupActiveIcon from "assets/icons/focusgroup-active.svg";
import FocusGroupIcon from "assets/icons/focusgroup.svg";
import HomeActiveIcon from "assets/icons/home-active.svg";
import HomeIcon from "assets/icons/home.svg";
import MilestonesActiveIcon from "assets/icons/milestones-active.svg";
import MilestonesIcon from "assets/icons/milestones.svg";
import NoticeActiveIcon from "assets/icons/notice-active.svg";
import NoticeIcon from "assets/icons/notice.svg";
import OpportunitiesActiveIcon from "assets/icons/opportunities-active.svg";
import PlanActiveIcon from "assets/icons/plan-active.svg";
import PlanIcon from "assets/icons/plan.svg";
import ProductsActiveIcon from "assets/icons/products-active.svg";
import ProductsIcon from "assets/icons/products.svg";
import QuestionBankActiveIcon from "assets/icons/questionBank-active.svg";
import QuestionBankIcon from "assets/icons/questionBank.svg";
import SettingActiveIcon from "assets/icons/setting-active.svg";
import SettingIcon from "assets/icons/setting.svg";
import SurveysActiveIcon from "assets/icons/surveys-active.svg";
import SurveysIcon from "assets/icons/surveys.svg";
import TeamActiveIcon from "assets/icons/team-active.svg";
import TeamIcon from "assets/icons/team.svg";
import TesterActiveIcon from "assets/icons/tester-active.svg";
import TesterIcon from "assets/icons/tester.svg";
import WideSurveysActiveIcon from "assets/icons/wide-survey-active.svg";

export default {
  agreements: {
    active: AgreementsActiveIcon,
    default: AgreementsIcon,
  },
  apps: {
    active: AppsActive,
    default: Apps,
  },
  billing: {
    active: CompanyBillingActiveIcon,
    default: CompanyBillingIcon,
  },
  companyTeam: { default: CompanyTeamsIcon, active: CompanyTeamsActiveIcon },
  companyWideSurvey: {
    active: WideSurveysActiveIcon,
    default: SurveysIcon,
  },
  details: {
    active: CompanyDetailsActiveIcon,
    default: CompanyDetailsIcon,
  },
  devices: {
    active: DevicesActiveIcon,
    default: DevicesIcon,
  },
  distribution: {
    active: DistributionActiveIcon,
    default: DistributionIcon,
  },
  documents: {
    active: DocumentsActiveIcon,
    default: DocumentsIcon,
  },
  focusGroups: {
    active: FocusGroupActiveIcon,
    default: FocusGroupIcon,
  },
  home: { default: HomeIcon, active: HomeActiveIcon },
  milestones: {
    active: MilestonesActiveIcon,
    default: MilestonesIcon,
  },
  notifications: {
    active: BallIconActive,
    default: BallIcon,
  },
  opportunities: {
    active: OpportunitiesActiveIcon,
    default: CompanyDetailsIcon,
  },
  plan: { default: PlanIcon, active: PlanActiveIcon },
  products: {
    active: ProductsActiveIcon,
    default: ProductsIcon,
  },
  projects: {
    active: CompanyProjectsActiveIcon,
    default: CompanyProjectsIcon,
  },
  questionBank: { default: QuestionBankIcon, active: QuestionBankActiveIcon },
  setting: { default: SettingIcon, active: SettingActiveIcon },
  surveys: {
    active: SurveysActiveIcon,
    default: SurveysIcon,
  },
  team: { default: TeamIcon, active: TeamActiveIcon },
  testerManagement: { default: TesterIcon, active: TesterActiveIcon },
  testerSurveys: {
    active: NoticeActiveIcon,
    default: NoticeIcon,
  },
};
