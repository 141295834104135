import DateFnsUtils from "@date-io/date-fns";
import {
  DatePicker,
  DateTimePicker,
  DatePickerProps as MuiDatePickerProps,
  DateTimePickerProps as MuiDateTimePickerProps,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import CalenderIcon from "assets/icons/calender.svg";

import "date-fns";
import React, { FC } from "react";

import styled from "styled-components";
enum PICKER_TYPE {
  DATE = "datePicker",
  DATE_TIME = "dateTimePicker",
}
type OutType = FC<MuiDatePickerProps | MuiDateTimePickerProps>;
export type DatepickerProps = (MuiDatePickerProps | MuiDateTimePickerProps) & {
  error?: Error;
  id?: string | number;
  name: string;
  ref?: React.Ref<OutType>;
  type: PICKER_TYPE;
};
const DefaultDatePicker = React.forwardRef<OutType, DatepickerProps>(
  (props, ref) => {
    const { type, ...rest } = props;
    const PickerComponent =
      type === PICKER_TYPE.DATE_TIME ? DateTimePicker : DatePicker;
    return <PickerComponent ref={ref} {...(rest as any)} />;
  },
);
const StyledDatePicker = styled.div`
  position: relative;
  padding: 11px;
  width: 100%;
  text-align: left;
  line-height: 1;
  svg {
    //padding-bottom: 2px;
    path[fill] {
      fill: #c5cee0;
    }
  }
`;
const StyledDatePickerInputContainer = styled.div`
  position: absolute;
  top: 0;
  left: -4px;
  width: 100%;
`;
const StyledDatePickerInput = styled(DefaultDatePicker)`
  .MuiInputBase-input {
    padding-left: 48px;
    padding-top: 14px;
    cursor: pointer;
    order: 2;
  }

  .MuiInput-underline,
  .MuiFilledInput-underline {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &::before,
    &::after {
      border-bottom: none;
    }

    &:hover {
      &::before {
        border-bottom: none;
      }
    }
  }

  .MuiButtonBase-root {
    margin-left: -5px;
  }

  .MuiInputLabel-outlined {
    transform: translate(52px, 16px) scale(1);
  }
`;
const Datepicker = ({
  id,
  type = PICKER_TYPE.DATE,
  format = "dd MMMM yyyy",
  error,
  disableToolbar = true,
  ...restProps
}: Readonly<DatepickerProps>): JSX.Element => {
  return (
    <StyledDatePicker>
      <CalenderIcon />
      <StyledDatePickerInputContainer>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <StyledDatePickerInput
            id={id}
            type={PICKER_TYPE[type]}
            autoOk={true}
            helperText={(error || {}).message}
            disableToolbar={disableToolbar}
            inputVariant="outlined"
            variant="inline"
            format={format}
            {...restProps}
          />
        </MuiPickersUtilsProvider>
      </StyledDatePickerInputContainer>
    </StyledDatePicker>
  );
};
export { PICKER_TYPE };
export default Datepicker;
