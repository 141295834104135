import React from "react";

import styled from "styled-components";

const ActivityIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const VisualWrapper = styled.div`
  margin-right: 20px;
  min-height: 50px !important;
  flex: 2;
`;

const ActivityDescriptionWrapper = styled.div`
  flex: 10;
  .main {
    font-size: 15px;
    font-weight: bold;
  }

  .description {
    color: ${props => props.theme.palette.text.secondary};
    font-size: 12px;
  }
`;

type ActivityDescriptionProps = {
  description: string | React.ReactNode;
  main: React.ReactNode;
};
const ActivityDescription = ({
  main,
  description,
}: ActivityDescriptionProps): JSX.Element => (
  <ActivityDescriptionWrapper>
    <div className="main">{main}</div>
    <div className="description">{description}</div>
  </ActivityDescriptionWrapper>
);

const classNamePrefix = "explorer-activity-indicator";

export type ActivityIndicatorProps = {
  description: string | React.ReactNode;
  main: string | React.ReactNode;
  visual: string | React.ReactNode;
};

export default ({
  visual,
  main,
  description,
}: ActivityIndicatorProps): JSX.Element => (
  <ActivityIndicatorWrapper className={classNamePrefix}>
    <VisualWrapper className={`${classNamePrefix} visual`}>
      {visual}
    </VisualWrapper>
    <ActivityDescription main={main} description={description} />
  </ActivityIndicatorWrapper>
);
