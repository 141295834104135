import React from "react";

import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import styled from "styled-components";

export type TimePickerProps = {
  defaultValue?: any;
  label: string;
  onChange: (
    date: MaterialUiPickersDate,
    value?: string | null | undefined,
  ) => void;
  placeholder: string;
  value: any;
};

const TimeInputWrapper = styled.div`
  .MuiInputBase-root {
    display: flex;
    flex-direction: row-reverse;
  }

  input.MuiInputBase-input {
    padding-left: 0px;
  }
`;

export default ({
  label,
  placeholder,
  defaultValue,
  value,
  onChange,
}: TimePickerProps): JSX.Element => {
  return (
    <TimeInputWrapper>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          variant="dialog"
          inputVariant="outlined"
          label={label}
          placeholder={placeholder}
          InputLabelProps={{ shrink: true }}
          // There are zero width characters in the mask and maskCharProp
          // two before colon, two after colon, and one after space before M.
          mask="​​:​​ ​M"
          maskChar="​"
          initialFocusedDate={defaultValue}
          value={value}
          onChange={onChange}
          keyboardIcon={<WatchLaterIcon />}
        />
      </MuiPickersUtilsProvider>
    </TimeInputWrapper>
  );
};
