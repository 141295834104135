import React from "react";

import VoteDownIcon from "assets/icons/down.svg";
import VoteUpIcon from "assets/icons/up.svg";
import styled from "styled-components";

const VoteWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  .value {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.23;
    color: #8f9bb3;

    margin: 0;
  }

  .icon {
    path {
      &:last-child {
        fill: #8f9bb3;
      }
    }

    &_disabled {
      path {
        &:last-child {
          fill: #c5cee0;
        }
      }
    }
  }
`;

export type VoteProps = {
  direction?: "up" | "down";
  handleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  showValue?: boolean;
  value?: number;
  wrapClassName: string;
};

export default ({
  direction = "up",
  value = 0,
  showValue = true,
  handleClick,
  wrapClassName,
}: Readonly<VoteProps>): JSX.Element => {
  const onClickHandler = React.useCallback(
    event => {
      if (handleClick) {
        handleClick(event);
      }
    },
    [handleClick],
  );

  return (
    <VoteWrapper
      onClick={onClickHandler}
      className={wrapClassName ? wrapClassName : ""}
    >
      {!!showValue && <p className={"value"}>{value}</p>}
      {direction === "up" ? (
        <VoteUpIcon className={`icon ${!value ? "icon_disabled" : ""}`} />
      ) : (
        <VoteDownIcon className={`icon ${!value ? "icon_disabled" : ""}`} />
      )}
    </VoteWrapper>
  );
};
