import React from "react";

import { Theme } from "@material-ui/core";

import CommentIcon from "assets/icons/comment-alt.svg";
import DownIcon from "assets/icons/down.svg";
import PersonAddIcon from "assets/icons/person-add.svg";
import UpIcon from "assets/icons/up.svg";
import styled from "styled-components";

const ActivityItemWrapper = styled.div`
  height: 80px;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f3f8;

  .explorer-activity-item-description {
    padding: 15px;
    flex-grow: 1;

    .title {
      font-size: 13px;
      margin-top: 10px;
      span {
        margin-right: 2px;
      }
      .bold {
        font-weight: bold;
      }
    }

    .details {
      display: flex;
      align-items: center;

      .subtitle {
        font-size: 11px;
        color: ${props => props.theme.palette.infoInvert.contrastText};
      }

      .actions {
        margin-left: auto;

        .action-item {
          margin-left: 5px;
          margin-right: 5px;
          align-items: center;

          .action-item-text {
            color: ${props => props.theme.palette.infoInvert.contrastText};
            font-size: 11px;
            position: relative;
            bottom: 8px;
            margin-left: 5px;
          }
        }
      }
    }
  }
`;

export type ActiveItemsProps = {
  component: JSX.Element;
  performer: JSX.Element;
  subtitle: string;
  theme?: Theme;
  type: "add" | "edit";
};

export default ({
  theme,
  type,
  component,
  performer,
  subtitle,
}: ActiveItemsProps): JSX.Element => (
  <ActivityItemWrapper theme={theme}>
    <div className="explorer-activity-item-icon">
      {type === "add" && <PersonAddIcon />}
      {type === "edit" && <PersonAddIcon />}
    </div>
    <div className="explorer-activity-item-description">
      <div className="title">
        <span>{type === "add" && "New"}</span>
        <span>{component}</span>
        <span>{type === "add" && "Added by"}</span>
        <span>{type === "edit" && "Edited by"}</span>
        <span className="bold">{performer}</span>
      </div>
      <div className="details">
        <div className="subtitle">{subtitle}</div>
        <div className="actions">
          <span className="action-item">
            <UpIcon />
          </span>
          <span className="action-item">
            <DownIcon />
          </span>
          <span className="action-item">
            <CommentIcon />
            <span className="action-item-text">Comment</span>
          </span>
        </div>
      </div>
    </div>
  </ActivityItemWrapper>
);
