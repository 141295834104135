import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import { Input } from "atoms";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import styled from "styled-components";

import AddItem from "../AddItem";
import EditRowCol from "../EditRow";

const MAX_COLUMNS = 7; // Name Column + Max 5 Item Column + Edit Column

const Wrapper = styled.div`
  flex-grow: 1;

  .question {
    &__title {
      display: flex;

      &_row {
        font-size: 13px;

        justify-content: flex-start;
        align-items: center;
      }

      &_column {
        font-size: 12px;

        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
`;

const EditColWrapper = styled.div`
  margin-left: auto;
`;

const EditableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const isOrigin = (rowIndex, colIndex) => rowIndex === 0 && colIndex === 0;

const isEditColumn = (mode, columns, colIndex) =>
  mode === "edit" && colIndex === columns.length - 1;

const isEditColumnHeader = (mode, columns, colIndex, rowIndex) =>
  isEditColumn(mode, columns, colIndex) && rowIndex === 0;

const isAddColumn = (mode, columns, colIndex) => {
  let val = false;
  if (mode === "edit" && colIndex === columns.length - 2) {
    if (columns.length !== MAX_COLUMNS) {
      val = true;
    } else if (columns[colIndex].id === "matrix-add-column") {
      val = true;
    }
  }
  return val;
};

const isAddColumnAction = (mode, columns, colIndex, rowIndex) =>
  isAddColumn(mode, columns, colIndex) && rowIndex === 0;

const isAddRow = (mode, rows, rowIndex) =>
  mode === "edit" && rowIndex === rows.length - 1;

const isAddRowAction = (mode, rows, rowIndex, colIndex) =>
  isAddRow(mode, rows, rowIndex) && colIndex === 0;

type getMatrixLabelProps = {
  collection: any;
  collectionIndex: number;
  mode: string;
  onLabelUpdate?: Function;
  onRemoveColumn?: Function;
  type: string;
};
const getMatrixLabel = (
  collection: any,
  collectionIndex: number | string,
  mode: string,
  type: string,
  onLabelUpdate?: Function,
  onRemoveColumn?: (collIndex: number | string) => any,
): JSX.Element => {
  const currentValue = collection[collectionIndex];
  if (mode !== "edit") {
    return currentValue.text;
  }
  return (
    <EditableWrapper>
      {type === "columns" ? (
        <EditRowCol
          index={collectionIndex}
          onRemove={collIndex =>
            onRemoveColumn && onRemoveColumn((collIndex as number) - 1)
          }
          showReorder={false}
        />
      ) : null}
      <Input
        value={currentValue.text}
        onChange={ev =>
          onLabelUpdate &&
          onLabelUpdate(type, (collectionIndex as number) - 1, ev.target.value)
        }
        required={true}
      />
    </EditableWrapper>
  );
};

const getItem = ({
  totalRows: rows,
  totalColumns: columns,
  rowIndex,
  colIndex,
  renderElement,
  mode,
  ...rest
}) => {
  if (isOrigin(rowIndex, colIndex)) {
    return "";
  }
  if (rowIndex === 0) {
    if (isEditColumnHeader(mode, columns, colIndex, rowIndex)) {
      return "";
    }
    if (isAddColumnAction(mode, columns, colIndex, rowIndex)) {
      return (
        <AddItem
          type="column"
          label="Add Column"
          handleAdd={rest.onAddColumn}
        />
      );
    }
    return getMatrixLabel(
      mode,
      "columns",
      columns,
      colIndex,
      rest.onLabelUpdate,
      rest.onRemoveColumn,
    );
  }
  if (colIndex === 0) {
    if (isAddRowAction(mode, rows, rowIndex, colIndex)) {
      return <AddItem type="row" label="Add Row" handleAdd={rest.onAddRow} />;
    }
    return getMatrixLabel(mode, "rows", rows, rowIndex, rest.onLabelUpdate);
  }
  if (
    isEditColumn(mode, columns, colIndex) &&
    !isAddRow(mode, rows, rowIndex)
  ) {
    return (
      <EditColWrapper>
        <EditRowCol
          index={rowIndex}
          onRemove={rIndex => rest.onRemoveRow((rIndex as number) - 1)}
        />
      </EditColWrapper>
    );
  }
  if (isAddColumn(mode, columns, colIndex) || isAddRow(mode, rows, rowIndex)) {
    if (rest.renderAdderElement) {
      return rest.renderAdderElement({
        colIndex: colIndex - 1,
        rowIndex: rowIndex - 1,
        type: isAddColumn(mode, columns, colIndex) ? "column" : "row",
      });
    }
    return "";
  }
  return renderElement({ rowIndex: rowIndex - 1, colIndex: colIndex - 1 });
};

type ItemRowProps = {
  columns: any[];
  mode: string;
  renderElement: Function;
  rowIndex: number | string;
  rows: any[];
  vertical?: boolean;
  verticalMap?: any;
};
const ItemRow = ({
  rows,
  columns,
  rowIndex,
  renderElement,
  mode,
  vertical = false,
  verticalMap,
  ...rest
}: ItemRowProps): JSX.Element => {
  // const colSize = Math.floor(12 / columns.length);
  const totalRows = [...rows];
  const totalColumns = [...columns];

  const getColumnDimension = colIndex => {
    if (totalColumns.length === 7) {
      if (colIndex === 5 || colIndex === 6) {
        return 1;
      }
      return 2;
    }
    return 2;
  };

  const allColumns = totalColumns.map((currentCol, colIndex) => {
    // Have to apply margin-left: auto on Grid in case of edit row
    // Clone columns here - check if not first col & edit col or add col & not last row
    // Group by colIndex wrapped in a wrapper, append one after another. Each of this wrapper is SortableElement
    // Arrange all the wrappers in a row one after another. This parent wrapper would be the sortableContainer.
    const elem = (
      <Grid
        key={`${rowIndex}-${colIndex}`}
        item={true}
        className={
          colIndex === 0
            ? "question__title question__title_row"
            : rowIndex === 0
            ? "question__title question__title_column"
            : ""
        }
        xs={vertical ? 12 : getColumnDimension(colIndex)}
      >
        <>
          {getItem({
            colIndex,
            mode,
            renderElement,
            rowIndex,
            totalColumns,
            totalRows,
            ...rest,
          })}
        </>
      </Grid>
    );

    if (
      vertical &&
      colIndex !== 0 &&
      !isAddColumn(mode, columns, colIndex) &&
      !isEditColumn(mode, columns, colIndex) &&
      !isAddRow(mode, rows, rowIndex)
    ) {
      const currentColItems = verticalMap[colIndex];
      if (!currentColItems) {
        verticalMap[colIndex] = [];
      }
      verticalMap[colIndex].push(elem);
    }
    return elem;
  });
  return <>{allColumns}</>;
};

const ItemRowGrid = ({
  totalRows,
  totalColumns,
  rowIndex,
  renderElement,
  mode,
  ...rest
}) => (
  <Grid key={rowIndex} container={true} item={true} xs={12} spacing={2}>
    <ItemRow
      rows={totalRows}
      columns={totalColumns}
      rowIndex={rowIndex}
      renderElement={renderElement}
      mode={mode}
      {...rest}
    />
  </Grid>
);

const SortableItem = SortableElement(ItemRowGrid);

const SortableRows = SortableContainer(
  ({ totalRows, totalColumns, renderElement, mode, ...rest }) => {
    const allRows = totalRows.map((currentRow, index) => {
      if (mode === "edit") {
        if (index === 0 || index === totalRows.length - 1) {
          return (
            <ItemRowGrid
              key={`row-${index}`}
              totalRows={totalRows}
              totalColumns={totalColumns}
              renderElement={renderElement}
              mode={mode}
              rowIndex={index}
              {...rest}
            />
          );
        }
        return (
          <SortableItem
            key={`row-${index}`}
            index={index}
            totalRows={totalRows}
            totalColumns={totalColumns}
            renderElement={renderElement}
            mode={mode}
            rowIndex={index}
            {...rest}
          />
        );
      }
      return (
        <ItemRowGrid
          key={`row-${index}`}
          totalRows={totalRows}
          totalColumns={totalColumns}
          renderElement={renderElement}
          mode={mode}
          rowIndex={index}
        />
      );
    });
    return <div>{allRows}</div>;
  },
);

const getTotalRowsAndColumns = ({ rows, columns, mode }) => {
  const totalRows = [...rows];
  const totalColumns = [...columns];
  totalRows.unshift({ id: "matrix-first-row", text: "" });
  totalColumns.unshift({ id: "matrix-first-column", text: "" });
  if (mode && mode === "edit") {
    totalRows.push({ id: "matrix-add-row", text: "" });
    // Check for if item columns are less than the max limit of 5 or not
    if (columns.length < 5) {
      totalColumns.push({ id: "matrix-add-column", text: "" });
    }
    totalColumns.push({ id: "matrix-edit-row", text: "" });
  }
  return { totalRows, totalColumns };
};

const getRows = ({ rows, columns, renderElement, mode, ...rest }) => {
  const { totalRows, totalColumns } = getTotalRowsAndColumns({
    columns,
    mode,
    rows,
  });

  return (
    <SortableRows
      useDragHandle={true}
      onSortEnd={({ oldIndex, newIndex }) => {
        if (rest && rest.onReorderRow) {
          rest.onReorderRow({ oldIndex: oldIndex - 1, newIndex: newIndex - 1 });
        }
      }}
      totalRows={totalRows}
      totalColumns={totalColumns}
      renderElement={renderElement}
      mode={mode}
      {...rest}
    />
  );
};

const getVerticalColumns = columnMap => {
  const columnMapKeys = Object.keys(columnMap).sort();
  const DivCol = SortableElement(({ item }) => (
    <Grid item={true} xs={2} spacing={2}>
      {item}
    </Grid>
  ));
  const DivContainer = SortableContainer(() => (
    <Grid container={true} xs={12} spacing={2}>
      {columnMapKeys.map((colKey, index) => (
        <DivCol
          key={`colKey-${colKey}-index-${index}`}
          index={index}
          item={columnMap[colKey]}
        />
      ))}
    </Grid>
  ));
  if (columnMapKeys.length > 0) {
    return (
      <DivContainer
        onSortEnd={({ oldIndex, newIndex }) => {
          /* todo: implement */
        }}
        axis="x"
      />
    );
  }
  return null;
};

const getBreak = ({ rows, columns, renderElement, mode, ...rest }) => {
  const { totalRows, totalColumns } = getTotalRowsAndColumns({
    columns,
    mode,
    rows,
  });
  const verticalMap = {};
  totalRows.map((currentRow, rowIndex) =>
    ItemRow({
      columns: totalColumns,
      mode,
      renderElement,
      rowIndex,
      rows: totalRows,
      vertical: true,
      verticalMap,
    }),
  );
  return verticalMap;
};

const BaseMatrix = ({ matrix, renderElement, mode = "view", ...rest }) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setRows(matrix[0]);
    setColumns(matrix[1]);
  }, [matrix]);

  const arrangedRows = getRows({ rows, columns, renderElement, mode, ...rest });
  const verticalMap = getBreak({ rows, columns, renderElement, mode, ...rest });
  const vertColumns = getVerticalColumns(verticalMap);
  // eslint-disable-next-line no-console
  console.log({ vertColumns });
  return (
    <div style={{ position: "relative" }}>
      <Wrapper>{arrangedRows}</Wrapper>
    </div>
  );
};

export default BaseMatrix;
