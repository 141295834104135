import React from "react";

import { Typography } from "@material-ui/core";

import { Breadcrumb, BreadcrumbProps } from "atoms";
import styled from "styled-components";

const HeaderWrapper = styled.div<{ customStyle?: Function }>`
  display: flex;
  width: 100%;
  border-radius: 0;
  align-items: center;
  ${props => props.customStyle && props.customStyle()};

  .explorer-header_main {
    .MuiTypography-h6 {
      margin-bottom: 5px;
    }
  }

  .explorer-header_actions {
    margin-left: auto;
  }

  ${props => props.theme.breakpoints.down("sm")} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 8px;
    background-color: ${props => props.theme.palette.common.white};
  }
`;

export type BreadcrumbHeaderProps = BreadcrumbProps & {
  children: any;
  title: string;
};

export default (props: BreadcrumbHeaderProps): JSX.Element => (
  <HeaderWrapper {...props}>
    <div className="explorer-header_main">
      <Typography variant="h6">{props.title}</Typography>
      {props.breadcrumb && <Breadcrumb breadcrumb={props.breadcrumb} />}
    </div>
    <div className="explorer-header_actions">{props.children}</div>
  </HeaderWrapper>
);
