import React from "react";

import styled from "styled-components";

const TemplateLoaderWrapper = styled.div`
  font-size: 1rem;

  p,
  h2,
  h1 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

export type TemplateLoaderProps = {
  html?: any;
  template?: any;
};

export default ({
  template,
  html,
}: Readonly<TemplateLoaderProps>): JSX.Element => (
  <TemplateLoaderWrapper
    dangerouslySetInnerHTML={{ __html: template || html }}
  />
);
