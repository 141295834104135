import React from "react";

import MuiTabs from "@material-ui/core/Tabs";
import { TabsProps as MuiTabsProps } from "@material-ui/core/Tabs/Tabs";
import styled, { css } from "styled-components";

export type TabsProps = MuiTabsProps & {
  customStyle: any;
};

export default styled(
  ({ children, ...rest }): JSX.Element => (
    <MuiTabs {...rest} aria-label="simple tabs example">
      {children}
    </MuiTabs>
  ),
)<Readonly<TabsProps> & { customStyle: any }>`
  ${props =>
    css`
      ${props.customStyle}
    `}
`;
