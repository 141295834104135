import React from "react";

import Grid, { GridProps } from "@material-ui/core/Grid";
import styled from "styled-components";

export type FormContainerProps = GridProps;

export default styled((props: FormContainerProps) => (
  <Grid container={true} {...props} />
))`
  padding: 24px;
`;
