import React from "react";

import { Avatar, Button } from "atoms";
import styled from "styled-components";

const OpportunityWrapper = styled.div`
  display: flex;

  padding: 24px 24px;
  background: white;
  border-radius: 4px;

  box-sizing: border-box;

  .info-column {
    display: flex;
    flex-direction: column;

    padding-left: 16px;
  }

  .title {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.6;
    color: #222b45;
  }

  .desc {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.33;
    color: #8f9bb3;

    margin: 8px 0 0 0;
  }

  .manage-row {
    display: flex;
    justify-content: flex-end;

    margin: 24px 0 0 0;

    button {
      margin: 0;
    }
  }
`;

export type OpportunityProps = {
  onGetOnboard: Function;
  project?: any;
  survey?: any;
};

export default ({
  project,
  onGetOnboard,
  survey,
}: OpportunityProps): JSX.Element => {
  const { id, logo, name, desc, color } = project;

  let image: any = null;
  if (logo) {
    const { url, variations } = logo;
    image = [url, ...variations].join("/");
  }

  const onGetOnboardHandler = React.useCallback(
    (...value) => {
      if (onGetOnboard) {
        onGetOnboard(...value);
      }
    },
    [onGetOnboard],
  );

  const projectColors = [
    "primary",
    "warning",
    "info",
    "infoLight",
    "secondary",
  ];

  return (
    <OpportunityWrapper>
      <Avatar
        width={40}
        height={40}
        image={image}
        title={name}
        palette={[projectColors[color || 0]]}
      />
      <div className="info-column">
        <div className="title">{name}</div>
        <div className="desc">{desc}</div>
        <div className={"manage-row"}>
          <Button
            size="medium"
            variant="contained"
            palette="primary"
            onClick={() => {
              onGetOnboardHandler(id, survey.id);
            }}
          >
            Get Onboard
          </Button>
        </div>
      </div>
    </OpportunityWrapper>
  );
};
