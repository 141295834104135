import React from "react";

import { TapRadio } from "atoms";
import styled from "styled-components";

const TapRadioGroupWrapper = styled.div`
  display: flex;
  align-items: center;

  .explorer-tap-radio {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

interface IOption {
  count: number;
  icon: JSX.Element;
  key: number;
  label: string;
}

export type TapRadioGroupProps = {
  disabled?: boolean;
  onChangeOption: (key: number, option: Partial<IOption>) => void;
  options: IOption[];
  selectedKey: number;
};

export default ({
  options,
  disabled,
  selectedKey,
  onChangeOption,
}: Readonly<TapRadioGroupProps>): JSX.Element => (
  <TapRadioGroupWrapper>
    {options.map(({ icon, key, ...option }) => (
      <TapRadio
        key={key}
        disabled={!!disabled}
        active={selectedKey === key}
        onClick={() => onChangeOption(key, option)}
        IconComponent={icon}
        {...option}
      />
    ))}
  </TapRadioGroupWrapper>
);
